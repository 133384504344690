import React from 'react'
import {bindActionCreators} from 'redux';
import moment from 'moment-timezone'
import {connect} from 'react-redux';
import Formsy from 'formsy-react';
import {TextInput, MultiSelect, Textarea, DateTimeInput} from '../../../components/form';
import {getOne, update, patch, linkSync, createTag} from "../../../state/resource/actions";

class ResourceEditForm extends React.Component {

  componentDidMount() {
    if (this.props.id) {
      this.props.getOne(this.props.id);
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      isProcessing: false,
      message: null,
      messageIsError: false,
      isPristine: true
    };

  }

  render() {

    const { showErrors, isProcessing, message, messageIsError, isPristine } = this.state;
    const { resource, tags, persons } = this.props;

    return (
      <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
              onChange={(values, isChanged) => this.checkForPristine(values, isChanged) }
              onInvalidSubmit={() => this.onInvalidSubmit() }
              ref="form">
        <div className="form-fields">
          <DateTimeInput name="date"
                         icon="calendar"
                         value={resource.date}
                         closeOnSelection={false}
                         label="Effective date:" />
          <TextInput name="name"
                 type="text"
                 icon="build"
                 value={resource.name}
                 label="Name/title:"
                 showErrors={showErrors}
                 required />
          <TextInput name="link"
                     type="text"
                     link={resource.link}
                     icon="link"
                     value={resource.link}
                     label="Link to resource:"
                     showErrors={showErrors} />
          <Textarea name="text"
                     type="text"
                     icon="build"
                     value={resource.text}
                     label="Text:"
                     showErrors={showErrors}
          />
          <MultiSelect
            name="tags"
            type="text"
            icon="build"
            createable
            value={resource.tags.map((tag) => ({
              value: tag.id + "",
              label: tag.name
            }))}
            isMulti
            options={tags.map((tag) => ({
              value: tag.id + "",
              label: tag.name
            }))}
            label="Resource Type:"
            showErrors={showErrors}
          />
        </div>
        <div className="form-submit-group grid-row">
          <div className="grid-item grid-row v-align">
            {message && (
              <div className={"message" + (messageIsError ? " error-message" : "")}>
                {message}
              </div>
            )}
          </div>
          <div>
            <button className={"bp3-button sr2" + (isPristine ? " bp3-disabled" : "")}
                    type="button"
                    onClick={() => this.onCancel() }>Cancel</button>
            {!isProcessing ? (
              <button type="submit"
                      className={"bp3-button bp3-intent-primary" + (isPristine ? " bp3-disabled" : "")}>Submit</button>
            ) : (
              <button type="submit"
                      className="bp3-button bp3-disabled ">Submitting...</button>
            )}
          </div>
        </div>
      </Formsy>
    );
  }

  onValidSubmit(model) {

    const { isPristine } = this.state;
    const { resource, linkSync, patch, getOne, createTag } = this.props;
    if (isPristine) return;

    this.setState({isProcessing: true, serverError: null});

    var selectedTags = model.tags || [];

    Promise
      .all(selectedTags.filter((tag) => tag.__isNew__).map((tag) => createTag(tag.label)))
      .then((results) => {
        // find new tags and link them.

        var newTags = results.map((result) => result.item.id);
        var currentTags = selectedTags.filter((tag) => !tag.__isNew__).map((tag) => parseInt(tag.value));

        return Promise.all([
          patch(resource.id, {
            name: model.name,
            text: model.text,
            link: model.link,
            ... model.date && { date: moment(model.date).valueOf() }
          }),
          linkSync(resource.id, [...newTags, ...currentTags])
        ]);

      })
      .then((result) => getOne(resource.id))
      .then((result) => {
        this.setState({
          isProcessing: false,
          message: "Your changes were applied successfully.",
          messageIsError: false,
          reset: true
        }, () => this.setState({reset: false}));

      })
      .catch((e) => {
        this.setState({
          isProcessing: false,
          message: e.message,
          messageIsError: true
        });
      });
  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

  checkForPristine(values, isChanged) {
    this.setState({isPristine: !isChanged});
  }

  onCancel() {

    const { isPristine } = this.state;
    if (isPristine) return;

    this.refs.form.reset();
    this.setState({
      message : "Your changes have been discarded.",
      messageIsError: false
    });
  }

}

export default connect(
  (state, props) => {
    return {
      resource: props.resource ? props.resource : state.resource.item,
      tags: state.resource.tags || [],
      persons: state.person.items
    }
  },
  (dispatch) => (
    bindActionCreators({
      update, createTag, getOne, patch, linkSync
    }, dispatch)
  )
)(ResourceEditForm);
