import React from 'react'
import Chart from "react-google-charts";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import moment from 'moment-timezone'

class TitheChart extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      groupBy: "month",
      data: []
    }
  }

  getDataSet() {

    const { tithes } = this.props;
    const { groupBy } = this.state;

    if (groupBy === "month") {

      var labels = ['Month', 'Amount'];

      var months = {}

      tithes.forEach((tithe) => {

        var date = moment(tithe.date, "YYYY-MM-DD HH:mm:ss")
        var month = date.format("MMM YY");

        if (!months[month])
          months[month] = 0;

        months[month] += tithe.amount;

      })

      return [
        labels,
        ...Object.keys(months).map((month) => ([month, months[month]]))
      ]

    }

    return [
      ['Year', 'Sales', 'Expenses', 'Profit'],
      ['Jan', 1000, 400, 200],
      ['Feb', 1170, 460, 250],
      ['Mar', 660, 1120, 300],
      ['Apr', 1030, 540, 350],
      ['May', 1030, 540, 350],
      ['Jun', 1030, 540, 350],
      ['Jul', 1030, 540, 350],
      ['Aug', 1030, 540, 350],
    ]
  }

  onGroupByChange = (e) => {
    this.setState({groupBy: e.currentTarget.value, newData: true})
    setTimeout(() => this.setState({newData: false}), 5);
  }

  render() {

    const { groupBy, newData } = this.state;
    const data = this.getDataSet();

    return (
      <div className="TitheChart">
        <select value={groupBy} onChange={this.onGroupByChange}>
          <option value="month">Group by month</option>
          <option value="year">Group by year</option>
        </select>
        <div style={{height: 200}}>
          {!newData && (
            <Chart
              width={'100%'}
              height={'200px'}
              chartType="ColumnChart"
              data={data}
              options={{
                isStacked: true,
                chart: {
                  title: 'Report',
                },
                bar: { groupWidth: '10%' }
              }}
            />
          )}
        </div>
      </div>
    )
  }

}

export default connect(
  (state) => {
    return {
      tithes: state.tithe.items
    }
  },
  (dispatch) => (
    bindActionCreators({
    }, dispatch)
  )
)(TitheChart);
