import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash'
import { Dialog, Button, Intent } from "@blueprintjs/core";
import {Toaster} from "../../../components";

class BuildSongEmailDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      isOpen: false
    }
  }

  render() {

    const { service, trigger = _.noop } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        {trigger(() => this.setState({isOpen: true}))}
        <div className="AddSongEmailDialog">
          <Dialog
            style={{width: "700px"}}
            iconName="music"
            isOpen={isOpen}
            onClose={() => this.setState({isOpen: false}) }
            title="Songs for service content"
          >
            <div className="bp3-dialog-body">
              <div className="email-body">
                {service.songs.map((song, idx) => (
                  <div key={idx} ref="email-content">
                    {song.name} | {song.videolink ? (<a target="_blank" href={song.videolink}>Video</a>) : ("No video")}
                    | {song.chordpdflink ? (<a target="_blank" href={song.chordpdflink}>Chords</a>) : ("No chords")}
                    | {song.sheetpdflink ? (<a target="_blank" href={song.sheetpdflink}>Sheet Music</a>) : ("No sheet music")}
                  </div>
                ))}
              </div>
            </div>
            <div className="bp3-dialog-footer">
              <div className="bp3-dialog-footer-actions">
                <Button text="Done" onClick={() => this.setState({isOpen: false})}/>
              </div>
            </div>
          </Dialog>
        </div>
      </>
    )
  }

}

export default connect(
  (state) => {
    return {
      service: state.service.item
    }
  },
  (dispatch) => (
    bindActionCreators({
    }, dispatch)
  )
)(BuildSongEmailDialog);


