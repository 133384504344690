import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Moment from 'moment'
import {getCharges  as getOrgCharges} from '../../../state/org/actions'
import { Money } from "../../../components";
import {getCharges} from "../../../state/account/actions";

class ChargesList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false
    }
  }

  componentDidMount() {

    const { getCharges, getOrgCharges } = this.props;

    this.setState({loading: true, error: false});

    (this.props.personal ? getCharges() : getOrgCharges())
      .then((results) => this.setState({loading: false}))
      .catch((e) => {this.setState({ error: e.message, loading: false })})
  }

  render() {

    const { orgCharges, charges } = this.props;
    const { loading } = this.state;
    const viewedCharges = this.props.personal ? charges : orgCharges;

    if (loading) return null;

    if (viewedCharges.length === 0) return (
      <div className="bp3-non-ideal-state">
        <h3 className="bp3-non-ideal-state-title">No charges recorded.</h3>
      </div>
    );

    return (
      <div className="PaymentsList">
        <table className="bp3-table full-width bp3-condensed">
          <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Card</th>
          </tr>
          </thead>
          <tbody>
          {viewedCharges.map((charge, idx) => (
            <tr key={idx}>
              <td>{Moment.unix(charge.date).format("LL")}</td>
              <td><Money value={charge.amount} /></td>
              <td>{charge.brand} ****{charge.last4}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    )
  }

}

export default connect(
  (state) => {
    return {
      orgCharges: state.org.charges,
      charges: state.account.charges
    }
  },
  (dispatch) => (
    bindActionCreators({
      getCharges, getOrgCharges
    }, dispatch)
  )
)(ChargesList);

