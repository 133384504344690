import React from 'react'
import {bindActionCreators} from 'redux';
import {Button, Intent, Popover, Menu, Position, Tooltip, AnchorButton} from '@blueprintjs/core';
import {connect} from 'react-redux';
import {Link} from 'gatsby'
import {DashboardLayoutA} from "../layouts";
import {ArchiveSongButton, SongEditForm} from './components'
import {getOne, getTags} from "../../state/song/actions";
import * as Paths from '../../constants/Paths'
import moment from 'moment-timezone'
import {navigate} from 'gatsby'

class SongEditPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }

  }

  componentDidMount() {
    Promise.all([
      this.props.getOne(this.props.id),
      this.props.getTags()
    ]).then(() => {
      this.setState({loading: false})
    })
  }

  render() {

    const { song } = this.props;

    return (
      <DashboardLayoutA className="page-content-narrow SongEditPage" loading={this.state.loading}>
        <div className="SongEditPage">
          <div className="card-settings s5">
            <div className="inner-header grid-row v-align">
              <Tooltip content="Back to all Songs." position={Position.RIGHT}>
                <AnchorButton
                  icon="arrow-left"
                  outlined
                  minimal
                  href={Paths.SONG_LIST}
                  onClick={(e) => {e.preventDefault(); navigate(Paths.SONG_LIST) }}
                  className="heading-back"
                />
              </Tooltip>
              <div className="grid-item">
                <div className="heading"><span className="bp3-icon-standard bp3-icon-music sr3"></span>{song.name}</div>
              </div>
              <ArchiveSongButton className="bp3-button bp3-icon-archive" id={song.id}>Archive</ArchiveSongButton>
            </div>
            <SongEditForm />
          </div>
        </div>
        <div className="grid-row">
          <div className="card-list grid-item sr5">
            <div className="header">
              <div className="title">Played in recent services:</div>
            </div>
            {song.service.length > 0 ? (
              <div className="body">
                <table className="bp3-table">
                  <tbody>
                  {song.service.sort((a, b) => a.id < b.id ? 1 : -1).map((service, idx) => (
                    <tr key={idx}>
                      <td className="td-icon"><span className="bp3-icon-standard bp3-icon-bookmark"></span></td>
                      <td className="td-name"><Link
                        to={Paths.withId(Paths.SERVICE_EDIT, service.id)}>
                        {moment(service.date, "YYYY-MM-DD HH:mm:ss").format("LLLL")}
                      </Link></td>
                      <td className="td-name">{service.author}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="bp3-non-ideal-state">
                <div className="bp3-non-ideal-state-visual bp3-non-ideal-state-icon">
                  <span className="bp3-icon bp3-icon-bookmark"></span>
                </div>
                <h5 className="bp3-non-ideal-state-title">Nothing recorded.</h5>
              </div>
            )}
          </div>
          <div className="grid-item">
            <div className="grid-row">
              <div className="grid-item grid-row h-align">
                {this.isYoutube(song.videolink) ?
                  (
                    <iframe width="100%" height="315" src={this.prepYoutubeEmbed(song.videolink)}
                            frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe>
                  ) : (
                    <a href={song.videolink} target="_blank">{song.videolink}</a>
                  )}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayoutA>
    )
  }

  isYoutube(link) {
    return !!link && link.indexOf("youtube.com") !== -1;
  }

  prepYoutubeEmbed(link) {
    return link.replace("watch?v=", "embed/")
  }

}

// SongEditPage.defaultProps = {
//   song:
// }

export default connect(
  (state) => {
    return {
      song: state.song.item || {id: 0, name: "", service: [], tags: []}
    }
  },
  (dispatch) => (
    bindActionCreators({
      getOne, getTags
    }, dispatch)
  )
)(SongEditPage);