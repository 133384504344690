import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Dialog, Callout, Intent, Button} from '@blueprintjs/core'
import {Toaster} from "../../../components";
import {getEmailsFromRoles, getPersonsFromRole} from "../../../state/role/utils";
import {getInformalFullname} from "../../../state/person/util";
import {copyToClipboard} from '../../../utils/utils'
class EmailDialog extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    const { dialogProps, service, serviceRoles  } = this.props;
    const { songs, sermons } = service;
    var emails = getEmailsFromRoles(serviceRoles);

    return (
      <>
        <Dialog
          {...dialogProps}
          portalClassName="EmailTemplate-dialogbody"
          iconName="envelope"
          className="no-padding"
          title="Service Email"
        >
          <div className="EmailTemplate">
            <div className="recipients">
              <Button minimal icon={"clipboard"} className="copy-section" large onClick={() => this.copyRecipientsToClipboard()}/>
              {emails.emails.length === 0 && (
                <span>No participants set.</span>
              )}
              {emails.emails.map((email, idx) => (
                <span key={idx}>{email + (idx !== emails.emails.length - 1 ? ", " : "")} </span>
              ))}
            </div>
            {emails.missing.length > 0 && (
              <Callout title="Missing Emails" intent={Intent.WARNING} icon="warning-sign">
                The following people in this service do not have emails yet: {emails.missing.map((person, idx) => (
                  <b key={idx}>{getInformalFullname(person) + (idx !== emails.missing.length - 1 ? ", " : "")}</b>
                ))}
              </Callout>
            )}
            <div className="email-body">

              <div className="email-body-section-title">People:</div>
              <div className="email-body-section">
                {serviceRoles.map((role, idx) => (
                  <div key={idx}>{role.name}:{"  "}
                    {getPersonsFromRole(role).map((person, idx, persons) => (
                      <b key={idx}>{getInformalFullname(person) + (idx !== persons.length - 1 ? ", " : "")}</b>
                    ))}
                    {getPersonsFromRole(role).length === 0 && (
                      <span>Not set.</span>
                    )}
                  </div>
                ))}
              </div>
              <div className="email-body-section-title">Message:</div>
              <div className="email-body-section">
                {sermons.map((sermon, idx) => (
                  <div key={idx}>{sermon.name} ({sermon.description})</div>
                ))}
              </div>
              <div className="email-body-section-title">Songs:</div>
              <div className="email-body-section">
                {songs.map((song, idx) => (
                  <div key={idx}>
                    {song.name} {song.videolink ? (<a target="_blank" href={song.videolink}>(Video)</a>) : ("")}
                    {" "}{song.chordpdflink ? (<a target="_blank" href={song.chordpdflink}>(Chords)</a>) : ("")}
                    {" "}{song.sheetpdflink ? (<a target="_blank" href={song.sheetpdflink}>(Sheet Music)</a>) : ("")}
                  </div>
                ))}
              </div>
            </div>
            {songs.length === 0 && (
              <Callout title="Missing Songs" intent={Intent.WARNING} icon="warning-sign">
                No songs have been chosen for this service.
              </Callout>
            )}
            {sermons.length === 0 && (
              <Callout title="Missing Messages" intent={Intent.WARNING} icon="warning-sign">
                No messages have been chosen for this service.
              </Callout>
            )}
          </div>
        </Dialog>
      </>
    )
  }

  copyRecipientsToClipboard = () => {

    const { serviceRoles } = this.props;

    var email = getEmailsFromRoles(serviceRoles);

    copyToClipboard(email.emails.join(", "));

    var message = `${email.emails.length} email(s) copied to your clipboard.`;

    if (email.missing.length > 0) {
      message = `${email.emails.length} email(s) of ${email.emails.length + email.missing.length} copied to your clipboard. Emails not found for: ${email.missing.map((p) => getInformalFullname(p)).join(", ")}`;
    } else {
      message = `${email.emails.length} email(s) copied to your clipboard.`;
    }

    Toaster.success(message)

  };

}

export default connect(
  (state) => {
    return {
    }
  },
  (dispatch) => (
    bindActionCreators({

    }, dispatch)
  )
)(EmailDialog);