import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import _ from 'lodash'
import Moment from 'moment-timezone';
import {Toaster} from "../../../components/index";
import Formsy from 'formsy-react';
import {navigate} from 'gatsby'
import {Button, Dialog, Intent} from "@blueprintjs/core";
import {create} from '../../../state/tithe/actions'
import {DateTimeInput, NumberInput} from '../../../components/form';
import * as Paths from '../../../constants/Paths'

class AddTitheDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {showErrors: false}
  }

  render() {

    const { onClose = _.noop, onSubmit = _.noop, isOpen = false } = this.props;
    const { showErrors = false } = this.state;

    return (
      <div className="AddTitheDialog">
        <Dialog
          iconName="inbox"
          isOpen={isOpen}
          onClose={() => onClose() }
          title="Dialog header"
        >
          <div className="bp3-dialog-body">
            <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
                    onInvalidSubmit={() => this.onInvalidSubmit() }
                    ref="form">
              <DateTimeInput name="date"
                             icon="calendar"
                             label="Date of tithe:"
                             timePrecision={null} />
              <NumberInput name="amount"
                           large
                           label="Tithe amount:" />
            </Formsy>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text="Cancel" onClick={() => onClose()}/>
              <Button
                intent={Intent.PRIMARY}
                onClick={() => this.refs.form.submit() }
                text="Submit"
              />
            </div>
          </div>
        </Dialog>
      </div>
    )
  }

  onValidSubmit(model) {

    const { create, onClose } = this.props;

    create({
      date: Moment(model.date).format("YYYY-MM-DD HH:mm:ss"),
      amount: model.amount
    })
      .then((result) => {
        onClose();
        navigate(Paths.withId(Paths.TITHE_EDIT, result.item.id));
      }).catch((e) => {
        Toaster.show({
          message: e.message,
          iconName: "warning-sign",
          intent: Intent.DANGER
        });
      })
  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

}

AddTitheDialog.defaultProps = {
  onClose: _.noop
};

export default connect(
  (state) => {
    return {

    }
  },
  (dispatch) => (
    bindActionCreators({
      create
    }, dispatch)
  )
)(AddTitheDialog);
