import React from 'react'
import {Button, Icon, Intent, Checkbox, InputGroup} from '@blueprintjs/core'

class ListFilterItem extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      processing: false,
      error: false,
      newTagName: null
    }

  }

  render() {

    const { editing, processing, error, newTagName } = this.state;
    const { selected, toggleSelectedTag, archiveTag, updateTag, getTags, tag, getAll } = this.props;

    return (
      <li>
        <a
          className={`bp3-menu-item`}
          onClick={() => toggleSelectedTag(tag.id).then((getAll()))}
        >
          <div className="grid-row full-width">
            <div>
              <Checkbox
                checked={selected}
                onChange={() => toggleSelectedTag(tag.id).then((getAll()))}
              />
            </div>
            <div className="grid-item">
              {editing ? (
                <InputGroup
                  onClick={(e) => e.stopPropagation() }
                  value={newTagName}
                  onChange={(e) => this.setState({newTagName: e.target.value})}
                  rightElement={
                    <div onClick={(e) => e.stopPropagation()}>
                      <Button icon="tick" onClick={(e) => {
                        updateTag(tag.id, newTagName).then(() => getTags()).then(() => {
                          this.setState({editing: false})
                        });
                      }} />
                      <Button icon="disable" onClick={(e) => {
                        this.setState({editing: false})
                      }} />
                      <Button
                        icon="trash"
                        intent={Intent.DANGER}
                        onClick={(e) => {
                          archiveTag(tag.id).then(() => getTags()).then(() => this.setState({editing: false}))
                        }} />
                    </div>
                  }
                />
              ) : (
                tag.name
              )}
            </div>
            {!editing && (
              <div>
                <Icon
                  icon="edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({editing: true, newTagName: tag.name})
                  }}
                />
              </div>
            )}
          </div>
        </a>
      </li>
    )
  }

}

export default ListFilterItem