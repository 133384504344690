import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {DashboardLayoutA} from "../layouts";
import {CreateOrgForm} from './components'

class OrgCreatePage extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
      <DashboardLayoutA className="OrgCreatePage page-content-narrow">
        <div className="bp3-card">
          <div className="OrgCreatePage">
            <CreateOrgForm />
          </div>
        </div>
      </DashboardLayoutA>
    )
  }

}

export default connect(
  (state) => {
    return {

    }
  },
  (dispatch) => (
    bindActionCreators({

    }, dispatch)
  )
)(OrgCreatePage);