import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Moment from 'moment-timezone';
import Formsy from 'formsy-react';
import {Checkbox, DateTimeInput, MultiSelect, Textarea} from '../../../components/form';
import {createTag, getOne, linkSync, patch, update} from "../../../state/service/actions";


class ServiceEditForm extends React.Component {

  componentDidMount() {
    if (this.props.id) {
      this.props.getOne(this.props.id);
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      isProcessing: false,
      message: null,
      messageIsError: false,
      isPristine: true
    };

  }

  render() {

    const { showErrors, isProcessing, message, messageIsError, isPristine, reset } = this.state;
    const { service, tags } = this.props;

    return (
      <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
              onChange={(values, isChanged) => this.checkForPristine(values, isChanged) }
              onInvalidSubmit={() => this.onInvalidSubmit() }
              ref="form">
        <div className="form-fields">
          <DateTimeInput name="date"
                         icon="calendar"
                         value={service.date}
                         closeOnSelection={false}
                         label="Time of service:" />
          <Checkbox name="organized"
                    type="checkbox"
                    icon="calendar"
                    value={service.organized}
                    label="Organized" />
          <Textarea name="notes"
                    value={service.notes}
                    label="Internal Notes:"
                    showErrors={showErrors}
          />
          <MultiSelect
            name="tags"
            type="text"
            icon="build"
            createable
            value={service.tags.map((tag) => ({
              value: tag.id + "",
              label: tag.name
            }))}
            isMulti
            options={tags.map((tag) => ({
              value: tag.id + "",
              label: tag.name
            }))}
            label="Tags:"
            showErrors={showErrors}
          />
        </div>
        <div className="form-submit-group grid-row">
          <div className="grid-item grid-row v-align">
            {message && (
              <div className={"message" + (messageIsError ? " error-message" : "")}>
                {message}
              </div>
            )}
          </div>
          <div>
            <button className={"bp3-button sr2" + (isPristine ? " bp3-disabled" : "")}
                    type="button"
                    onClick={() => this.onCancel() }>Discard changes</button>
            {!isProcessing ? (
              <button type="submit"
                      className={"bp3-button bp3-intent-primary" + (isPristine ? " bp3-disabled" : "")}>Save Changes</button>
            ) : (
              <button type="submit"
                      className="bp3-button bp3-disabled ">Submitting...</button>
            )}
          </div>
        </div>
      </Formsy>
    );
  }

  onValidSubmit(model) {

    const { isPristine } = this.state;
    const { service, linkSync, patch, getOne, createTag } = this.props;
    if (isPristine) return;

    this.setState({isProcessing: true, serverError: null});

    var selectedTags = model.tags || [];

    Promise
      .all(selectedTags.filter((tag) => tag.__isNew__).map((tag) => createTag(tag.label)))
      .then((results) => {
        // find new tags and link them.

        var newTags = results.map((result) => result.item.id);
        var currentTags = selectedTags.filter((tag) => !tag.__isNew__).map((tag) => parseInt(tag.value));

        return Promise.all([
          patch(service.id, {
            date: Moment(model.date).format("YYYY-MM-DD HH:mm:ss"),
            organized: model.organized,
            notes: model.notes
          }),
          linkSync(service.id, "tag", [...newTags, ...currentTags])
        ]);

      })

      .then(() => getOne(service.id))
      .then(() => {
        this.setState({
          isProcessing: false,
          message: "Your changes were applied successfully.",
          messageIsError: false,
          reset: true
        }, () => this.setState({reset: false}));

      })
      .catch((e) => {
        this.setState({
          isProcessing: false,
          message: e.message,
          messageIsError: true
        });
      });
  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

  checkForPristine(values, isChanged) {
    this.setState({isPristine: !isChanged});
  }

  onCancel() {

    const { isPristine } = this.state;
    if (isPristine) return;

    this.refs.form.reset();
    this.setState({
      message : "Your changes have been discarded.",
      messageIsError: false
    });
  }

}

export default connect(
  (state, props) => {
    return {
      service: props.service ? props.service : state.service.item,
      tags: state.service.tags || [],
    }
  },
  (dispatch) => (
    bindActionCreators({
      update, createTag, getOne, patch, linkSync
    }, dispatch)
  )
)(ServiceEditForm);
