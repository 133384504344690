import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {getRecentMessages, setRecentMessageTag, setRecentMessageLimit} from '../../../state/overview/actions'
import {getTags} from '../../../state/sermon/actions'
import * as Paths from "../../../constants/Paths";
import {Link} from 'gatsby'
import moment from 'moment-timezone'

class RecentMessages extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getRecentMessages();
    this.props.getTags();
  }

  render() {

    const { messages, tags, selectedTagId, limit } = this.props;
    
    return (
      <div className="RecentMessages">
        <div className="card-list grid-item">
          <div className="header grid-row">
            <div className="grid-item title">Recent Messages:</div>
            <div className="grid-row">
              <div className="bp3-select sr2">
                <select id={"tag"}
                        className="bp3-input"
                        dir="auto"
                        onChange={this.onTagChange}
                        value={selectedTagId} >
                  <option defaultValue value="">All</option>
                  {tags.map((tag, idx) => (
                    <option key={idx} value={tag.id}>{tag.name}</option>
                  ))}
                </select>
              </div>
              <div className="bp3-select">
                <select id={"limit"}
                        className="bp3-input"
                        dir="auto"
                        onChange={this.onLimitChange}
                        value={limit} >
                  {[1, 5, 10, 20, 30].map((limit, idx) => (
                    <option key={idx} value={limit}>{`View ${limit}`}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="body">
            {messages.length > 0 ? (
              <table className="bp3-table">
                <tbody>
                {messages.map((sermon, idx) => (
                  <tr key={idx}>
                    <td className="td-icon"><span className="bp3-icon-standard bp3-icon-feed"></span></td>
                    <td className="td-name"><Link
                      to={Paths.withId(Paths.SERMON_EDIT, sermon.id)}>{sermon.name}</Link>
                    </td>
                    <td className="td-name">{sermon.speaker.first}</td>
                    <td className="td-name">
                      {sermon && sermon.mostRecentService.id && (
                        <Link
                          to={Paths.withId(Paths.SERVICE_EDIT, sermon.mostRecentService.id)}>
                          {moment(sermon.mostRecentService.date).format("L")}
                        </Link>
                      )}
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            ) : (
              <div className="inner-body">
                <div className="bp3-non-ideal-state">
                  <div className="bp3-non-ideal-state-visual bp3-non-ideal-state-icon">
                    <span className="bp3-icon bp3-icon-feed"></span>
                  </div>
                  <h5 className="bp3-non-ideal-state-title">No recent sermons.</h5>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  onTagChange = (e) => {
    this.props.setRecentMessageTag(e.currentTarget.value !== "" ? parseInt(e.currentTarget.value) : null);
  }

  onLimitChange = (e) => {
    this.props.setRecentMessageLimit(parseInt(e.currentTarget.value));
  }

}

export default connect(
  (state) => {
    return {
      messages: state.overview.recentMessages.items,
      selectedTagId: state.overview.recentMessages.selectedTagId,
      limit: state.overview.recentMessages.limit,
      tags: state.sermon.tags
    }
  },
  (dispatch) => (
    bindActionCreators({
      getRecentMessages, setRecentMessageTag, getTags, setRecentMessageLimit
    }, dispatch)
  )
)(RecentMessages);
