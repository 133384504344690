import React from 'react'
import {ListFilterItem} from "./";
import {Button, InputGroup, Intent, Menu, Popover, Position} from '@blueprintjs/core'

class ListFilter extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      adding: false,
      newTag: ""
    }

  }



  render() {

    const {
      archiveTag,
      updateTag,
      getTags,
      getAll,
      toggleSelectedTag,
      selectedTags,
      clearSelectedTags,
      tags,
      buttonLabel = "Filter",
      className
    } = this.props;

    const {
      adding,
      newTag
    } = this.state;

    return (
      <Popover content={
        <Menu className="ListFilter card-list-filters">
          <li className="bp3-menu-header"><h6 className="bp3-heading">Filter by tags:</h6></li>
          {tags.sort((a, b) => a.name > b.name ? 1 : -1).map((tag, idx) =>
            <ListFilterItem
              key={idx}
              tag={tag}
              archiveTag={archiveTag}
              updateTag={updateTag}
              getTags={getTags}
              getAll={getAll}
              toggleSelectedTag={toggleSelectedTag}
              selected={selectedTags.includes(tag.id)}
            />
          )}
          <Menu.Divider />
          {!adding ? (
            <Menu.Item
              icon="add"
              text="Add tag"
              onClick={(e) => {
                e.stopPropagation()
                this.setState({adding: true})
              }}
            />
          ) : (
            <form onSubmit={this.createTag}>
              <InputGroup
                onClick={(e) => e.stopPropagation() }
                value={newTag}
                autoFocus={true}
                onChange={(e) => this.setState({newTag: e.target.value})}
                rightElement={
                  <div onClick={(e) => e.stopPropagation()}>
                    <Button
                      icon="tick"
                      onClick={this.createTag}
                    />
                    <Button icon="disable" onClick={(e) => {
                      this.setState({adding: false, newTag: ""})
                    }} />
                  </div>
                }
              />
            </form>
          )}
          <Menu.Item
            icon="small-cross"
            text="Clear Filters"
            disabled={selectedTags.length === 0}
            onClick={() => clearSelectedTags().then((getAll()))}
          />
        </Menu>
      } position={Position.BOTTOM_LEFT}>
        <Button text={buttonLabel} icon="filter" rightIcon="caret-down" className={className}/>
      </Popover>
    )
  }

  createTag = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.createTag(this.state.newTag)
      .then(() => this.props.getTags())
      .then(() => this.setState({adding: false}))
  }

}

export default ListFilter;