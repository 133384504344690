import React from 'react'
import Formsy from 'formsy-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Dialog, Button, Intent} from '@blueprintjs/core'
import {MultiSelect} from '../../../components/form';
import {Toaster} from '../../../components';
import {getAll as getAllServices} from '../../../state/service/actions'
import {linkSync, getOne} from '../../../state/sermon/actions'
import moment from 'moment-timezone'

class AddSermonToServiceDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false
    }

  }

  componentDidMount() {
    this.props.getAllServices();
  }

  render() {

    const { trigger, services, sermon } = this.props;
    const { open } = this.state;

    return (
      <>
        {trigger(() => this.setState({open: true}))}
        <Dialog
          iconName="music"
          isOpen={open}
          onClose={() => this.setState({open: false}) }
          title="Link message to services"
        >
          <div className="bp3-dialog-body">
            <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
                    onInvalidSubmit={() => this.onInvalidSubmit() }
                    ref="form">
              <MultiSelect
                name="services"
                icon="calendar"
                value={sermon.service.map((service) => ({
                  value: service.id + "",
                  label: moment(service.date).format("LL")
                }))}
                isMulti
                options={services.map((service) => ({
                  value: service.id + "",
                  label: moment(service.date).format("LL")
                }))}
                label="Services with this message:" />
            </Formsy>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text="Cancel" onClick={() => this.setState({open: false})}/>
              <Button
                intent={Intent.PRIMARY}
                onClick={() => this.refs.form.submit() }
                text="Submit"
              />
            </div>
          </div>
        </Dialog>
      </>
    )
  }

  onValidSubmit(model) {

    const { linkSync, sermon, getOne } = this.props;

    var serviceIds = model.services ? model.services.map((service) => service.value) : [];

    linkSync(sermon.id, serviceIds.map((id) => parseInt(id)), "service")
      .then(() => this.setState({open: false}))
      .then(() => getOne(sermon.id))
      .catch((e) => {
        Toaster.show({
          message: e.message,
          iconName: "warning-sign",
          intent: Intent.DANGER
        });
      })
  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

}

export default connect(
  (state) => {
    return {
      sermon: state.sermon.item,
      services: state.service.items
    }
  },
  (dispatch) => (
    bindActionCreators({
      getAllServices, linkSync, getOne
    }, dispatch)
  )
)(AddSermonToServiceDialog);


