import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ClassNames from 'classnames';
import {Icon, Menu, MenuDivider, MenuItem, Popover, Position, Spinner, Tooltip, Tag, Intent, Classes} from '@blueprintjs/core'
import {
  archive,
  archiveTag,
  clearSelectedTags,
  createTag,
  duplicate,
  getAll,
  getTags,
  setOrdering,
  toggleSelectedTag,
  updateTag,
  linkOne
} from '../../../state/person/actions'
import {AddPersonButton} from "../components";
import {ListFilter, QuickAddTag} from "../../components";
import {Link} from 'gatsby'
import {Toaster} from "../../../components/index";
import * as Paths from "../../../constants/Paths";
import {generateCSV} from "../../../managers/csv";
import {getContext} from "../../../state/context/selectors";
import {buildLookup} from '../../../utils/utils'

import Moment from 'moment-timezone'

class PersonList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    this.getAllPersons();
    this.props.getTags();
  }

  getAllPersons = () => {
    this.setState({loading: true});
    this.props.getAll()
      .then(() => this.setState({loading: false}))
      .catch((e) => this.setState({loading: false}))
  };

  render() {

    const {
      persons,
      tags,
      orderBy,
      orderDir,
      toggleSelectedTag,
      clearSelectedTags,
      selectedTags,
      archiveTag,
      getTags,
      updateTag,
      createTag,
      context,
      tagLookup,
      linkOne
    } = this.props;
    const { loading } = this.state;

    const sorteableHeader = (columnName, columnTitle) => (
      <th
        className={
          ClassNames("th-name ordereable", {
            ordered: orderBy === columnName,
            "ordered-asc": orderBy === columnName && orderDir === "asc",
            "ordered-desc": orderBy === columnName && orderDir === "desc"
          })
        }
        onClick={() => this.onHeaderOrder(columnName)}>
        {orderBy === columnName && (
          orderDir === "asc" ?
            <Icon icon="arrow-up"/>
          : orderDir === "desc" ?
            <Icon icon="arrow-down"/> : null
          )}
        {columnTitle}
      </th>
    );

    return (
      <div className="PersonList">
        <div className="card-list">
          <div className="header grid-row v-align">
            <div className="grid-item">
              <ListFilter
                buttonLabel={"People Categories"}
                className="sr3"
                tags={tags}
                archiveTag={archiveTag}
                updateTag={updateTag}
                getTags={getTags}
                getAll ={this.getAllPersons}
                toggleSelectedTag={toggleSelectedTag}
                selectedTags={selectedTags}
                clearSelectedTags={clearSelectedTags}
                createTag={createTag}
              />
              {selectedTags.map((selectedTag, idx) => (
                <Tag
                  key={idx}
                  large
                  onRemove={() => toggleSelectedTag(selectedTag).then(this.getAllPersons)}
                  intent={Intent.SUCCESS}
                  className="sr1"
                >
                  {tagLookup[selectedTag] ? tagLookup[selectedTag].name : "...loading"}
                </Tag>
              ))}
            </div>
            <AddPersonButton className="bp3-button bp3-icon-add bp3-intent-primary">Add New Person</AddPersonButton>
          </div>
          <div className="body">
            {loading ? (
              <Spinner size={50} />
            )
            : persons.length === 0 ? (
              <div className="bp3-non-ideal-state">
                <div className="bp3-non-ideal-state-visual bp3-non-ideal-state-icon">
                  <span className="bp3-icon bp3-icon-folder-open"></span>
                </div>
                <h4 className="bp3-non-ideal-state-title">You don't have any persons yet.</h4>
              </div>
            ) : (
              <table className="bp3-table">
                <thead>
                <tr>
                  <th className="th-icon"></th>
                  {sorteableHeader("spoken_first", "First Name")}
                  {sorteableHeader("last", "Last Name")}
                  {sorteableHeader("email", "Email")}
                  {sorteableHeader("next_birthday", "Birthday")}
                  {sorteableHeader("phone", "Phone Number")}
                  <th className="th-name" style={{'max-width' : 200}}>
                    <Tooltip content={`To filter by category/tag, use the "filter" button in the top left of this list.`} position={Position.RIGHT}>
                      <span>Category</span>
                    </Tooltip>
                  </th>
                  <th className="th-menu"></th>
                </tr>
                </thead>
                <tbody>
                {persons.map((person, idx) => (
                  <tr key={idx}>
                    <td className="td-icon"><span className="bp3-icon-standard bp3-icon-person"></span></td>
                    <td className="td-name"><Link
                      to={Paths.withId(Paths.PERSON_EDIT, person.id)}>{person.spoken_first || person.first}</Link>
                    </td>
                    <td className="td-name">{person.last}</td>
                    <td className="td-name">{person.email}</td>
                    <td className="td-name">
                      {person.next_birthday && Moment.utc(person.next_birthday).format("MMMM Do")}
                    </td>
                    <td className="td-name">{person.phone}</td>
                    <td className="td-name">
                      <QuickAddTag
                        tags={tags}
                        linkOne={linkOne}
                        entityId={person.id}
                      />
                      {person.tags.map((tag, idx) => (
                        <span key={idx} className="bp3-tag bp3-minimal bp3-intent-success sr1">{tag.name}</span>
                      ))}
                    </td>
                    <td className="td-menu">
                      <Popover
                        position={Position.BOTTOM_RIGHT}
                        content={(
                          <Menu className="bp3-minimal">
                            <li>
                              <Link className="bp3-menu-item bp3-icon-edit"
                                    to={Paths.withId(Paths.PERSON_EDIT, person.id)}>Edit</Link>
                            </li>
                            <MenuItem text="Clone" iconname="duplicate" onClick={() => this.onDuplicate(person)}/>
                            <MenuDivider/>
                            <li className={Classes.POPOVER_DISMISS}>
                              <a className="bp3-menu-item person bp3-intent-danger"
                                 onClick={() => this.onArchive(person)}>Archive</a>
                            </li>
                          </Menu>
                         )}>
                        <span className="bp3-icon-standard bp3-icon-more"></span>
                      </Popover>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    )
  }

  onHeaderOrder(header) {

    const { setOrdering, orderDir, orderBy } = this.props;

    if (orderBy === header) {
      if (orderDir === "asc") setOrdering({orderDir: "desc", orderBy});
      else setOrdering({orderBy: null, orderDir: null})
    } else {
      setOrdering({orderBy: header, orderDir: "asc"});
    }

    this.props.getAll()
  }

  onArchive(person) {

    const { archive, getAll } = this.props;

    confirm("Are you sure?") ? archive(person.id)
      .then(() => getAll())
      .then(() => Toaster.success("Successfully archived person."))
      .catch((e) => Toaster.error(e.message)) : null;

  }

  onDuplicate(person) {

    const { duplicate, getAll } = this.props;

    duplicate(person.id)
      .then(() => getAll())
      .then(() => Toaster.success("Successfully duplicated person."))
      .catch((e) => Toaster.error(e.message))

  }

  onExport() {
    generateCSV([["name"]]
      .concat(this.props.persons.map((person) => [
        person.name
      ])), `Persons`);
  }

}

PersonList.defaultProps = {
  persons: [],
  tags: []
}

export default connect(
  (state) => {
    return {
      persons: state.person.items,
      selectedTags: state.person.selectedTags,
      tags: state.person.tags,
      orderBy: state.person.orderBy,
      orderDir: state.person.orderDir,
      context: getContext(state),
      tagLookup: buildLookup(state.person.tags, "id")
    }
  },
  (dispatch) => (
    bindActionCreators({
      getAll,
      archive,
      duplicate,
      getTags,
      setOrdering,
      toggleSelectedTag,
      clearSelectedTags,
      archiveTag,
      updateTag,
      createTag,
      linkOne
    }, dispatch)
  )
)(PersonList);