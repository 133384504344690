import moment from 'moment-timezone';

const initalZones = [
  "US/Central",
  "US/Pacific",
  "US/Eastern",
  "US/Mountain"
];

var momentTimezones = moment.tz.names();

var filteredZones = momentTimezones.filter((tz) => {
  return initalZones.indexOf(tz) === -1;
});

export const TIMEZONES = initalZones.concat(filteredZones);
