import React from 'react'
import Formsy from 'formsy-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Dialog, Button, Intent} from '@blueprintjs/core'
import {MultiSelect} from '../../../components/form';
import {Toaster} from '../../../components';
import {getAll as getAllSongs} from '../../../state/song/actions'
import {linkSync, getOne} from '../../../state/service/actions'

class AddSongToServiceDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false
    }

  }

  componentDidMount() {
    this.props.getAllSongs();
  }

  render() {

    const { trigger, service, songs } = this.props;
    const { open } = this.state;

    return (
      <>
        {trigger(() => this.setState({open: true}))}
        <Dialog
          iconName="music"
          isOpen={open}
          onClose={() => this.setState({open: false}) }
          title="Add songs to service"
        >
          <div className="bp3-dialog-body">
            <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
                    onInvalidSubmit={() => this.onInvalidSubmit() }
                    ref="form">
              <MultiSelect
                name="songs"
                icon="calendar"
                value={service.songs.map((song) => ({
                  value: song.id + "",
                  label: song.name
                }))}
                isMulti
                options={songs.map((song) => ({
                  value: song.id + "",
                  label: song.name
                }))}
                label="Songs in service:" />
            </Formsy>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text="Cancel" onClick={() => this.setState({open: false})}/>
              <Button
                intent={Intent.PRIMARY}
                onClick={() => this.refs.form.submit() }
                text="Submit"
              />
            </div>
          </div>
        </Dialog>
      </>
    )
  }

  onValidSubmit(model) {

    const { linkSync, service, getOne } = this.props;

    var songIds = model.songs.map((song) => song.value);

    linkSync(service.id, "song", songIds.map((id) => parseInt(id)))
      .then(() => this.setState({open: false}))
      .then(() => getOne(service.id))
      .catch((e) => {
        Toaster.show({
          message: e.message,
          iconName: "warning-sign",
          intent: Intent.DANGER
        });
      })
  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

}

export default connect(
  (state) => {
    return {
      service: state.service.item,
      songs: state.song.items
    }
  },
  (dispatch) => (
    bindActionCreators({
      getAllSongs, linkSync, getOne
    }, dispatch)
  )
)(AddSongToServiceDialog);


