export const generateCSV = (data = [], filename = "download.csv") => {

  var csvContent = "data:text/csv;charset=utf-8,";
  var dataString = "";

  data.forEach((infoArray, index) => {
    dataString = infoArray.join(",");
    csvContent += index < data.length ? dataString+ "\n" : dataString;
  });

  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");

  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename);
  document.body.appendChild(link); // Required for FF

  link.click();
}

