import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {DashboardLayoutA} from "../layouts";
import {Menu, Popover, Button, Intent} from '@blueprintjs/core'
import {getAll as getAllNotifications, create} from '../../state/notification/actions'
import {BirthdayNotification} from './components'
import Moment from 'moment-timezone'

class NotificationOverviewPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }

  }

  componentDidMount() {
    Promise.all([
      this.props.getAllNotifications()
    ]).then(() => {
      this.setState({loading: false})
    })
  }

  render() {

    const { personalNotifications } = this.props;
    const { loading } = this.state;

    return (
      <DashboardLayoutA className="page-content">
        <div className="NotificationOverviewPage">
          <div className="grid-row">
            <div className="grid-item sr5">
              <div className="card-list">
                <div className="header grid-row v-align">
                  <div className="title grid-item">Personal Notifications</div>
                  <Popover
                    content={
                      <Menu>
                        <Menu.Item
                          icon="cube"
                          text="Upcoming Birthdays"
                          onClick={() => this.onNewBirthdayNotification()}
                        />
                      </Menu>
                    }
                    target={
                      <Button intent={Intent.PRIMARY} text={"Add Personal Notification"} icon="add" />
                    }
                  />
                </div>
                {!loading && (
                  personalNotifications.map((n, idx) => (
                    <BirthdayNotification key={n.type + n.id} notification={n} />
                  ))
                )}
              </div>
            </div>
            <div className="grid-item">
              <div className="card-list">
                <div className="header grid-row v-align">
                  <div className="title grid-item">Church-wide Notifications</div>
                  <Popover
                    content={
                      <Menu>
                        <Menu.Item
                          icon="cube"
                          text="Messages List"
                          onClick={() => this.onNewWidget("sermonlist")}
                        />
                      </Menu>
                    }
                    target={
                      <Button intent={Intent.PRIMARY} text={"Add Church Notification"} icon="add" />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayoutA>
    )
  }

  onNewBirthdayNotification = () => {

    var nextNotification = Moment(`Monday 10`, "dddd H");
    if (nextNotification.valueOf() < Date.now()) nextNotification = nextNotification.add(1, "weeks");

    this.props.create({
      notify_at: nextNotification.valueOf(),
      "type": "BIRTHDAY",
      "config": {
        "interval_minutes": 10080,
        "window_minutes": 10080
      }
    }).then(() => this.props.getAllNotifications())
  }

}

export default connect(
  (state) => {
    return {
      personalNotifications: state.notification.items.filter((i) => i.personal),
      churchNotifications: state.notification.items.filter((i) => !i.personal)
    }
  },
  (dispatch) => (
    bindActionCreators({
      getAllNotifications, create
    }, dispatch)
  )
)(NotificationOverviewPage);
