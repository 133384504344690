import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ApiConfigureBlock from './ApiConfigureBlock'
import {upsert as upsertEntityApi} from '../../../../state/entityapi/actions'
import {getContext} from "../../../../state/context/selectors";

class ApiConfigure extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      serviceExpanded: false,
      sermonExpanded: false,
      personExpanded: false,
      songExpanded: false,
      teamExpanded: false,
      titheExpanded: false,
      resourceExpanded: false
    };

  }

  render() {

    const {
      context,
      serviceTags,
      personTags,
      sermonTags,
      serviceEntityApi,
      personEntityApi,
      sermonEntityApi,
      songEntityApi,
      titheEntityApi,
      resourceEntityApi,
      upsertEntityApi
    } = this.props;

    return (
      <div>
        <ApiConfigureBlock
          entity="service"
          expanded={this.state.serviceExpanded}
          intialEntityApi={serviceEntityApi}
          onToggleExpand={() => this.setState({serviceExpanded: !this.state.serviceExpanded})}
          tags={serviceTags}
          upsertEntityApi={upsertEntityApi}
          context={context}
        />
        <ApiConfigureBlock
          entity="sermon"
          title="message"
          expanded={this.state.sermonExpanded}
          intialEntityApi={sermonEntityApi}
          onToggleExpand={() => this.setState({sermonExpanded: !this.state.sermonExpanded})}
          tags={sermonTags}
          upsertEntityApi={upsertEntityApi}
          context={context}
        />
        <ApiConfigureBlock
          entity="person"
          expanded={this.state.personExpanded}
          intialEntityApi={personEntityApi}
          onToggleExpand={() => this.setState({personExpanded: !this.state.personExpanded})}
          tags={personTags}
          upsertEntityApi={upsertEntityApi}
          context={context}
        />
        <ApiConfigureBlock
          entity="song"
          expanded={this.state.songExpanded}
          intialEntityApi={songEntityApi}
          onToggleExpand={() => this.setState({songExpanded: !this.state.songExpanded})}
          tags={this.props.songTags}
          upsertEntityApi={upsertEntityApi}
          context={context}
        />
        <ApiConfigureBlock
          entity="tithe"
          expanded={this.state.titheExpanded}
          intialEntityApi={titheEntityApi}
          onToggleExpand={() => this.setState({titheExpanded: !this.state.titheExpanded})}
          tags={this.props.titheTags}
          upsertEntityApi={upsertEntityApi}
          context={context}
        />
        {resourceEntityApi && (
          <ApiConfigureBlock
            entity="resource"
            expanded={this.state.resourceExpanded}
            intialEntityApi={resourceEntityApi}
            onToggleExpand={() => this.setState({resourceExpanded: !this.state.resourceExpanded})}
            tags={this.props.resourceTags}
            upsertEntityApi={upsertEntityApi}
            context={context}
          />
        )}
      </div>
    );
  }

}

export default connect(
  (state, props) => {
    return {
      serviceTags: state.service.tags,
      sermonTags: state.sermon.tags,
      personTags: state.person.tags,
      songTags: state.song.tags,
      titheTags: state.tithe.tags,
      resourceTags: state.resource.tags,

      serviceEntityApi: state.entityapi.service,
      sermonEntityApi: state.entityapi.sermon,
      personEntityApi: state.entityapi.person,
      songEntityApi: state.entityapi.song,
      teamEntityApi: state.entityapi.team,
      titheEntityApi: state.entityapi.tithe,
      resourceEntityApi: state.entityapi.resource,

      context: getContext(state)
    }
  },
  (dispatch) => (
    bindActionCreators({
      upsertEntityApi
    }, dispatch)
  )
)(ApiConfigure);
