import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Intent} from '@blueprintjs/core'
import {navigate} from 'gatsby'
import {Toaster} from "../../../components/index";
import {
  archive as archiveEntry,
} from "../../../state/song/actions";
import * as Paths from "../../../constants/Paths";

class ArchiveSongButton extends React.Component {

  render() {

    const { className } = this.props;

    return <>
      <button className={className}
              onClick={() => this.onDelete()}>{this.props.children}</button>
    </>
  }

  onDelete() {

    const { archiveEntry, id } = this.props;

    if (confirm("Are you sure?")) {
      archiveEntry(id)
        .then(() => {
          Toaster.show({
            message: "Successfully archived song.",
            iconName: "tick",
            intent: Intent.SUCCESS
          });
          navigate(Paths.SONG_LIST)
        })
        .catch((e) => {
          Toaster.show({
            message: e.message,
            iconName: "warning-sign",
            intent: Intent.DANGER
          });
        })
    }

  }

}

export default connect(
  (state) => {
    return {

    }
  },
  (dispatch) => (
    bindActionCreators({
      archiveEntry
    }, dispatch)
  )
)(ArchiveSongButton);


