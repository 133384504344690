import React from 'react'
import {AddRoleDialog} from './'

class AddRoleButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = { isDialogOpen: false }

  }

  render() {

    const { isDialogOpen } = this.state;
    const { className, onSuccess = () => {} } = this.props;

    return <>
      <button className={className}
              key={0}
              onClick={() => this.setState({isDialogOpen: true})}>{this.props.children}</button>
      <AddRoleDialog isOpen={isDialogOpen}
                     onSuccess={onSuccess}
                     key={1}
                     onClose={() => this.setState({isDialogOpen: false})}/>
    </>
  }

}

export default AddRoleButton;
