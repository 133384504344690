import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {DashboardLayoutA} from "../layouts";
import {PersonList} from './containers'

class PersonListPage extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
      <DashboardLayoutA className="page-content">
        <div className="PersonListPage">
          <PersonList />
        </div>
      </DashboardLayoutA>
    )
  }

}

export default connect(
  (state) => {
    return {

    }
  },
  (dispatch) => (
    bindActionCreators({

    }, dispatch)
  )
)(PersonListPage);