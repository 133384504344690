import * as PersonApi from "../../api/PersonApi";
import * as actionTypes from "./actionTypes";
import {getContext} from '../context/selectors';
import * as SermonApi from "../../api/SermonApi";

export const getOne = (personId) => (dispatch, state) =>
  PersonApi.getOne(personId, getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_ONE, item: result.item }));

export const getAll = (options = {}) => (dispatch, state) =>
  (options.noFilters
    ? PersonApi.getAll({}, getContext(state))
    : PersonApi.getAll({ orderby:  state().person.orderBy, orderdir:  state().person.orderDir, tags:  state().person.selectedTags }, getContext(state))
  ).then((result) => {
    dispatch({ type: actionTypes.GET_ALL, items: result.items })
  });

export const create = (person) => (dispatch, state) =>
  PersonApi.create(person, getContext(state))
    .then((result) => {
      dispatch({ type: actionTypes.CREATE, item: result.item });
      return result;
    });

export const update = (personId, person) => (dispatch, state) =>
  PersonApi.update(personId, person, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE }));

export const patch = (personId, patch) => (dispatch, state) =>
  PersonApi.patch(personId, patch, getContext(state))
    .then(() => dispatch({ type: actionTypes.PATCH }));

export const duplicate = (personId) => (dispatch, state) =>
  PersonApi.duplicate(personId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DUPLICATE }));

export const archive = (personId) => (dispatch, state) =>
  PersonApi.archive(personId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE }));

export const unArchive = (personId) => (dispatch, state) =>
  PersonApi.unArchive(personId, getContext(state))
    .then(() => dispatch({ type: actionTypes.UNARCHIVE }));

export const hardDelete = (personId) => (dispatch, state) =>
  PersonApi.hardDelete(personId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DELETE }));

export const getTags = () => (dispatch, state) =>
  PersonApi.getTags(getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_TAGS, items: result.items }));

export const createTag = (name) => (dispatch, state) =>
  PersonApi.createTag({ name }, getContext(state))
    .then((result) => dispatch({ type: actionTypes.CREATE_TAG, item: result.item }));

export const linkOne = (personId, linkId, linkType) => (dispatch, state) =>
  PersonApi.linkOne(personId, linkId, linkType, getContext(state))
    .then(() => dispatch({ type: actionTypes.LINK_ONE, entityId: personId, linkId, linkType }));

export const unlinkOne = (personId, linkId, linkType) => (dispatch, state) =>
  PersonApi.linkOne(personId, linkId, linkType, getContext(state))
    .then(() => dispatch({ type: actionTypes.UNLINK_ONE, entityId: personId, linkId, linkType }));

export const linkSync = (personId, tagIds) => (dispatch, state) =>
  PersonApi.tagLinkSync(personId, tagIds, getContext(state))
    .then((result) => dispatch({ type: actionTypes.TAG_LINK_SYNC, item: result.item }));

export const archiveTag = (tagId) => (dispatch, state) =>
  PersonApi.archiveTag(tagId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE_TAG }));

export const updateTag = (tagId, name) => (dispatch, state) =>
  PersonApi.updateTag(tagId, name, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE_TAG }));

export const setOrdering = ({orderBy, orderDir}) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ORDER,
    orderBy,
    orderDir
  });
  return Promise.resolve();
};

export const toggleSelectedTag = (tagId) => (dispatch, state) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAGS,
    selectedTags: state().person.selectedTags.includes(tagId)
      ? state().person.selectedTags.filter((eTagId) => eTagId !== tagId)
      : [...state().person.selectedTags, tagId]

  });
  return Promise.resolve();
};

export const clearSelectedTags = () => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAGS,
    selectedTags: []
  });

  return Promise.resolve();
};