import React from 'react';
import _ from 'lodash';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Formsy from 'formsy-react';
import {createOrg, getOrgs} from '../../../state/org/actions'
import {switchContext} from '../../../state/context/actions'
import {TextInput, Select} from '../../../components/form';
import {navigate} from 'gatsby'
import * as Paths from '../../../constants/Paths'
import {TIMEZONES} from '../../../constants/Timezones';

class CreateOrgForm extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      processing: false,
      error: null
    };

  }

  onValidSubmit(model) {

    const { onSuccess, onError, createOrg, getOrgs, switchContext } = this.props;
    this.setState({processing: true, error: null});

    createOrg({
      name: model.name,
      timezone: model.timezone,
      short_name: model.shortName,
      bg_img_url: "https://wallpaperaccess.com/full/183868.jpg"
    }).then((newOrg) => {
      switchContext("org", newOrg.id)
    }).catch((e) => {
      this.setState({processing: false, error: e.message});
      onError(e.message);
    });

  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

  render() {

    const { showErrors, processing, error } = this.state;
    const { onCancel = _.noop } = this.props;

    return (
      <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
              onInvalidSubmit={() => this.onInvalidSubmit() }>
        <div className="form-fields">
          <TextInput name="name"
                 type="text"
                 icon="build"
                 large
                 label="Church full name:"
                 showErrors={showErrors}
                 required />
          <TextInput name="shortName"
                 type="text"
                 icon="build"
                 large
                 label="Short name / acronym:"
                 showErrors={showErrors}
                 required />
          <Select name="timezone"
                  label="Timezone:"
                  large
                  value={TIMEZONES[0].abbr}
                  showErrors={showErrors}
                  options={TIMEZONES.map((timezone) => ({name: timezone, value: timezone}))}
                  required />
        </div>
        <div className="form-submit-group grid-row">
          <div className="grid-item grid-row v-align">
            {error && (
              <div className="error-message">
                {error}
              </div>
            )}
          </div>
          <div>
            <button type="button"
                    className="bp3-button bp3-large sr2" onClick={() => onCancel() }>Cancel</button>
            {!processing ? (
              <button type="submit" className="bp3-button bp3-large bp3-intent-primary">Submit</button>
            ) : (
              <button type="submit" className="bp3-button bp3-large bp3-disabled ">Submitting...</button>
            )}
          </div>
        </div>
      </Formsy>
    );
  }
}

CreateOrgForm.defaultProps = {
  onSuccess: _.noop,
  onError: _.noop
}

export default connect(
  (state) => {
    return {
    }
  },
  (dispatch) => (
    bindActionCreators({
      createOrg, getOrgs, switchContext
    }, dispatch)
  )
)(CreateOrgForm);
