import React from 'react'
import * as Paths from "../../../constants/Paths";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {getRecentServices, setRecentServiceTag} from '../../../state/overview/actions'
import {getTags} from '../../../state/sermon/actions'

class PrayerRequests extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getRecentServices();
    this.props.getTags()
  }

  render() {

    const { services } = this.props;

    return (
      <div className="RecentServices">
        <div className="card-list grid-item">
          <div className="header">
            <div className="title">Recent & Upcoming Services:</div>
          </div>
          <div className="body">
            {services.length > 0 ? (
                <div className="inner-body">
                  <div className="bp3-non-ideal-state">
                    <div className="bp3-non-ideal-state-visual bp3-non-ideal-state-icon">
                      <span className="bp3-icon bp3-icon-feed"></span>
                    </div>
                    {/*<h5 className="bp3-non-ideal-state-title">No recent services.</h5>*/}
                  </div>
                </div>
              // <table className="bp3-table">
              //   <tbody>
              //   {sermon.service.sort((a, b) => a.id < b.id ? 1 : -1).map((service, idx) => (
              //     <tr key={idx}>
              //       <td className="td-icon"><span className="bp3-icon-standard bp3-icon-bookmark"></span></td>
              //       <td className="td-name"><Link
              //         to={Paths.withId(Paths.SERVICE_EDIT, service.id)}>
              //         {moment(service.date, "YYYY-MM-DD HH:mm:ss").format("LLLL")}
              //       </Link></td>
              //       <td className="td-name">{service.author}</td>
              //     </tr>
              //   ))}
              //   </tbody>
              // </table>
            ): (
              <div className="inner-body">
                <div className="bp3-non-ideal-state">
                  <div className="bp3-non-ideal-state-visual bp3-non-ideal-state-icon">
                    <span className="bp3-icon bp3-icon-feed"></span>
                  </div>
                  <h5 className="bp3-non-ideal-state-title">Widget not integrated.</h5>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

}

export default connect(
  (state) => {
    return {
      lastService: state.overview.recentServices.lastService,
      nextService: state.overview.recentServices.nextService,
      selectedTag: state.overview.recentServices.selectedTag,
      services: state.service.items
    }
  },
  (dispatch) => (
    bindActionCreators({
      getRecentServices, setRecentServiceTag, getTags
    }, dispatch)
  )
)(PrayerRequests);
