import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Formsy from 'formsy-react';
import {MultiSelect, DateTimeInput, NumberInput, Textarea} from '../../../components/form';
import {getOne, update, patch, linkSync, createTag} from "../../../state/tithe/actions";

class TitheEditForm extends React.Component {

  componentDidMount() {
    if (this.props.id) {
      this.props.getOne(this.props.id);
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      isProcessing: false,
      message: null,
      messageIsError: false,
      isPristine: true
    };

  }

  render() {

    const { showErrors, isProcessing, message, messageIsError, isPristine, reset } = this.state;
    const { tithe, tags } = this.props;

    return (
      <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
              onChange={(values, isChanged) => this.checkForPristine(values, isChanged) }
              onInvalidSubmit={() => this.onInvalidSubmit() }
              ref="form">
        <div className="form-fields">
          <DateTimeInput name="date"
                         icon="calendar"
                         label="Date of tithe:"
                         value={tithe.date}
                         timePrecision={null} />
          <NumberInput name="amount"
                       large
                       value={tithe.amount}
                       label="Tithe amount:" />
          <Textarea name="notes"
                    value={tithe.notes}
                    label="Internal Notes:"
                    showErrors={showErrors}
          />
          <MultiSelect
            name="tags"
            type="text"
            icon="build"
            createable
            value={tithe.tags.map((tag) => ({
              value: tag.id + "",
              label: tag.name
            }))}
            isMulti
            options={tags.map((tag) => ({
              value: tag.id + "",
              label: tag.name
            }))}
            label="Tithe name:"
            showErrors={showErrors}
          />
        </div>
        <div className="form-submit-group grid-row">
          <div className="grid-item grid-row v-align">
            {message && (
              <div className={"message" + (messageIsError ? " error-message" : "")}>
                {message}
              </div>
            )}
          </div>
          <div>
            <button className={"bp3-button sr2" + (isPristine ? " bp3-disabled" : "")}
                    type="button"
                    onClick={() => this.onCancel() }>Cancel</button>
            {!isProcessing ? (
              <button type="submit"
                      className={"bp3-button bp3-intent-primary" + (isPristine ? " bp3-disabled" : "")}>Submit</button>
            ) : (
              <button type="submit"
                      className="bp3-button bp3-disabled ">Submitting...</button>
            )}
          </div>
        </div>
      </Formsy>
    );
  }

  onValidSubmit(model) {

    const { isPristine } = this.state;
    const { tithe, linkSync, patch, getOne, createTag } = this.props;
    if (isPristine) return;

    this.setState({isProcessing: true, serverError: null});

    var selectedTags = model.tags || [];

    Promise
      .all(selectedTags.filter((tag) => tag.__isNew__).map((tag) => createTag(tag.label)))
      .then((results) => {
        // find new tags and link them.

        var newTags = results.map((result) => result.item.id);
        var currentTags = selectedTags.filter((tag) => !tag.__isNew__).map((tag) => parseInt(tag.value));

        return Promise.all([
          patch(tithe.id, {
            date: model.date,
            amount: model.amount,
            notes: model.notes
          }),
          linkSync(tithe.id, [...newTags, ...currentTags])
        ]);

      })
      .then((result) => getOne(tithe.id))
      .then((result) => {
        this.setState({
          isProcessing: false,
          message: "Your changes were applied successfully.",
          messageIsError: false,
          reset: true
        }, () => this.setState({reset: false}));

      })
      .catch((e) => {
        this.setState({
          isProcessing: false,
          message: e.message,
          messageIsError: true
        });
      });
  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

  checkForPristine(values, isChanged) {
    this.setState({isPristine: !isChanged});
  }

  onCancel() {

    const { isPristine } = this.state;
    if (isPristine) return;

    this.refs.form.reset();
    this.setState({
      message : "Your changes have been discarded.",
      messageIsError: false
    });
  }

}

export default connect(
  (state, props) => {
    return {
      tithe: props.tithe ? props.tithe : state.tithe.item,
      tags: state.tithe.tags || [],
    }
  },
  (dispatch) => (
    bindActionCreators({
      update, createTag, getOne, patch, linkSync
    }, dispatch)
  )
)(TitheEditForm);
