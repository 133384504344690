import React from 'react'
import {DashboardLayoutA} from '../layouts'
import {EditUserForm, ChangePasswordForm} from './components'

class UserInfoPage extends React.Component {
  render() {
    return (
      <DashboardLayoutA className="OrgSettings">
        <div className="grid-restrain-narrow full-width">
            <div className="card-settings s4 full-width">
              <div className="inner-header">
                <div className="heading">Basic info:</div>
              </div>
              <div className="inner-form">
                <EditUserForm />
              </div>
            </div>
            <div className="card-settings s4">
              <div className="inner-header">
                <div className="heading">Change Password:</div>
              </div>
              <div className="inner-form">
                <ChangePasswordForm />
              </div>
            </div>
        </div>
      </DashboardLayoutA>
    )
  }

}

export default UserInfoPage;
