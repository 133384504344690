import * as TitheApi from "../../api/TitheApi";
import * as actionTypes from "./actionTypes";
import {getContext} from '../context/selectors';
import * as SermonApi from "../../api/SermonApi";

export const getOne = (titheId) => (dispatch, state) =>
  TitheApi.getOne(titheId, getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_ONE, item: result.item }));

export const getAll = () => (dispatch, state) =>
  TitheApi.getAll(
    { orderby:  state().tithe.orderBy, orderdir:  state().tithe.orderDir, tags:  state().tithe.selectedTags },
    getContext(state)
  ).then((result) => {
    dispatch({ type: actionTypes.GET_ALL, items: result.items })
  });

export const create = (tithe) => (dispatch, state) =>
  TitheApi.create(tithe, getContext(state))
    .then((result) => {
      dispatch({ type: actionTypes.CREATE, item: result.item });
      return result;
    });

export const update = (titheId, tithe) => (dispatch, state) =>
  TitheApi.update(titheId, tithe, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE }));

export const patch = (titheId, patch) => (dispatch, state) =>
  TitheApi.patch(titheId, patch, getContext(state))
    .then(() => dispatch({ type: actionTypes.PATCH }));

export const linkOne = (titheId, linkId, linkType) => (dispatch, state) =>
  TitheApi.linkOne(titheId, linkId, linkType, getContext(state))
    .then(() => dispatch({ type: actionTypes.LINK_ONE, entityId: titheId, linkId, linkType }));

export const unlinkOne = (titheId, linkId, linkType) => (dispatch, state) =>
  TitheApi.linkOne(titheId, linkId, linkType, getContext(state))
    .then(() => dispatch({ type: actionTypes.UNLINK_ONE, entityId: titheId, linkId, linkType }));

export const linkSync = (titheId, linkIds) => (dispatch, state) =>
  TitheApi.linkSync(titheId, linkIds, getContext(state))
    .then(() => dispatch({ type: actionTypes.LINKSYNC }));

export const duplicate = (titheId) => (dispatch, state) =>
  TitheApi.duplicate(titheId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DUPLICATE }));

export const archive = (titheId) => (dispatch, state) =>
  TitheApi.archive(titheId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE }));

export const unArchive = (titheId) => (dispatch, state) =>
  TitheApi.unArchive(titheId, getContext(state))
    .then(() => dispatch({ type: actionTypes.UNARCHIVE }));

export const hardDelete = (titheId) => (dispatch, state) =>
  TitheApi.hardDelete(titheId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DELETE }));

export const getTags = () => (dispatch, state) =>
  TitheApi.getTags(getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_TAGS, items: result.items }));

export const createTag = (name) => (dispatch, state) =>
  TitheApi.createTag({ name }, getContext(state))
    .then((result) => dispatch({ type: actionTypes.CREATE_TAG, item: result.item }));

export const archiveTag = (tagId) => (dispatch, state) =>
  TitheApi.archiveTag(tagId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE_TAG }));

export const updateTag = (tagId, name) => (dispatch, state) =>
  TitheApi.updateTag(tagId, name, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE_TAG }));

export const setOrdering = ({orderBy, orderDir}) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ORDER,
    orderBy,
    orderDir
  });
  return Promise.resolve();
};

export const toggleSelectedTag = (tagId) => (dispatch, state) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAGS,
    selectedTags: state().tithe.selectedTags.includes(tagId)
      ? state().tithe.selectedTags.filter((eTagId) => eTagId !== tagId)
      : [...state().tithe.selectedTags, tagId]

  });
  return Promise.resolve();
};

export const clearSelectedTags = () => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAGS,
    selectedTags: []
  });

  return Promise.resolve();
};