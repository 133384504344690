import * as ServiceApi from "../../api/ServiceApi";
import * as actionTypes from "./actionTypes";
import {getContext} from '../context/selectors';
import {Toaster} from "../../components";
import {Intent} from '@blueprintjs/core'

export const getOne = (serviceId) => (dispatch, state) =>
  ServiceApi.getOne(serviceId, getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_ONE, item: result.item }));

export const getAll = () => (dispatch, state) =>
  ServiceApi.getAll(
    { orderby:  state().service.orderBy, orderdir:  state().service.orderDir, tags:  state().service.selectedTags },
    getContext(state)
  ).then((result) => {
    dispatch({ type: actionTypes.GET_ALL, items: result.items })
  });

export const create = (service) => (dispatch, state) =>
  ServiceApi.create(service, getContext(state))
    .then((result) => {
      dispatch({ type: actionTypes.CREATE, item: result.item });
      return result;
    });

export const update = (serviceId, service) => (dispatch, state) =>
  ServiceApi.update(serviceId, service, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE }));

export const patch = (serviceId, patch) => (dispatch, state) =>
  ServiceApi.patch(serviceId, patch, getContext(state))
    .then(() => dispatch({ type: actionTypes.PATCH }));

export const link = (serviceId, linkId, linkType) => (dispatch, state) =>
  ServiceApi.link(serviceId, linkId, linkType, getContext(state))
    .then(() => dispatch({ type: actionTypes.LINK_ONE, entityId: serviceId, linkId, linkType }));

export const unlink = (serviceId, linkId, linkType) => (dispatch, state) =>
  ServiceApi.unlink(serviceId, linkId, linkType, getContext(state))
    .then(() => dispatch({ type: actionTypes.UNLINK_ONE, entityId: serviceId, linkId, linkType }));

export const linkSync = (serviceId, linkType, linkIds) => (dispatch, state) =>
  ServiceApi.linkSync(serviceId, linkType, linkIds, getContext(state))
    .then(() => dispatch({ type: actionTypes.LINKSYNC }));

export const linkRole = (serviceroleId, linkType, linkId) => (dispatch, state) =>
  ServiceApi.linkRole(serviceroleId, linkType, linkId, getContext(state))
    .then(() => dispatch({ type: actionTypes.LINK_ROLE, serviceroleId, linkId, linkType }));

export const unlinkRole = (serviceroleId, linkType, linkId) => (dispatch, state) =>
  ServiceApi.unlinkRole(serviceroleId, linkType, linkId, getContext(state))
    .then(() => dispatch({ type: actionTypes.UNLINK_ROLE, serviceroleId, linkId, linkType }));

export const duplicate = (serviceId) => (dispatch, state) =>
  ServiceApi.duplicate(serviceId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DUPLICATE }));

export const archive = (serviceId) => (dispatch, state) =>
  ServiceApi.archive(serviceId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE }));

export const archivePrompt = (serviceId) => (dispatch, state) => {
  if (confirm("Are you sure?")) {
    return archive(serviceId)(dispatch,state)
      .then(() => {
        Toaster.show({
          message: `Successfully archived service.`,
          iconName: "tick",
          intent: Intent.SUCCESS
        });
      })
      .catch((e) => {
        Toaster.show({
          message: e.message,
          iconName: "warning-sign",
          intent: Intent.DANGER
        });
      })
  }
};

export const unArchive = (serviceId) => (dispatch, state) =>
  ServiceApi.unArchive(serviceId, getContext(state))
    .then(() => dispatch({ type: actionTypes.UNARCHIVE }));

export const hardDelete = (serviceId) => (dispatch, state) =>
  ServiceApi.hardDelete(serviceId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DELETE }));

export const getTags = () => (dispatch, state) =>
  ServiceApi.getTags(getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_TAGS, items: result.items }));

export const createTag = (name) => (dispatch, state) =>
  ServiceApi.createTag({ name }, getContext(state))
    .then((result) => dispatch({ type: actionTypes.CREATE_TAG, item: result.item }));

export const archiveTag = (tagId) => (dispatch, state) =>
  ServiceApi.archiveTag(tagId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE_TAG }));

export const updateTag = (tagId, name) => (dispatch, state) =>
  ServiceApi.updateTag(tagId, name, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE_TAG }));

export const setOrdering = ({orderBy, orderDir}) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ORDER,
    orderBy,
    orderDir
  });
  return Promise.resolve();
};

export const toggleSelectedTag = (tagId) => (dispatch, state) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAGS,
    selectedTags: state().service.selectedTags.includes(tagId)
      ? state().service.selectedTags.filter((eTagId) => eTagId !== tagId)
      : [...state().service.selectedTags, tagId]

  });
  return Promise.resolve();
};

export const clearSelectedTags = () => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAGS,
    selectedTags: []
  });

  return Promise.resolve();
};

export const getRoles = (serviceId) => (dispatch, state) =>
  ServiceApi.getRoles(serviceId, getContext(state))
    .then((result) => dispatch({
      type: actionTypes.GET_ROLES,
      roles: result.items.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
    }));