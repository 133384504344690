import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import _ from 'lodash'
import { Toaster } from '../../../components'
import { Dialog, Button, Intent } from "@blueprintjs/core";
import {cancelSubscription as cancelOrgSubscription, renewSubscription as renewOrgSubscription} from "../../../state/org/actions";
import {cancelSubscription, renewSubscription} from "../../../state/account/actions";

class CancelSubscriptionDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {processing: false, showErrors: false}
  }

  render() {

    const { onClose = _.noop, isOpen = false, undo = false } = this.props;
    const { processing } = this.state;

    return (
      <div className="CancelSubscriptionDialog">
        <Dialog iconName="inbox"
                isOpen={isOpen}
                onClose={() => onClose() }
                title="Cancel Subscription">
          <div className="bp3-dialog-body">
            {undo ? (
              <div className="bp3-callout bp3-intent-success">
                <h5>You are about to renew your subscription.</h5>
                <p>Renewing a subscription will schedule a payment from your card at the beginning of the next billing period.</p>
              </div>
            ) : (
              <div className="bp3-callout bp3-intent-warning">
                <h5>You are about to cancel your subscription.</h5>
                <p>After cancelling your subscription, you will be able to continue use until the end of your current usage period.  Once time expires, your account will be "suspended", and can be activated at any time.</p>
              </div>
            )}
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text="Nevermind..."
                      onClick={() => onClose()}
                      disabled={processing} />
              <Button
                intent={Intent.PRIMARY}
                disabled={processing}
                onClick={() => this.onCancelSubscription() }
                text={(undo ? (processing ? "Renewing" : "Renew" ) : (processing ? "Cancelling" : "Cancel")) + " Subscription"}
              />
            </div>
          </div>
        </Dialog>
      </div>
    )
  }

  onCancelSubscription() {

    const { undo, onClose, personal, cancelSubscription, cancelOrgSubscription, renewSubscription, renewOrgSubscription } = this.props;

    this.setState({processing: true});

    if (!undo) {
      (personal ? cancelSubscription() : cancelOrgSubscription())
        .then(() => {
          onClose();
          this.setState({processing: false});
        })
        .catch((e) => {
          this.setState({processing: false});
          Toaster.show({
            message: e.message,
            iconName: "warning-sign",
            intent: Intent.DANGER
          });
        });
    } else {
      (personal ? renewSubscription() : renewOrgSubscription())
        .then(() => {
          onClose();
          this.setState({processing: false});
        })
        .catch((e) => {
          this.setState({processing: false});
          Toaster.show({
            message: e.message,
            iconName: "warning-sign",
            intent: Intent.DANGER
          });
        });
    }

  }

}


export default connect(
  (state) => {
    return {

    }
  },
  (dispatch) => (
    bindActionCreators({
      cancelSubscription, cancelOrgSubscription, renewSubscription, renewOrgSubscription
    }, dispatch)
  )
)(CancelSubscriptionDialog);

