import React from 'react'
import {bindActionCreators} from 'redux';
import {Button, Intent, Popover, Menu, Position, Tooltip, AnchorButton} from '@blueprintjs/core';
import {connect} from 'react-redux';
import {Link} from 'gatsby'
import {DashboardLayoutA} from "../layouts";
import {ArchiveSermonButton, SermonEditForm} from './components'
import {getOne, getTags} from "../../state/sermon/actions";
import {getAll as getPersons} from "../../state/person/actions";
import * as Paths from '../../constants/Paths'
import {AddServiceToSermonDialog} from './components'
import moment from 'moment-timezone'
import {navigate} from 'gatsby'

class SermonEditPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }

  }

  componentDidMount() {
    Promise.all([
      this.props.getOne(this.props.id),
      this.props.getTags(),
      this.props.getPersons({noFilters: true})
    ]).then(() => {
      this.setState({loading: false})
    })
  }

  render() {

    const { sermon } = this.props;

    return (
      <DashboardLayoutA className="page-content-narrow SermonEditPage" loading={this.state.loading}>
        <div className="SermonEditPage">
          <div className="card-settings s5">
            <div className="inner-header grid-row v-align">
              <Tooltip content="Back to all Messages." position={Position.RIGHT}>
                <AnchorButton
                  icon="arrow-left"
                  outlined
                  minimal
                  href={Paths.SERMON_LIST}
                  onClick={(e) => {e.preventDefault(); navigate(Paths.SERMON_LIST) }}
                  className="heading-back"
                />
              </Tooltip>
              <div className="grid-item">
                <div className="heading"><span className="bp3-icon-standard bp3-icon-feed sr3"></span>{sermon.name}</div>
              </div>
              <ArchiveSermonButton className="bp3-button bp3-icon-archive" id={sermon.id}>Archive</ArchiveSermonButton>
            </div>
            <SermonEditForm />
          </div>
        </div>
        <div className="grid-row">
          <div className="card-list grid-item sr5">
            <div className="header">
              <div className="title grid-item">In services:</div>
              <AddServiceToSermonDialog trigger={(open) => (
                <Button text="Add to services" onClick={() => open()} />
              )} />
            </div>
            {(sermon.service && sermon.service.length > 0) ? (
              <div className="body">
                <table className="bp3-table">
                  <tbody>
                  {sermon.service.sort((a, b) => a.id < b.id ? 1 : -1).map((service, idx) => (
                    <tr key={idx}>
                      <td className="td-icon"><span className="bp3-icon-standard bp3-icon-bookmark"></span></td>
                      <td className="td-name"><Link
                        to={Paths.withId(Paths.SERVICE_EDIT, service.id)}>
                        {moment(service.date, "YYYY-MM-DD HH:mm:ss").format("LLLL")}
                      </Link></td>
                      <td className="td-name">{service.author}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="bp3-non-ideal-state">
                <div className="bp3-non-ideal-state-visual bp3-non-ideal-state-icon">
                  <span className="bp3-icon bp3-icon-feed"></span>
                </div>
                <h5 className="bp3-non-ideal-state-title">Nothing recorded.</h5>
              </div>
            )}
          </div>
          <div className="grid-item">
            <div className="grid-row">
              <div className="grid-item grid-row h-align">
                {this.isGoogleDriveLink(sermon.link) ?
                  (
                    <iframe width="100%" height="315" src={this.prepGoogleDriveIframe(sermon.link)}></iframe>
                  ) : (
                    <a href={sermon.link} target="_blank">{sermon.link}</a>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </DashboardLayoutA>
    )
  }

  isGoogleDriveLink(link) {
    return !!link
      && link.indexOf("drive.google.com") !== -1;
  }

  prepGoogleDriveIframe(link) {
    return link.replace("/view", "/preview")
  }

}

export default connect(
  (state) => {
    return {
      sermon: state.sermon.item || {id: 0, name: "", service: [], tags: []}
    }
  },
  (dispatch) => (
    bindActionCreators({
      getOne, getTags, getPersons
    }, dispatch)
  )
)(SermonEditPage);