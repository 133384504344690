import React from 'react'
import {AddResourceDialog} from './'

class AddResourceButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = { isDialogOpen: false }

  }

  render() {

    const { isDialogOpen } = this.state;
    const { className } = this.props;

    return <>
      <button className={className}
              key={0}
              onClick={() => this.setState({isDialogOpen: true})}>{this.props.children}</button>
      <AddResourceDialog isOpen={isDialogOpen}
                       key={1}
                       onClose={() => this.setState({isDialogOpen: false})}/>
    </>
  }

}

export default AddResourceButton;
