import * as WidgetApi from "../../api/WidgetApi";
import * as actionTypes from "./actionTypes";
import {getContext} from '../context/selectors';

export const getOne = (widgetId) => (dispatch, state) =>
  WidgetApi.getOne(widgetId, getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_ONE, item: result.item }));

export const getAll = () => (dispatch, state) =>
  WidgetApi.getAll(
    { orderby:  state().widget.orderBy, orderdir:  state().widget.orderDir, tags:  state().widget.selectedTags },
    getContext(state)
  ).then((result) => {
    dispatch({ type: actionTypes.GET_ALL, items: result.items })
  });

export const create = (widget) => (dispatch, state) =>
  WidgetApi.create(widget, getContext(state))
    .then((result) => {
      dispatch({ type: actionTypes.CREATE, item: result.item });
      return result;
    });

export const update = (widgetId, widget) => (dispatch, state) =>
  WidgetApi.update(widgetId, widget, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE }));

export const patch = (widgetId, patch) => (dispatch, state) =>
  WidgetApi.patch(widgetId, patch, getContext(state))
    .then(() => dispatch({ type: actionTypes.PATCH }));

export const duplicate = (widgetId) => (dispatch, state) =>
  WidgetApi.duplicate(widgetId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DUPLICATE }));

export const archive = (widgetId) => (dispatch, state) =>
  WidgetApi.archive(widgetId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE }));

export const unArchive = (widgetId) => (dispatch, state) =>
  WidgetApi.unArchive(widgetId, getContext(state))
    .then(() => dispatch({ type: actionTypes.UNARCHIVE }));

export const hardDelete = (widgetId) => (dispatch, state) =>
  WidgetApi.hardDelete(widgetId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DELETE }));

export const setOrdering = ({orderBy, orderDir}) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ORDER,
    orderBy,
    orderDir
  });
  return Promise.resolve();
};
