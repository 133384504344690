import { withFormsy } from 'formsy-react';
import React from 'react';

class Select extends React.Component {

  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    this.props.setValue(event.currentTarget.value);
  }

  render() {

    const {
      name,
      className,
      hasDefault = true,
      defaultOptionTitle = "Choose an item...",
      options = []
    } = this.props;

    return (
      <select id={name}
              dir="auto"
              onChange={this.changeValue}
              value={this.props.getValue() || ''}
              className={className}>
        {hasDefault && (
          <option defaultValue value="">{defaultOptionTitle}</option>
        )}
        {options.map((option, idx) => (
          <option key={idx} value={option.value}>{option.name}</option>
        ))}
      </select>
    )
  }
}

export default withFormsy(Select);