import React from 'react'
import {CancelSubscriptionDialog} from './'

class CancelSubscriptionButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = { isDialogOpen: false }

  }

  render() {

    const { isDialogOpen } = this.state;
    const { undo = false, personal = false } = this.props;

    return (
      <span className="CancelSubscriptionButton">
        <button className="bp3-button"
                onClick={() => this.setState({isDialogOpen: true})}>{undo ? "Renew" : "Cancel"} Subscription</button>
        <CancelSubscriptionDialog undo={undo}
                                  personal={personal}
                                  isOpen={isDialogOpen}
                                  onClose={() => this.setState({isDialogOpen: false})}/>
      </span>
    )
  }

}

export default CancelSubscriptionButton;
