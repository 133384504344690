import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {DashboardLayoutA} from "../layouts";
import {TeamList} from './components'
import {getContext} from '../../state/context/selectors'

class TeamListPage extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    const { context } = this.props;

    return (
      <DashboardLayoutA className="page-content">
        <div className="TeamListPage">
          <TeamList />
        </div>
      </DashboardLayoutA>
    )
  }

}

export default connect(
  (state) => {
    return {
      context: getContext(state)
    }
  },
  (dispatch) => (
    bindActionCreators({

    }, dispatch)
  )
)(TeamListPage);