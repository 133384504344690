import React from 'react'
import {Intent} from '@blueprintjs/core'
import {navigate} from 'gatsby'
import {Toaster} from "../../components/index";

class ArchiveEntityButton extends React.Component {

  render() {

    const { className } = this.props;

    return <>
      <button className={className}
              onClick={() => this.onDelete()}>{this.props.children}</button>
    </>
  }

  onDelete() {

    const { archive, id, navigateTo, entityName } = this.props;

    if (confirm("Are you sure?")) {
      archive(id)
        .then(() => {
          Toaster.show({
            message: `Successfully archived ${entityName}.`,
            iconName: "tick",
            intent: Intent.SUCCESS
          });

          if (navigateTo)
            navigate(navigateTo);
        })
        .catch((e) => {
          Toaster.show({
            message: e.message,
            iconName: "warning-sign",
            intent: Intent.DANGER
          });
        })
    }

  }

}

export default ArchiveEntityButton


