import * as ResourceApi from "../../api/ResourceApi";
import * as actionTypes from "./actionTypes";
import {getContext} from '../context/selectors';
import * as SermonApi from "../../api/SermonApi";

export const getOne = (resourceId) => (dispatch, state) =>
  ResourceApi.getOne(resourceId, getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_ONE, item: result.item }));

export const getAll = () => (dispatch, state) =>
  ResourceApi.getAll(
    { orderby:  state().resource.orderBy, orderdir:  state().resource.orderDir, tags:  state().resource.selectedTags },
    getContext(state)
  ).then((result) => {
    dispatch({ type: actionTypes.GET_ALL, items: result.items })
  });

export const create = (resource) => (dispatch, state) =>
  ResourceApi.create(resource, getContext(state))
    .then((result) => {
      dispatch({ type: actionTypes.CREATE, item: result.item });
      return result;
    });

export const update = (resourceId, resource) => (dispatch, state) =>
  ResourceApi.update(resourceId, resource, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE }));

export const patch = (resourceId, patch) => (dispatch, state) =>
  ResourceApi.patch(resourceId, patch, getContext(state))
    .then(() => dispatch({ type: actionTypes.PATCH }));

export const linkOne = (resourceId, linkId, linkType) => (dispatch, state) =>
  ResourceApi.linkOne(resourceId, linkId, linkType, getContext(state))
    .then(() => dispatch({ type: actionTypes.LINK_ONE, entityId: resourceId, linkId, linkType }));

export const unlinkOne = (resourceId, linkId, linkType) => (dispatch, state) =>
  ResourceApi.linkOne(resourceId, linkId, linkType, getContext(state))
    .then(() => dispatch({ type: actionTypes.UNLINK_ONE, entityId: resourceId, linkId, linkType }));

export const linkSync = (resourceId, linkIds) => (dispatch, state) =>
  ResourceApi.linkSync(resourceId, linkIds, getContext(state))
    .then(() => dispatch({ type: actionTypes.LINKSYNC }));

export const duplicate = (resourceId) => (dispatch, state) =>
  ResourceApi.duplicate(resourceId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DUPLICATE }));

export const archive = (resourceId) => (dispatch, state) =>
  ResourceApi.archive(resourceId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE }));

export const unArchive = (resourceId) => (dispatch, state) =>
  ResourceApi.unArchive(resourceId, getContext(state))
    .then(() => dispatch({ type: actionTypes.UNARCHIVE }));

export const hardDelete = (resourceId) => (dispatch, state) =>
  ResourceApi.hardDelete(resourceId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DELETE }));

export const getTags = () => (dispatch, state) =>
  ResourceApi.getTags(getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_TAGS, items: result.items }));

export const createTag = (name) => (dispatch, state) =>
  ResourceApi.createTag({ name }, getContext(state))
    .then((result) => dispatch({ type: actionTypes.CREATE_TAG, item: result.item }));

export const archiveTag = (tagId) => (dispatch, state) =>
  ResourceApi.archiveTag(tagId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE_TAG }));

export const updateTag = (tagId, name) => (dispatch, state) =>
  ResourceApi.updateTag(tagId, name, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE_TAG }));

export const setOrdering = ({orderBy, orderDir}) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ORDER,
    orderBy,
    orderDir
  });
  return Promise.resolve();
};

export const toggleSelectedTag = (tagId) => (dispatch, state) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAGS,
    selectedTags: state().resource.selectedTags.includes(tagId)
      ? state().resource.selectedTags.filter((eTagId) => eTagId !== tagId)
      : [...state().resource.selectedTags, tagId]

  });
  return Promise.resolve();
};

export const clearSelectedTags = () => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAGS,
    selectedTags: []
  });

  return Promise.resolve();
};