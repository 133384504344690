import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Navbar, Subnav} from '../'
import ClassNames from 'classnames'
import {BaseLayout} from './'
import FullHeightCalculator from "../../components/FullHeightCalculator";

class PreOrgLayout extends React.Component {

  render() {

    const { className = "", full = false } = this.props;

    var classNames = ClassNames("db-content", className);

    return (
      <BaseLayout className="PreOrgLayout">
        <Navbar preOrg/>
        {full ? (
          <FullHeightCalculator>
            <div className={classNames}>
              {this.props.children}
            </div>
          </FullHeightCalculator>
        ) : (
          <div className={classNames}>
            {this.props.children}
          </div>
        )}

      </BaseLayout>
    )
  }

}

export default connect(
  (state) => {
    return {
    }
  },
  (dispatch) => (
    bindActionCreators({
    }, dispatch)
  )
)(PreOrgLayout);

