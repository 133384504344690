import React from 'react'
import {DashboardLayoutA} from '../layouts'
import { EditOrgForm, CollaboratorsList, AddCollaboratorButton } from './components'

class UserSettingsPage extends React.Component {
  render() {
    return (
      <DashboardLayoutA className="OrgBillingPage">
        <div className="grid-restrain-narrow full-width">
          <div className="card-settings s5">
            <div className="inner-header">
              <div className="heading">Basic Info</div>
            </div>
            <div className="inner-body">
              <EditOrgForm />
            </div>
          </div>
          <div className="card-settings s5">
            <div className="inner-header grid-row v-align">
              <div className="grid-item">
                <div className="heading">Collaborators</div>
                <div className="description">New collaborators will be initially added with lowest permissions.</div>
              </div>
              <AddCollaboratorButton className="bp3-button bp3-icon-user" enter >Add collaborator</AddCollaboratorButton>
            </div>
            <div className="inner-body">
              <CollaboratorsList />
            </div>
          </div>
        </div>
      </DashboardLayoutA>
    )
  }

}

export default UserSettingsPage;
