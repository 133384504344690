import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {unlink, getRoles as getServiceRoles, linkRole, unlinkRole} from "../../../../state/service/actions";
import RoleRow from './RoleRow'

class Participants extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {

    const { serviceRoles, persons, teams, linkRole, unlinkRole, getServiceRoles, serviceId } = this.props;

    return (
      <div className="Participants">
        {serviceRoles.map((role, idx) => (
          <RoleRow
            key={idx}
            role={role}
            persons={persons}
            teams={teams}
            onRemove={this.removeRole}
            linkRole={linkRole}
            unlinkRole={unlinkRole}
            getServiceRoles={getServiceRoles}
            serviceId={serviceId}
          />
        ))}
      </div>
    )
  }

  removeRole = (role) => {
    const { serviceId, unlink, getServiceRoles } = this.props;
    unlink(serviceId, role.roleId, "role").then(() => getServiceRoles(serviceId));
  }

}

export default connect(
  (state, props) => {
    return {
      persons: state.person.items,
      teams: state.team.items
    }
  },
  (dispatch) => (
    bindActionCreators({
      unlink, getServiceRoles, linkRole, unlinkRole
    }, dispatch)
  )
)(Participants);
