import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Moment from 'moment-timezone'
import Formsy from 'formsy-react';
import {Select} from '../../../components/form/simple'
import deepClone from 'deep-clone'
import {Popover, Menu, Intent, Position} from '@blueprintjs/core'
import {update, getAll, archive, trigger} from '../../../state/notification/actions'
var deepEqual = require('fast-deep-equal');

class BirthdayNotification extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      pristine: deepClone(props.notification),
      nextNotification: Moment(props.notification.notify_at),
      showErrors: false,
      isProcessing: false,
      archiving: false,
      message: null,
      messageIsError: false,
      isPristine: true,
      stagedValues: this.getFormValuesFromNotification(props.notification),
      pristineValues: this.getFormValuesFromNotification(props.notification)
    }

  }

  render() {

    const { notification } = this.props;

    const {
      isPristine,
      message,
      messageIsError,
      isProcessing,
      showErrors,
      nextNotification,
      stagedValues
    } = this.state;

    const frequencyMap = {
      "10080": "every week",
      "20160": "every other week",
      "30240": "every 3 weeks",
      "40320": "every 4 weeks",
    }

    const windowOptions = [...Array.from(Array(90).keys())].map((day) => ({name: day + 1, value: day + 1}));
    const frequency = frequencyMap[stagedValues.interval_time_minutes];
    const time_window = ""

    return (
      <div className="BirthdayNotification">
        <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
                onChange={this.onFormChange }
                onInvalidSubmit={() => this.onInvalidSubmit() }
                ref="form">

          <div className="grid-row">
            <div className="grid-item">
              {frequency ? (
                <div className="sentence-body">
                  Send
                  <Select
                    name="window_days"
                    id="window_days"
                    hasDefault={false}
                    value={stagedValues.window_days}
                    options={
                      windowOptions
                    }
                  />
                  days of upcoming birthdays to my email
                  <Select
                    name="interval_time_minutes"
                    id="interval_time_minutes"
                    hasDefault={false}
                    value={stagedValues.interval_time_minutes}
                    options={
                      Object.keys(frequencyMap).map((key) => ({name: frequencyMap[key], value: key}))
                    }
                  />
                  on
                  <Select
                    name="interval_day"
                    id="interval_day"
                    hasDefault={false}
                    value={stagedValues.interval_day}
                    options={[
                      {name: "Monday", value: "Monday"},
                      {name: "Tuesday", value: "Tuesday"},
                      {name: "Wednesday", value: "Wednesday"},
                      {name: "Thursday", value: "Thursday"},
                      {name: "Friday", value: "Friday"},
                      {name: "Saturday", value: "Saturday"},
                      {name: "Sunday", value: "Sunday"}
                    ]}
                  />
                  {" "}at
                  <Select
                    name="interval_hour"
                    id="interval_hour"
                    hasDefault={false}
                    value={stagedValues.interval_hour}
                    options={[
                      {name: "12am", value: "0"},
                      {name: "1am", value: "1"},
                      {name: "2am", value: "2"},
                      {name: "3am", value: "3"},
                      {name: "4am", value: "4"},
                      {name: "5am", value: "5"},
                      {name: "6am", value: "6"},
                      {name: "7am", value: "7"},
                      {name: "8am", value: "8"},
                      {name: "9am", value: "9"},
                      {name: "10am", value: "10"},
                      {name: "11am", value: "11"},
                      {name: "12pm", value: "12"},
                      {name: "1pm", value: "13"},
                      {name: "2pm", value: "14"},
                      {name: "3pm", value: "15"},
                      {name: "4pm", value: "16"},
                      {name: "5pm", value: "17"},
                      {name: "6pm", value: "18"},
                      {name: "7pm", value: "19"},
                      {name: "8pm", value: "20"},
                      {name: "9pm", value: "21"},
                      {name: "10pm", value: "22"},
                      {name: "11pm", value: "23"},
                      {name: "12am", value: "0"}
                    ]}
                  />
                </div>
              ) : (
                <div>
                  Send {time_window.humanize()} of birthday updates to my email
                  every {Moment.duration(stagedValues.interval_minutes, 'minutes').humanize()} starting on {Moment(nextNotification).format("LLL")}</div>
              )}
              <div className="next-email">Next email scheduled for {nextNotification.format("LLLL")}</div>
            </div>
            <Popover
              position={Position.BOTTOM_RIGHT}
              className="three-dot-menu"
              content={(
                <Menu className={"bp3-minimal"} >
                  <Menu.Item
                    text={"Send Email Example"}
                    loading={this.state.triggering}
                    onClick={this.onTrigger}
                  />
                  <Menu.Divider />
                  <Menu.Item
                    text={"Archive"}
                    intent={Intent.DANGER}
                    loading={this.state.archiving}
                    onClick={() => this.archive()}
                  />
                </Menu>
              )}>
              <span className="bp3-icon-standard bp3-icon-more"></span>
            </Popover>
          </div>
          {!isPristine && (
            <div className="form-submit-group grid-row">
              <div className="grid-item grid-row v-align">
                {message && (
                  <div className={"message" + (messageIsError ? " error-message" : "")}>
                    {message}
                  </div>
                )}
              </div>
              <div>
                <button className={"bp3-button sr2" + (isPristine ? " bp3-disabled" : "")}
                        type="button"
                        disabled={isPristine}
                        onClick={() => this.onCancel() }>Discard Changes</button>
                {!isProcessing ? (
                  <button type="submit"
                          className={"bp3-button bp3-intent-primary" + (isPristine ? " bp3-disabled" : "")}>Save Changes</button>
                ) : (
                  <button type="submit"
                          className="bp3-button bp3-disabled ">Submitting...</button>
                )}
              </div>
            </div>
          )}
        </Formsy>
      </div>
    )
  }

  onTrigger = () => {

    const { trigger, notification } = this.props;

    this.setState({triggering: true})
    trigger(notification.type, notification.id).then(() => this.setState({triggering: false}))

  }

  archive = () => {
    const { notification, archive, getAll } = this.props;

    this.setState({archiving: true})

    archive(notification.type, notification.id).then(() => getAll())

  }

  onFormChange = (values, isChanged) => {
    this.setState({
      isPristine: deepEqual(this.state.pristineValues, values),
      nextNotification: this.getNextNotificationDate(values.interval_day, values.interval_hour)
    });
  }

  getNextNotificationDate = (interval_day, interval_hour) => {
    var nextNotification = Moment(`${interval_day} ${interval_hour}`, "dddd H");
    if (nextNotification.valueOf() < Date.now()) nextNotification = nextNotification.add(1, "weeks");
    return nextNotification;
  }

  getFormValuesFromNotification = (notification) => {

    const time_window = Moment.duration(notification.config.window_minutes, 'minutes');

    return {
      window_days: time_window.as('days') + "",
      interval_time_minutes: notification.config.interval_minutes + "",
      interval_day: Moment(notification.notify_at).format("dddd"),
      interval_hour: Moment(notification.notify_at).format("H")
    }

  }

  getNotificationFromFormValues = (model) => {

    var notifyAt = this.getNextNotificationDate(model.interval_day, model.interval_hour)

    return {
      "notify_at": notifyAt.valueOf(),
      "type": "BIRTHDAY",
      "config": {
        "interval_minutes": parseInt(model.interval_time_minutes),
        "window_minutes": parseInt(model.window_days) * 1440
      }
    }
  }

  onValidSubmit(model) {

    const { notification, getAll, update } = this.props;


    var updatedNotification = this.getNotificationFromFormValues(model);

    this.setState({
      isProcessing: true,
      message: null,
      messageIsError: null
    })

    update(notification.id, updatedNotification)
      .then(() => getAll())
      .then(() =>{
        this.setState({
          isProcessing: false,
          isPristine: true,
          stagedValues: deepClone(model),
          pristineValues: deepClone(model)
        })
      })
      .catch((e) => {
        this.setState({
          isProcessing: false,
          message: e.message,
          messageIsError: true
        });
      })

  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

  onCancel() {

    this.setState({
      isPristine: true,
      isProcessing: false,
      message: null,
      messageIsError: null,
      stagedValues: deepClone(this.state.pristineValues),
    });

    this.refs.form.reset(this.state.pristineValues)
  }

}

export default connect(
  (state) => {
    return {

    }
  },
  (dispatch) => (
    bindActionCreators({
      update, getAll, archive, trigger
    }, dispatch)
  )
)(BirthdayNotification);

