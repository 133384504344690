import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { Spinner } from "../../../../components";
import { ResendInviteButton, CancelInviteButton, RemoveCollaboratorButton } from '../'
import { getCollaborators, getInvites } from '../../../../state/org/actions'
import {getSelectedOrg} from "../../../../state/org/selectors";

class CollaboratorsList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false
    }
  }

  componentDidMount() {

    const { getCollaborators, getInvites } = this.props;

    this.setState({loading: true, error: false});

    Promise.all([getCollaborators(), getInvites()])
      .then(() => this.setState({loading: false}))
      .catch((e) => {this.setState({ error: e.message, loading: false })})
  }

  render() {

    const { collaborators = [], invites = [], selectedOrg, user } = this.props;
    const { loading } = this.state;

    return (
      <div className="CollaboratorsList">
        {loading ? (<Spinner className="bp3-small" />) : (
          <div className="grid-row">
            {(collaborators.length + invites.length > 0) ? (
              <div className="collab-list">
                {collaborators.sort((collab) => collab.owner ? -1 : 1).map((collab, idx) => (
                  <div className="collab-item" key={idx}>
                    {collab.owner ? (
                      <div className="grid-row v-align">
                        <span className="bp3-tag bp3-intent-primary sr2">owner</span>
                        <div>{collab.email}</div>
                        <div className="grid-item"></div>
                      </div>
                    ) : (
                      <div className="grid-row v-align">
                        <span className="bp3-tag bp3-intent-success sr2">member</span>
                        <div>{collab.email}</div>
                        <div className="grid-item"></div>
                        <div>
                          <RemoveCollaboratorButton userId={user.id}
                                                    org={selectedOrg}
                                                    collab={collab}
                                                    text={collab.user_id === user.id ? "Leave" : "Remove"}
                                                    className="bp3-button bp3-intent-secondary"/>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                {invites.map((invite, idx) => (
                  <div className="collab-item grid-row v-align" key={idx}>
                    <span className="bp3-tag bp3-intent-warning sr2">pending</span>
                    <div>{invite.email}</div>
                    <div className="grid-item"></div>
                    <div>
                      <ResendInviteButton org={selectedOrg} invite={invite} className="bp3-button sr2" />
                      <CancelInviteButton org={selectedOrg} invite={invite} className="bp3-button" />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="bp3-non-ideal-state">
                <div className="bp3-non-ideal-state-visual bp3-non-ideal-state-icon">
                  <span className="bp3-icon bp3-icon-people"></span>
                </div>
                <div className="bp3-non-ideal-state-description">
                  You don't have any collaborators yet.
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

}

export default connect(
  (state) => {
    return {
      collaborators: state.org.collaborators,
      invites: state.org.invites,
      selectedOrg: getSelectedOrg(state),
      user: state.account.user
    }
  },
  (dispatch) => (
    bindActionCreators({
      getCollaborators, getInvites
    }, dispatch)
  )
)(CollaboratorsList);

