import * as NotificationApi from "../../api/NotificationApi";
import * as actionTypes from "./actionTypes";
import {getContext} from '../context/selectors';

export const getAll = () => (dispatch, state) =>
  NotificationApi.getAll(
    { orderby:  state().notification.orderBy, orderdir:  state().notification.orderDir, tags:  state().notification.selectedTags },
    getContext(state)
  ).then((result) => {
    dispatch({ type: actionTypes.GET_ALL, items: result.items })
  });

export const create = (notification) => (dispatch, state) =>
  NotificationApi.create(notification.type, notification, getContext(state))
    .then((result) => {
      dispatch({ type: actionTypes.CREATE, item: result.item });
      return result;
    });

export const update = (notificationId, notification) => (dispatch, state) =>
  NotificationApi.update(notification.type, notificationId, notification, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE }));

export const archive = (notificationType, notificationId) => (dispatch, state) =>
  NotificationApi.archive(notificationType, notificationId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE }));

export const unArchive = (notificationType, notificationId) => (dispatch, state) =>
  NotificationApi.unArchive(notificationType, notificationId, getContext(state))
    .then(() => dispatch({ type: actionTypes.UNARCHIVE }));

export const trigger = (notificationType, notificationId) => (dispatch, state) =>
  NotificationApi.trigger(notificationType, notificationId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE }));
