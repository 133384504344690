import * as EntityapiApi from "../../api/EntityapiApi";
import * as actionTypes from "./actionTypes";
import {getContext} from '../context/selectors';


export const getAll = () => (dispatch, state) =>
  EntityapiApi.getAll(getContext(state)).then((result) => {
    dispatch({ type: actionTypes.GET_ALL, items: result.items })
  });

export const upsert = (entityapi) => (dispatch, state) =>
  EntityapiApi.upsert(entityapi, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPSERT }));
