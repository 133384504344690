import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {setUserSetting} from '../../state/account/actions';
import {getOrgs} from '../../state/org/actions';
import {PreOrgLayout} from "../layouts";
import {switchContext} from "../../state/context/actions";
import {getDefaultContext} from "../../state/account/selectors";

class OrgsOverviewPage extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getOrgs()
  }

  render() {

    const { orgs = [], defaultContextId, setUserSetting, userId, userEmail, switchContext } = this.props;

    const OrgItem = (org, idx) => (
      <div key={idx} className="org-item">
        <div className="header grid-row v-align">
          {userId === org.owner_id ? (
            <span className="bp3-tag bp3-intent-primary sr2">owner</span>
          ) : (
            <span className="bp3-tag bp3-intent-success sr2">collaborator</span>
          )}
          {defaultContextId === org.id && (
            <span className="bp3-tag bp3-intent-success sr2">default</span>
          )}
          <a onClick={(e) => { e.preventDefault(); switchContext("org", org.id); }}>{org.name}</a>
        </div>
        <div className="body">
          <div className="grid-row">
            <div className="grid-item">Basic stats...</div>
            {defaultContextId !== org.id ? (
              <button
                className="bp3-button sr2"
                onClick={() => setUserSetting("defaultContext", `org-${org.id}`)}>
                Make default
              </button>
            ) : (
              <button
                className="bp3-button bp3-disabled sr2">
                Make default
              </button>
            )}
          </div>
        </div>
      </div>
    );


    return (
      <PreOrgLayout className="OrgsOverviewPage page-content-narrow full-height grid-row v-align h-align" full>
        <div className="orgs-list">
          <div className="org-item">
            <div className="header grid-row v-align">
              <span className="bp3-tag bp3-intent-primary sr2">personal</span>
              {defaultContextId === 0 && (<span className="bp3-tag bp3-intent-success sr2">default</span>)}
              <a onClick={(e) => {e.preventDefault(); switchContext("user", "me");}}>{userEmail}</a>
            </div>
            <div className="body">
              <div className="grid-row">
                <div className="grid-item">Basic stats for the church...</div>
                {defaultContextId !== 0 ? (
                  <button
                    className="bp3-button sr2"
                    onClick={() => setUserSetting("defaultContext", `user-me`)}
                  >
                    Make default
                  </button>
                ) : (
                  <button
                    className="bp3-button bp3-disabled sr2">
                    Make default
                  </button>
                )}
              </div>
            </div>
          </div>
          {orgs.map((org, idx) => (
            OrgItem(org, idx)
          ))}
        </div>
      </PreOrgLayout>
    )
  }

}

export default connect(
  (state) => {
    return {
      orgs: state.org.orgs,
      userId: state.account.user.id,
      userEmail: state.account.user.email,
      defaultContextId: getDefaultContext(state).id
    }
  },
  (dispatch) => (
    bindActionCreators({
      getOrgs, setUserSetting, switchContext
    }, dispatch)
  )
)(OrgsOverviewPage);