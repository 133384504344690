import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {ArchiveRoleButton, RoleEditForm} from './'
import {getOne, getTags} from "../../../state/role/actions";

class RoleEdit extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }

  }

  render() {

    const {
      role,
      onArchive = () => {}
    } = this.props;

    return (
      <div className="RoleEditPage">
        <div className="card-settings">
          <div className="inner-header grid-row v-align">
            <div className="grid-item">
              <div className="heading"><span className="bp3-icon-standard bp3-icon-social-media sr3"></span>{role.name}</div>
            </div>
            <ArchiveRoleButton className="bp3-button bp3-icon-archive" id={role.id} onArchive={onArchive}>Archive</ArchiveRoleButton>
          </div>
          <RoleEditForm role={role} />
        </div>
      </div>
    )
  }

}

export default connect(
  (state) => {
    return {
      // role: state.role.item || { id: 0, name: "" }
    }
  },
  (dispatch) => (
    bindActionCreators({
    }, dispatch)
  )
)(RoleEdit);