import React from 'react'
import _ from 'lodash'
import { Dialog } from "@blueprintjs/core";
import { LoginForm } from '../../../components/session'

class CancelSubscriptionDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {processing: false, showErrors: false}
  }

  render() {

    const { onClose = _.noop, isOpen = false } = this.props;

    return (
      <div className="LoginDialog">
        <Dialog isOpen={isOpen}
                onClose={() => onClose() }
                title="Login">
          <div className="bp3-dialog-body">
            <LoginForm onSuccess={(user) => this.onLogin(user)}/>
          </div>
        </Dialog>
      </div>
    )
  }

  onLogin() {

  }
}

export default CancelSubscriptionDialog;
