import React from 'react'
import {Button, Popover, Menu, Position, Classes, Tag, Intent, Tooltip, PopoverInteractionKind} from '@blueprintjs/core'
import {getInformalFullname} from "../../../../state/person/util";
import {getEmailsFromRole} from "../../../../state/role/utils";
import {Toaster} from "../../../../components/index";
import {copyToClipboard} from '../../../../utils/utils'
import * as Paths from "../../../../constants/Paths";
class RoleRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      removing: false
    }
  }

  render() {

    const { role, onRemove, persons, teams } = this.props;

    return (
      <div className="role grid-row">
        <div className="role-name">{role.name}</div>
        <div className="grid-item grid-row v-align">
          <Popover
            position={Position.RIGHT}
            className="quick-menu"
            popoverClassName="Participants-assignee-list"
            popoverProps={{}}
            content={(
              <Menu>
                <li className="bp3-menu-header"><h6 className="bp3-heading">Teams</h6></li>
                {teams.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 1).map((team, idx) => (
                  <Menu.Item
                    key={idx}
                    icon="people"
                    text={team.name}
                    onClick={() => this.linkRole("team", team.id)}
                  />
                ))}
                <li className="bp3-menu-header"><h6 className="bp3-heading">Individuals</h6></li>
                {persons.sort((a, b) => a.first.toLowerCase() > b.first.toLowerCase() ? 1 : -1).map((person, idx) => (
                  <Menu.Item
                    key={idx}
                    icon="person"
                    text={getInformalFullname(person)}
                    onClick={() => this.linkRole("person", person.id)}
                  />
                ))}
              </Menu>
            )}>
            <Button minimal small icon="add" loading={this.state.loading}/>
          </Popover>
          <div className="grid-item">
            {role.teams.map((team, idx) => (
              <Popover
                popoverClassName="Participants-members-list"
                content={(
                  <div>
                    {team.members.map((member, idx) => (
                      <Tag minimal
                           className="sr1"
                           icon="person"
                           intent={Intent.SUCCESS}
                           onClick={() => window.open(Paths.withId(Paths.PERSON_EDIT, member.id))}
                           interactive={true}
                           key={idx}>
                        {getInformalFullname(member)}
                      </Tag>
                    ))}
                  </div>
                )}
                position={Position.TOP}
                interactionKind={PopoverInteractionKind.HOVER}
              >
                <Tag
                  minimal
                  className="sr1"
                  icon="people"
                  intent={Intent.PRIMARY}
                  key={idx}
                  onClick={() => window.open(Paths.withId(Paths.TEAM_EDIT, team.id))}
                  interactive={true}
                  onRemove={(e) => { e.stopPropagation(); this.unlinkRole("team", team.id)}}
                >{team.name}</Tag>
              </Popover>
            ))}
            {role.persons.map((person, idx) => (
              <Tag
                minimal
                className="sr1"
                icon="person"
                intent={Intent.SUCCESS}
                key={idx}
                onClick={() => window.open(Paths.withId(Paths.PERSON_EDIT, person.id))}
                interactive={true}
                onRemove={(e) => { e.stopPropagation(); this.unlinkRole("person", person.id)}}
              >
                {getInformalFullname(person)}
              </Tag>
            ))}
          </div>
        </div>
        <Popover
          position={Position.BOTTOM_RIGHT}
          className="three-dot-menu"
          content={(
            <Menu className={"bp3-minimal"} >
              <Menu.Item
                icon="clipboard"
                text={"Copy Emails to Clipboard"}
                onClick={() => this.copyToClipboard(role)}
              />
              <Menu.Divider />
              <li className={Classes.POPOVER_DISMISS}>
                <a className="bp3-menu-item service bp3-intent-danger"
                   onClick={() => this.onRemove(role)}>Remove Role</a>
              </li>
            </Menu>
          )}>
          <span className="bp3-icon-standard bp3-icon-more"></span>
        </Popover>
      </div>
    )
  }

  onRemove = (role) => {
    this.props.onRemove(role);
  };

  copyToClipboard = (role) => {

    var email = getEmailsFromRole(role);

    copyToClipboard(email.emails.join(", "));

    var message = `${email.emails.length} email(s) copied to your clipboard.`;

    if (email.missing.length > 0) {
      message = `${email.emails.length} email(s) of ${email.emails.length + email.missing.length} copied to your clipboard. Emails not found for: ${email.missing.map((p) => getInformalFullname(p)).join(", ")}`;
    } else {
      message = `${email.emails.length} email(s) copied to your clipboard.`;
    }

    Toaster.success(message)

  };

  linkRole = (linkType, linkId) => {
    const { role, linkRole, getServiceRoles, serviceId } = this.props;
    linkRole(role.id, linkType, linkId).then(() => getServiceRoles(serviceId));
  };

  unlinkRole = (linkType, linkId) => {
    const { role, unlinkRole, getServiceRoles, serviceId } = this.props;
    unlinkRole(role.id, linkType, linkId).then(() => getServiceRoles(serviceId));
  };

}

export default RoleRow;
