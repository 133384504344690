import * as RoleApi from "../../api/RoleApi";
import * as actionTypes from "./actionTypes";
import {getContext} from '../context/selectors';
import * as ServiceApi from "../../api/ServiceApi";
import * as PersonApi from "../../api/PersonApi";

export const getOne = (roleId) => (dispatch, state) =>
  RoleApi.getOne(roleId, getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_ONE, item: result.item }));

export const getAll = (options = {}) => (dispatch, state) =>
  (options.noFilters
      ? RoleApi.getAll({}, getContext(state))
      : RoleApi.getAll({ orderby:  state().role.orderBy, orderdir:  state().role.orderDir, tags:  state().role.selectedTags }, getContext(state))
  ).then((result) => {
    dispatch({ type: actionTypes.GET_ALL, items: result.items })
  });

export const create = (role) => (dispatch, state) =>
  RoleApi.create(role, getContext(state))
    .then((result) => {
      dispatch({ type: actionTypes.CREATE, item: result.item });
      return result;
    });

export const update = (roleId, role) => (dispatch, state) =>
  RoleApi.update(roleId, role, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE }));

export const patch = (roleId, patch) => (dispatch, state) =>
  RoleApi.patch(roleId, patch, getContext(state))
    .then(() => dispatch({ type: actionTypes.PATCH }));

export const linkOne = (roleId, linkId, linkType) => (dispatch, state) =>
  RoleApi.linkOne(roleId, linkId, linkType, getContext(state))
    .then(() => dispatch({ type: actionTypes.LINK_ONE, entityId: roleId, linkId, linkType }));

export const unlinkOne = (roleId, linkId, linkType) => (dispatch, state) =>
  RoleApi.linkOne(roleId, linkId, getContext(state), linkType)
    .then(() => dispatch({ type: actionTypes.UNLINK_ONE, entityId: roleId, linkId, linkType }));

export const linkSync = (serviceId, linkIds, linkType = "tag") => (dispatch, state) =>
  RoleApi.linkSync(serviceId, linkIds, getContext(state), linkType)
    .then(() => dispatch({ type: actionTypes.LINKSYNC }));

export const duplicate = (roleId) => (dispatch, state) =>
  RoleApi.duplicate(roleId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DUPLICATE }));

export const archive = (roleId) => (dispatch, state) =>
  RoleApi.archive(roleId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE }));

export const unArchive = (roleId) => (dispatch, state) =>
  RoleApi.unArchive(roleId, getContext(state))
    .then(() => dispatch({ type: actionTypes.UNARCHIVE }));

export const hardDelete = (roleId) => (dispatch, state) =>
  RoleApi.hardDelete(roleId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DELETE }));

export const getTags = () => (dispatch, state) =>
  RoleApi.getTags(getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_TAGS, items: result.items }));

export const createTag = (name) => (dispatch, state) =>
  RoleApi.createTag({ name }, getContext(state))
    .then((result) => dispatch({ type: actionTypes.CREATE_TAG, item: result.item }));

export const archiveTag = (tagId) => (dispatch, state) =>
  RoleApi.archiveTag(tagId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE_TAG }));

export const updateTag = (tagId, name) => (dispatch, state) =>
  RoleApi.updateTag(tagId, name, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE_TAG }));

export const setOrdering = ({orderBy, orderDir}) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ORDER,
    orderBy,
    orderDir
  });
  return Promise.resolve();
};

export const toggleSelectedTag = (tagId) => (dispatch, state) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAGS,
    selectedTags: state().role.selectedTags.includes(tagId)
      ? state().role.selectedTags.filter((eTagId) => eTagId !== tagId)
      : [...state().role.selectedTags, tagId]

  });
  return Promise.resolve();
};

export const clearSelectedTags = () => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAGS,
    selectedTags: []
  });

  return Promise.resolve();
};