import React from 'react'
import {bindActionCreators} from 'redux';
import {Button, Intent, Popover, Menu, Position, Tooltip, AnchorButton} from '@blueprintjs/core';
import {connect} from 'react-redux';
import {Link} from 'gatsby'
import {DashboardLayoutA} from "../layouts";
import {ArchiveTeamButton, TeamEditForm} from './components'
import {getOne, getTags} from "../../state/team/actions";
import * as Paths from '../../constants/Paths'
import {AddPersonToTeamDialog} from './components'
import {navigate} from 'gatsby'

class TeamEditPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }

  }

  componentDidMount() {
    Promise.all([
      this.props.getOne(this.props.id)
    ]).then(() => {
      this.setState({loading: false})
    })
  }

  render() {

    const { team } = this.props;

    return (
      <DashboardLayoutA className="page-content-narrow TeamEditPage" loading={this.state.loading}>
        <div className="TeamEditPage">
          <div className="card-settings s5">
            <div className="inner-header grid-row v-align">
              <Tooltip content="Back to Teams." position={Position.RIGHT}>
                <AnchorButton
                  icon="arrow-left"
                  outlined
                  minimal
                  href={Paths.TEAM_LIST}
                  onClick={(e) => {e.preventDefault(); navigate(Paths.TEAM_LIST) }}
                  className="heading-back"
                />
              </Tooltip>
              <div className="grid-item">
                <div className="heading"><span className="bp3-icon-standard bp3-icon-people sr3"></span>{team.name}</div>
              </div>
              <ArchiveTeamButton className="bp3-button bp3-icon-archive" id={team.id}>Archive</ArchiveTeamButton>
            </div>
            <TeamEditForm />
          </div>
        </div>
        <div className="grid-row">
          <div className="card-list grid-item">
            <div className="header">
              <div className="title grid-item">People in team:</div>
              <AddPersonToTeamDialog trigger={(open) => (
                <Button text="Add people" onClick={() => open()} />
              )} />
            </div>
            {(team.persons && team.persons.length > 0) ? (
              <div className="body">
                <table className="bp3-table">
                  <tbody>
                  {team.persons.sort((a, b) => a.id < b.id ? 1 : -1).map((person, idx) => (
                    <tr key={idx}>
                      <td className="td-icon"><span className="bp3-icon-standard bp3-icon-bookmark"></span></td>
                      <td className="td-name">
                        <Link
                          to={Paths.withId(Paths.PERSON_EDIT, person.id)}>
                          {person.first} {person.last}
                        </Link>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="bp3-non-ideal-state">
                <div className="bp3-non-ideal-state-visual bp3-non-ideal-state-icon">
                  <span className="bp3-icon bp3-icon-feed"></span>
                </div>
                <h5 className="bp3-non-ideal-state-title">Nothing recorded.</h5>
              </div>
            )}
          </div>
        </div>
      </DashboardLayoutA>
    )
  }

  isGoogleDriveLink(link) {
    return !!link
      && link.indexOf("drive.google.com") !== -1;
  }

  prepGoogleDriveIframe(link) {
    return link.replace("/view", "/preview")
  }

}

export default connect(
  (state) => {
    return {
      team: state.team.item || {id: 0, name: "", service: [], tags: []}
    }
  },
  (dispatch) => (
    bindActionCreators({
      getOne, getTags
    }, dispatch)
  )
)(TeamEditPage);