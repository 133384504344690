import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ClassNames from 'classnames';
import {Tooltip, Position} from '@blueprintjs/core'
import { logout } from '../state/account/actions'
import { getSelectedOrg } from '../state/org/selectors'
import { switchContext } from "../state/context/actions";
import { Link } from 'gatsby';
import * as Paths from '../constants/Paths';
import {getContext} from "../state/context/selectors";

class Subnav extends React.Component {

  shouldHighlight(subpath) {
    if (typeof window !== "undefined") {
      return window.location.pathname.includes(subpath)
    }
  }

  render() {

    const { className, context } = this.props;

    const contClasses = ClassNames("Subnav", className);

    return (
      <div className={contClasses}>
        <div className="grid-restrain grid-row v-align full-width">
          <Link
            className={ClassNames("bp3-button bp3-minimal bp3-icon-control", {"active" : Paths.pathMatches(Paths.CHURCH_OVERVIEW)})}
            to={Paths.CHURCH_OVERVIEW}>
            Overview
          </Link>
          <Link
            className={ClassNames("bp3-button bp3-minimal bp3-icon-bookmark", {"active" : this.shouldHighlight("/service")})}
                to={Paths.SERVICE_LIST}>
            Services
          </Link>
          <Link
            className={ClassNames("bp3-button bp3-minimal bp3-icon-music", {"active" : this.shouldHighlight("/song")})}
            to={Paths.SONG_LIST}>
            Songs
          </Link>
          <Link
            className={ClassNames("bp3-button bp3-minimal bp3-icon-person", {"active" : this.shouldHighlight("/people")})}
            to={Paths.PERSON_LIST}>
            People
          </Link>
          <Link
            className={ClassNames("bp3-button bp3-minimal bp3-icon-people", {"active" : this.shouldHighlight("/team")})}
            to={Paths.TEAM_LIST}>
            Teams
          </Link>
          <Link
            className={ClassNames("bp3-button bp3-minimal bp3-icon-feed", {"active" : this.shouldHighlight("/message")})}
            to={Paths.SERMON_LIST}>
            Messages
          </Link>
          <Link
            className={ClassNames("bp3-button bp3-minimal bp3-icon-dollar", {"active" : this.shouldHighlight("/tithe")})}
            to={Paths.TITHE_LIST}>
            Giving
          </Link>
          <Link
            className={ClassNames("bp3-button bp3-minimal bp3-icon-endorsed", {"active" : this.shouldHighlight("/resource")})}
            to={Paths.RESOURCE_LIST}>
            Resources
          </Link>
          <Link
            className={ClassNames("bp3-button bp3-minimal bp3-icon-inbox-update", {"active" : Paths.pathMatches(Paths.NOTIFICATION_OVERVIEW)})}
            to={Paths.NOTIFICATION_OVERVIEW}>
            Notifications
          </Link>
          <Link
            className={ClassNames("bp3-button bp3-minimal bp3-icon-cube", {"active" : Paths.pathMatches(Paths.SHARE_OVERVIEW)})}
            to={Paths.SHARE_OVERVIEW}>
            Widgets / API
          </Link>
        </div>
      </div>

    );
  }

}

export default connect(
  (state) => ({
    email: state.account.user.email,
    orgs: state.org.orgs,
    selectedOrg: getSelectedOrg(state),
    contextType: state.context.type,
    context: getContext(state)
  }),
  dispatch => (
    bindActionCreators({ logout, switchContext }, dispatch)
  )
)(Subnav);
