import React from 'react'
import { Popover, Button, Menu } from "@blueprintjs/core";

class QuickAddTag extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    }
  }

  render() {

    const { tags = [] } = this.props;

    return (
      <Popover
        content={
          <Menu>
            {tags.map((tag, idx) => (
              <Menu.Item
                icon="tag"
                text={tag.name}
                onClick={(e) => this.addTag(e, tag)}
              />
            ))}
          </Menu>
        }
        target={
          <Button minimal small icon="add" loading={this.state.loading}/>
        }
      />
    )
  }

  addTag = (e, tag) => {
    const { linkOne, entityId } = this.props;
    this.setState({loading: true});
    linkOne(entityId, tag.id).then(() => this.setState({loading: false}))
  }

}

export default QuickAddTag;
