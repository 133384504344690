import {statusErrorFilter, apiErrorFilter, headers} from './common/apicommons';

export function getAll({ownerType, ownerId}) {

  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/entityapi`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function upsert(entityapi, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/entityapi/upsert`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(entityapi),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}
