export function getInformalFullname(person) {
  if (person) {
    return (person.spoken_first || person.first) + (person.last ? " " + person.last : "");
  }
  return "Person Not Found."
}

// export function getPersonFullNameInformal(person) {
//
// }
//
// export function getPersonFirstNameInformal(person) {
//
// }
//
// export function getPersonFirstNameFormal(person) {
//
// }