import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import _ from 'lodash'
import { Dialog, Button, Intent } from "@blueprintjs/core";
import { updatePaymentMethod as updateOrgPaymentMethod } from '../../../state/org/actions'
import { updatePaymentMethod } from "../../../state/account/actions";
var stripe, Stripe, elements;

class NewCardDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {processing: false}
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      setTimeout(() => this.createCardElement(), 250)
    }

    if (this.props.isOpen && !nextProps.isOpen) {
      this.tearDownCardElement();
      this.setState({processing: false})
    }

  }

  componentDidMount() {

    if (typeof window.Stripe !== "undefined") Stripe = window.Stripe;
    else return;

    stripe = Stripe(process.env.GATSBY_STRIPE_KEY);
    elements = stripe.elements();
  }

  render() {

    const { onClose = _.noop, isOpen = false } = this.props;
    const { processing } = this.state;

    return (
      <div className="AddCollaboratorDialog">
        <Dialog iconName="inbox"
                isOpen={isOpen}
                onClose={() => onClose() }
                title="Add Credit Card" >
          <div className="bp3-dialog-body">
            <div ref="mount" className="stripe-newcard"></div>
            <div id="card-errors" className="stripe-newcard-error"></div>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button type="button" text="Cancel" disabled={processing} onClick={() => onClose()}/>
              <Button intent={Intent.PRIMARY} disabled={processing} text={processing ? "Submitting..." : "Submit"} onClick={() => this.onSubmit()}/>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }

  onSubmit() {

    const { onClose = _.noop, personal = false, updatePaymentMethod, updateOrgPaymentMethod } = this.props;

    this.setState({processing: true });

    stripe.createToken(this.card).then((result) => {
      var errorElement = document.getElementById('card-errors');

      if (result.error) {
        errorElement.textContent = result.error.message;
        this.setState({processing: false });
      } else {
        (personal ? updatePaymentMethod(result.token.id) : updateOrgPaymentMethod(result.token.id))
          .then(() => onClose())
          .catch((e) => {
            errorElement.textContent = e.message;
            this.setState({processing: false });
          })
      }
    });
  }

  createCardElement() {
    var style = {
      base: {
        background: "white",
        color: '#32325d',
        lineHeight: '18px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    if (!this.card) {
      this.card = elements.create('card', {style: style});
    }

    this.card.mount(this.refs.mount);

    this.card.addEventListener('change', function(event) {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

  }

  tearDownCardElement() {
    if (this.card) {
      this.card.unmount();
      this.card.destroy();
      this.card = null;
    }
  }

}

export default connect(
  (state) => {
    return {

    }
  },
  (dispatch) => (
    bindActionCreators({
      updateOrgPaymentMethod, updatePaymentMethod
    }, dispatch)
  )
)(NewCardDialog);

