import Formsy from 'formsy-react';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {TextInput, Select} from '../../../components/form';
import {patch} from '../../../state/org/actions'
import {getSelectedOrg} from '../../../state/org/selectors'
import {TIMEZONES} from "../../../constants/Timezones";

class EditOrgForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      isProcessing: false,
      message: null,
      messageIsError: false,
      isPristine: true
    };

  }

  render() {

    const {showErrors, isProcessing, message, messageIsError, isPristine} = this.state;
    const {org} = this.props;

    return (
      <Formsy onValidSubmit={(model) => this.onValidSubmit(model)}
              onChange={(values, isChanged) => this.checkForPristine(values, isChanged)}
              onInvalidSubmit={() => this.onInvalidSubmit()}
              ref="form">
        <div className="form-fields">
          <TextInput name="name"
                 type="text"
                 icon="build"
                 value={org.name}
                 label="Organization name:"
                 showErrors={showErrors}
                 required/>
          <TextInput name="short_name"
                     type="text"
                     icon="build"
                     value={org.short_name}
                     label="Organization name:"
                     showErrors={showErrors}
                     required />
          <Select name="timezone"
                  value={org.timezone}
                  label="Timezone:"
                  showErrors={showErrors}
                  options={TIMEZONES.map((timezone) => ({name: timezone, value: timezone}))}
                  required />
          <TextInput name="bg_img_url"
                     type="text"
                     icon="build"
                     value={org.bg_img_url}
                     label="Background url:"
                     showErrors={showErrors}
          />
          <TextInput name="bg_color_hex"
                     type="text"
                     icon="build"
                     value={org.bg_color_hex}
                     label="Background color:"
                     showErrors={showErrors}
          />
          <div className="grid-row v-align">
            <div className="grid-item">
              <TextInput name="logo_url"
                     type="text"
                     icon="link"
                     value={org.logo_url}
                     label="Logo url:"
                     showErrors={showErrors}/>
            </div>
            {org.logo_url && (
              <img src={org.logo_url} alt="" className="org-logo"/>
            )}
          </div>
        </div>
        <div className="form-submit-group grid-row">
          <div className="grid-item grid-row v-align">
            {message && (
              <div className={"message" + (messageIsError ? " error-message" : "")}>
                {message}
              </div>
            )}
          </div>
          <div>
            <button className={"bp3-button sr2" + (isPristine ? " bp3-disabled" : "")}
                    type="button"
                    onClick={() => this.onCancel()}>Cancel
            </button>
            {!isProcessing ? (
              <button type="submit"
                      className={"bp3-button bp3-intent-primary" + (isPristine ? " bp3-disabled" : "")}>Submit</button>
            ) : (
              <button type="submit"
                      className="bp3-button bp3-disabled ">Submitting...</button>
            )}
          </div>
        </div>
      </Formsy>
    );
  }

  checkForPristine(values, isChanged) {
    this.setState({isPristine: !isChanged});
  }

  onValidSubmit(model) {

    const { patch } = this.props;
    const {isPristine} = this.state;

    if (isPristine) return;

    this.setState({isProcessing: true, serverError: null});

    patch({
      name: model.name,
      short_name: model.short_name,
      timezone: model.timezone,
      logo_url: model.logo_url,
      bg_color_hex: model.bg_color_hex,
      bg_img_url: model.bg_img_url
    })
      .then(() => {
        this.setState({
          isProcessing: false,
          message: "Your changes were applied successfully.",
          messageIsError: false
        });
      })
      .catch((e) => {
        this.setState({
          isProcessing: false,
          message: e.message,
          messageIsError: true
        });
      });
  }

  onInvalidSubmit() {
    this.setState({showErrors: true});
  }

  onCancel() {

    const {isPristine} = this.state;
    if (isPristine) return;

    this.refs.form.reset();
    this.setState({
      message: "Your changes have been discarded.",
      messageIsError: false
    });
  }
}

export default connect(
  (state) => {
    return {
      org: getSelectedOrg(state)
    }
  },
  (dispatch) => (
    bindActionCreators({
      patch
    }, dispatch)
  )
)(EditOrgForm);

