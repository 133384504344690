import React from 'react'
import {bindActionCreators} from 'redux';
import {Button, Intent, Popover, Menu, Position, Tooltip, AnchorButton} from '@blueprintjs/core';
import {connect} from 'react-redux';
import {Link} from 'gatsby'
import {DashboardLayoutA} from "../layouts";
import {ArchiveResourceButton, ResourceEditForm} from './components'
import {getOne, getTags} from "../../state/resource/actions";
import {getAll as getAllPersons} from "../../state/person/actions";
import * as Paths from '../../constants/Paths'
import {navigate} from 'gatsby'

class ResourceEditPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }

  }

  componentDidMount() {
    Promise.all([
      this.props.getOne(this.props.id),
      this.props.getTags(),
      this.props.getAllPersons({noFilters: true})
    ]).then(() => {
      this.setState({loading: false})
    })
  }

  render() {

    const { resource } = this.props;

    return (
      <DashboardLayoutA className="page-content-narrow ResourceEditPage" loading={this.state.loading}>
        <div className="ResourceEditPage">
          <div className="card-settings s5">
            <div className="inner-header grid-row v-align">
              <Tooltip content="Back to all Resources." position={Position.RIGHT}>
                <AnchorButton
                  icon="arrow-left"
                  outlined
                  minimal
                  href={Paths.RESOURCE_LIST}
                  onClick={(e) => {e.preventDefault(); navigate(Paths.RESOURCE_LIST) }}
                  className="heading-back"
                />
              </Tooltip>
              <div className="grid-item">
                <div className="heading"><span className="bp3-icon-standard bp3-icon-endorsed sr3"></span>{resource.name}</div>
              </div>
              <ArchiveResourceButton className="bp3-button bp3-icon-archive" id={resource.id}>Archive</ArchiveResourceButton>
            </div>
            <ResourceEditForm />
          </div>
        </div>
      </DashboardLayoutA>
    )
  }

}


export default connect(
  (state) => {
    return {
      resource: state.resource.item
    }
  },
  (dispatch) => (
    bindActionCreators({
      getOne, getTags, getAllPersons
    }, dispatch)
  )
)(ResourceEditPage);