import {statusErrorFilter, apiErrorFilter, headers} from './common/apicommons';
import queryString from 'query-string';

export function getOne(roleId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role/${roleId}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function getAll({offset = 0, limit = 1000, archived = false, orderby="id", orderdir="desc", tags}, {ownerType, ownerId}) {

  var query = queryString.stringify({
    offset,
    limit,
    archived: archived ? 1 : 0,
    orderby,
    orderdir,
    tags
  }, {
    arrayFormat: "bracket"
  });

  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role?${query}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function create(role, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(role),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function update(roleId, role, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role/${roleId}`, {
    method: 'put',
    credentials: 'include',
    body: JSON.stringify(role),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function patch(roleId, patch, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role/${roleId}`, {
    method: 'PATCH',
    credentials: 'include',
    body: JSON.stringify(patch),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function linkOne(roleId, tagId, linkType = "tag", {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role/${roleId}/${linkType}/${tagId}`, {
    method: 'post',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function unlinkOne(roleId, tagId, linkType = "tag", {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role/${roleId}/${linkType}/${tagId}`, {
    method: 'delete',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function linkSync(roleId, linkIds, {ownerType, ownerId}, linkType = "tag") {

  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role/${roleId}/${linkType}/sync`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(linkType === "tag" ? {tags: linkIds} : {ids: linkIds}),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function duplicate(roleId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role/${roleId}/duplicate`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function archive(roleId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role/${roleId}/archive`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function unArchive(roleId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role/${roleId}/unarchive`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function hardDelete(roleId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role/${roleId}`, {
    method: 'delete',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function getTags({ ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role/tag`, {
    method: 'get',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function createTag(tag, { ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role/tag`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(tag),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function updateTag(tagId, name, { ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role/tag/${tagId}`, {
    method: 'put',
    credentials: 'include',
    body: JSON.stringify({name}),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function archiveTag(tagId, { ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role/tag/${tagId}/archive`, {
    method: 'post',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function tagLinkSync(roleId, tagIds, { ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/role/${roleId}/tag/sync`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify({tags: tagIds}),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}
