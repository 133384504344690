import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button, Icon, Menu, Popover, Position} from "@blueprintjs/core";
import {navigate, Link} from 'gatsby';
import {LoginButton} from './user/components'
import * as Paths from '../constants/Paths';
import ClassNames from 'classnames';
import {logout} from '../state/account/actions'
import {getSelectedOrg} from '../state/org/selectors'
import {switchContext} from "../state/context/actions";
import {getContext} from "../state/context/selectors";

class Navbar extends React.Component {

  render() {

    const {
      className,
      email,
      logout,
      orgs = [],
      switchContext,
      selectedOrg,
      contextType,
      preOrg,
      verseOfDay,
      context
    } = this.props;

    const contClasses = ClassNames("Navbar", className);

    const ProfileMenu = (
      <Menu className="">
        <Menu.Item text="Profile" iconname="cog" onClick={() => navigate(Paths.PROFILE)}/>
        <Menu.Item text="Logout" iconname="log-out" onClick={() => logout().then(() => navigate(Paths.HOME))}/>
      </Menu>
    );

    const OrgMenu = (
      <Menu className="">
        {contextType !== "user" && (
          <Menu.Item text={<span>Switch to <b>Personal Account</b></span>} onClick={() => switchContext("user", "me")}/>
        )}
        {orgs.length > 0 && (
          <>
            {orgs.filter((org) => org.id !== selectedOrg.id).map((org, idx) => (
              <Menu.Item key={idx} text={<span>Switch to <b>{org.short_name}</b></span>}
                         onClick={() => switchContext("org", org.id)}/>
            ))}
          </>
        )}
        {orgs.length > 0 && (
          <Menu.Item text="View All Churches" iconname="cog" onClick={() => navigate(Paths.ORG_OVERVIEW)}/>
        )}
        <li className="bp3-menu-header"></li>
        <Menu.Item text="Church Setup" icon="cog" onClick={() => navigate(Paths.ORG_INFO)}/>
        <Menu.Item text="Download Data" icon="import" onClick={() => window.location = `${process.env.GATSBY_API_URL}/${context.ownerType}/${context.ownerId}/export` }/>
        <Menu.Item text="New Church" icon="add" onClick={() => navigate(Paths.ORG_CREATE)}/>
      </Menu>
    );

    return (
      <div className={contClasses}>
        <div className="grid-restrain grid-row v-align full-width">
          {email ? (
            <div className="grid-row v-align">
              <Link to={Paths.ORG_INFO}>
                <div className="bp3-button bp3-minimal">
                  <Icon icon="cog"/>
                </div>
              </Link>
              <Popover
                content={OrgMenu}
                position={Position.BOTTOM_RIGHT}
              >
                <Button
                  minimal
                  icon="cloud"
                  rightIcon="caret-down"
                  text={preOrg ? "Select Org" : (contextType === "org" ? selectedOrg.short_name : "Personal Org")}
                />
              </Popover>
            </div>
          ) : (
            <LoginButton/>
          )}
          <div className="grid-item vod">{verseOfDay}</div>
          {email ? (
            <Popover content={ProfileMenu} position={Position.BOTTOM_RIGHT}>
              <Button minimal text={email} icon="user" rightIcon="caret-down"/>
            </Popover>
          ) : (
            <LoginButton/>
          )}
        </div>
      </div>

    );
  }

}

export default connect(
  (state) => ({
    email: state.account.user.email,
    orgs: state.org.orgs,
    selectedOrg: getSelectedOrg(state),
    contextType: state.context.type,
    verseOfDay: state.org.verseOfDay,
    context: getContext(state)
  }),
  dispatch => (
    bindActionCreators({logout, switchContext}, dispatch)
  )
)(Navbar);
