import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { Toaster } from '../../../../components/';
import { Intent, Spinner, Classes } from '@blueprintjs/core';
import {cancelInvite} from "../../../../state/org/actions";

class CancelInviteButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      processing: false
    }
  }

  render() {

    const { buttonProps } = this.props;
    const { processing } = this.state;

    return (
      <button {...buttonProps} onClick={() => this.onClick()}>
        {processing && (
          <Spinner className={Classes.SMALL} intent={Intent.PRIMARY} />
        )}
        Cancel invite
      </button>
    )
  }

  onClick() {

    const { invite, cancelInvite } = this.props;

    this.setState({processing: true});

    cancelInvite(invite.id)
      .then(() => {
        Toaster.show({
          message: "Successfully cancelled the invitation.",
          iconName: "tick",
          intent: Intent.SUCCESS
        });
      })
      .catch((e) => {
        this.setState({processing: false});
        Toaster.show({
          message: e.message,
          iconName: "warning-sign",
          intent: Intent.DANGER
        });
      })
  }

}

export default connect(
  (state) => {
    return {

    }
  },
  (dispatch) => (
    bindActionCreators({
      cancelInvite
    }, dispatch)
  )
)(CancelInviteButton);

