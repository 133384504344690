import {statusErrorFilter, apiErrorFilter, headers} from './common/apicommons';
import queryString from 'query-string';

export function getAll({offset = 0, limit = 1000, archived = false, orderby="id", orderdir="desc", tags}, {ownerType, ownerId}) {

  var query = queryString.stringify({
    offset,
    limit,
    archived: archived ? 1 : 0,
    orderby,
    orderdir,
    tags
  }, {
    arrayFormat: "bracket"
  });

  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/notification?${query}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function create(notificationType, notification, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/notification/${notificationType}`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(notification),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function update(notificationType, notificationId, notification, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/notification/${notificationType}/${notificationId}`, {
    method: 'put',
    credentials: 'include',
    body: JSON.stringify(notification),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function archive(notificationType, notificationId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/notification/${notificationType}/${notificationId}/archive`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function unArchive(notificationType, notificationId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/notification/${notificationType}/${notificationId}/unarchive`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function trigger(notificationType, notificationId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/notification/${notificationType}/${notificationId}/trigger`, {
    method: 'post',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}
