import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Navbar, Subnav} from '../'
import ClassNames from 'classnames'
import {BaseLayout} from './'
import {getSelectedOrg} from "../../state/org/selectors";

class DashboardLayoutA extends React.Component {

  render() {

    const { className = "", loading, selectedOrg } = this.props;

    var classNames = ClassNames(className);

    const bgStyles = {
      'background-image': `url(${selectedOrg.bg_img_url})`,
      'background-color': `${selectedOrg.bg_color_hex}`
    };

    return (
      <BaseLayout className="DashboardLayoutA" style={bgStyles}>
        <Navbar />
        <Subnav />
        {!loading && (
          <div className={classNames}>
            {this.props.children}
          </div>
        )}
      </BaseLayout>
    )
  }

}

export default connect(
  (state) => {
    return {
      selectedOrg: getSelectedOrg(state)
    }
  },
  (dispatch) => (
    bindActionCreators({
    }, dispatch)
  )
)(DashboardLayoutA);

