import React from 'react'
import { deleteCollaborator, getCollaborators } from "../../../../api/OrgApi";
import { Toaster } from '../../../../components/';
import { Intent, Spinner, Classes } from '@blueprintjs/core';
import { navigateTo } from 'gatsby-link'
import * as Paths from '../../../../constants/Paths'

class RemoveCollaboratorButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      processing: false
    }
  }

  render() {

    const { processing } = this.state;
    const { text = "Remove" } = this.props;


    return (
      <button className={this.props.className} onClick={() => this.onRemoveCollab()}>
        {processing && (
          <Spinner className={Classes.SMALL} intent={Intent.PRIMARY} />
        )}
        {text}
      </button>
    )
  }

  onRemoveCollab() {

    const { org, collab, userId } = this.props;

    this.setState({processing: true});

    deleteCollaborator(org.id, collab.id)
      .then(() => {
        if (userId === collab.user_id) {
          navigateTo(Paths.DB_USER_HOME);
        } else return getCollaborators(org.id).then((result) => {

            AppState.setCollaborators(result.collaborators.items);

            this.setState({processing: false});

            Toaster.show({
              message: "Successfully removed collaborator. Goodbye!",
              iconName: "tick",
              intent: Intent.SUCCESS
            });

          })

      })
      .catch((e) => {
        this.setState({processing: false});
        Toaster.show({
          message: e.message,
          iconName: "warning-sign",
          intent: Intent.DANGER
        });
      })
  }

}

export default RemoveCollaboratorButton;
