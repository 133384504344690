import {statusErrorFilter, apiErrorFilter, headers} from './common/apicommons';
import queryString from 'query-string';

export function getOne(teamId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team/${teamId}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function getAll({offset = 0, limit = 1000, archived = false, orderby="id", orderdir="desc", tags}, {ownerType, ownerId}) {

  var query = queryString.stringify({
    offset,
    limit,
    archived: archived ? 1 : 0,
    orderby,
    orderdir,
    tags
  }, {
    arrayFormat: "bracket"
  });

  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team?${query}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function create(team, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(team),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function update(teamId, team, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team/${teamId}`, {
    method: 'put',
    credentials: 'include',
    body: JSON.stringify(team),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function patch(teamId, patch, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team/${teamId}`, {
    method: 'PATCH',
    credentials: 'include',
    body: JSON.stringify(patch),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function linkOne(teamId, tagId, linkType = "tag", {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team/${teamId}/${linkType}/${tagId}`, {
    method: 'post',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function unlinkOne(teamId, tagId, linkType = "tag", {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team/${teamId}/${linkType}/${tagId}`, {
    method: 'delete',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function linkSync(teamId, linkIds, {ownerType, ownerId}, linkType = "tag") {

  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team/${teamId}/${linkType}/sync`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(linkType === "tag" ? {tags: linkIds} : {ids: linkIds}),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function duplicate(teamId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team/${teamId}/duplicate`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function archive(teamId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team/${teamId}/archive`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function unArchive(teamId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team/${teamId}/unarchive`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function hardDelete(teamId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team/${teamId}`, {
    method: 'delete',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function getTags({ ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team/tag`, {
    method: 'get',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function createTag(tag, { ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team/tag`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(tag),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function updateTag(tagId, name, { ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team/tag/${tagId}`, {
    method: 'put',
    credentials: 'include',
    body: JSON.stringify({name}),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function archiveTag(tagId, { ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team/tag/${tagId}/archive`, {
    method: 'post',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function tagLinkSync(teamId, tagIds, { ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/team/${teamId}/tag/sync`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify({tags: tagIds}),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}
