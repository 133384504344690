import React from 'react'
import _ from 'lodash'
import Formsy from 'formsy-react';
import {Textarea, TextInput, NumberInput, MultiSelect} from '../../../../components/form';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {patch, archive, getAll} from "../../../../state/widget/actions";
import MessageList from "./MessageList";
import deepClone from 'deep-clone'
import {Collapse, AnchorButton, Icon, Button, Intent, Tag, Popover, Menu, Position} from '@blueprintjs/core'

export function buildLookup(items, key) {
  var lookupMap = {};
  items.forEach((item) => {
    lookupMap[item[key]] = item;
  });
  return lookupMap
}

class MessageListConfigure extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      isProcessing: false,
      isPristine: true,
      message: null,
      messageIsError: false,
      stagedWidget: deepClone(props.widget),
      pristineWidget: deepClone(props.widget),
      refreshingPreview: false,
      expanded: false
    };

  }

  render() {

    const { widget, tags, tagsMap } = this.props;
    const { showErrors, reset, stagedWidget, isPristine, refreshingPreview, expanded, pristineWidget } = this.state;

    if (reset) {
      return null;
    }

    return (
      <div className="MessageListConfigure">
        <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
                onInvalidSubmit={() => this.onInvalidSubmit() }
                onChange={this.onChange}
                ref="form">
        <div className="title-expander grid-row v-align" onClick={() => this.setState({expanded: !expanded})}>
          <Icon icon={expanded ? "caret-up" : "caret-down"} className="sr3"/>
          <div className="grid-item widget-name">{widget.name}</div>
          {!isPristine && (
            <>
              <Button small className="sr2" onClick={(e) => {e.stopPropagation(); this.discardChanges()}}>Discard Changes</Button>
              <Button
                small
                loading={false}
                intent={Intent.PRIMARY}
                onClick={(e) => {e.stopPropagation();}}
                type="submit"
              >
                Publish Changes
              </Button>
            </>
          )}
          <div onClick={(e) => e.stopPropagation()} className="grid-row">
            <AnchorButton
              minimal icon="share"
              href={`/widget/?id=${widget.id}`}
              target="_blank"
            />
            <Popover
              position={Position.BOTTOM_RIGHT}
              className="three-dot-menu"
              content={(
                <Menu className={"bp3-minimal"} >
                  <Menu.Item
                    text={"Edit Widget"}
                    onClick={() => this.setState({expanded: true})}
                  />
                  <Menu.Divider />
                  <Menu.Item
                    text={"Archive"}
                    intent={Intent.DANGER}
                    onClick={() => this.onArchive()}
                  />
                </Menu>
              )}>
              <span className="bp3-icon-standard bp3-icon-more"></span>
            </Popover>
          </div>
        </div>
        <Collapse isOpen={expanded} keepChildrenMounted className="configure-section">
          <div className="grid-row widget-controls">
            <div className="grid-item sr3">
              <TextInput
                name="name"
                type="text"
                icon="build"
                value={pristineWidget.name}
                label="Widget Name:"
                showErrors={showErrors}
              />
              <TextInput
                name="title"
                type="text"
                icon="build"
                value={pristineWidget.config.title}
                label="Widget title:"
                showErrors={showErrors}
              />
              <TextInput
                name="dateFormat"
                type="text"
                icon="build"
                value={pristineWidget.config.dateFormat}
                label="Date format:"
                showErrors={showErrors}
              />
              <NumberInput
                name="limit"
                icon="build"
                value={pristineWidget.config.limit}
                label="Limit:"
                showErrors={showErrors}
              />
              <MultiSelect
                name="tags"
                value={pristineWidget.config.tags.map((tag) => ({
                  value: tag + "",
                  label: tagsMap[tag] ? tagsMap[tag].name : "Tag Deleted"
                }))}
                isMulti
                options={tags.map((tag) => ({
                  value: tag.id + "",
                  label: tag.name
                }))}
                label="Only tags:"
                showErrors={showErrors}
              />
            </div>
            <div className="grid-item">
              <Textarea
                name="css"
                type="text"
                icon="build"
                className="css-textarea"
                value={pristineWidget.config.css}
                label="Custom CSS (namespace with #widget):"
                showErrors={showErrors}
              />
            </div>
          </div>
          {!refreshingPreview && (
            <div className="preview">
              <div className="grid-row v-align preview-header">
                <div className="grid-item">Preview:</div>
                <div>
                  <Button text="Refresh Preview" icon="refresh" onClick={this.refreshPreview}/>
                </div>
              </div>
              <div className="preview-container">
                <MessageList widget={stagedWidget} />
              </div>
            </div>
          )}
        </Collapse>
        </Formsy>
      </div>
    )
  }

  onValidSubmit(model) {

    const { isPristine, pristineWidget } = this.state;
    const { widget, patch, getAll } = this.props;

    if (isPristine) return;

    this.setState({isProcessing: true, serverError: null});

    patch(widget.id, {
      name: model.name,
      config: {
        ...widget.config,
        title: model.title,
        limit: model.limit,
        css: model.css,
        dateFormat: model.dateFormat,
        tags: model.tags ? model.tags.map((tag) => parseInt(tag.value)) : []
      }
    }).then(() => getAll()).then((results) => {

      this.setState({
        isProcessing: false,
        message: "Your changes were applied successfully.",
        messageIsError: false,
        isPristine: true,
        pristineWidget: {
          ...pristineWidget,
          name: model.name,
          config: {
            ...pristineWidget.config,
            title: model.title,
            limit: model.limit,
            css: model.css,
            dateFormat: model.dateFormat,
            tags: model.tags ? model.tags.map((tag) => parseInt(tag.value)) : []
          }
        },
        reset: true
      }, () => this.setState({reset: false}));
    })
    .catch((e) => {
      this.setState({
        isProcessing: false,
        message: e.message,
        messageIsError: true
      });
    });

  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

  checkForPristine(values, isChanged) {
    this.setState({isPristine: !isChanged});
  }

  refreshPreview = () => {
    this.setState({refreshingPreview: true});
    setTimeout(() => this.setState({refreshingPreview: false}), 10)
  };

  onChange = (values, isChanged) => {

    this.checkForPristine(values, isChanged);

    this.setState({
      stagedWidget: { ...this.state.stagedWidget,
        config: { ...this.state.stagedWidget.config,
          ...values,
          tags: values.tags ? values.tags.map((tag) => parseInt(tag.value)) : []
        }}})
  };

  onArchive = () => {

    const { widget, archive, getAll } = this.props;

    if (confirm("Are you sure you want to archive this widget?")) {
      archive(widget.id).then(() => getAll())
    }

  }

  discardChanges() {

    const { isPristine } = this.state;
    if (isPristine) return;

    this.refs.form.reset();

    this.setState({
      message : "Your changes have been discarded.",
      messageIsError: false,
      isPristine: true
    });

  }

}

export default connect(
  (state) => {
    return {
      tags: state.sermon.tags,
      tagsMap: buildLookup(state.sermon.tags, "id")
    }
  },
  (dispatch) => (
    bindActionCreators({
      patch, archive, getAll
    }, dispatch)
  )
)(MessageListConfigure);
