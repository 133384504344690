import React from 'react'
import {bindActionCreators} from 'redux';
import {Button, Intent, Popover, Menu, Position, Tooltip, AnchorButton} from '@blueprintjs/core';
import {connect} from 'react-redux';
import {Link} from 'gatsby'
import {DashboardLayoutA} from "../layouts";
import {ArchiveTitheButton, TitheEditForm} from './components'
import {getOne, getTags} from "../../state/tithe/actions";
import * as Paths from '../../constants/Paths'
import {navigate} from 'gatsby'

class TitheEditPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }

  }

  componentDidMount() {
    Promise.all([
      this.props.getOne(this.props.id),
      this.props.getTags()
    ]).then(() => {
      this.setState({loading: false})
    })
  }

  render() {

    const { tithe } = this.props;

    return (
      <DashboardLayoutA className="page-content-narrow TitheEditPage" loading={this.state.loading}>
        <div className="TitheEditPage">
          <div className="card-settings s5">
            <div className="inner-header grid-row v-align">
              <Tooltip content="Back to all Giving." position={Position.RIGHT}>
                <AnchorButton
                  icon="arrow-left"
                  outlined
                  minimal
                  href={Paths.TITHE_LIST}
                  onClick={(e) => {e.preventDefault(); navigate(Paths.TITHE_LIST) }}
                  className="heading-back"
                />
              </Tooltip>
              <div className="grid-item">
                <div className="heading"><span className="bp3-icon-standard bp3-icon-dollar sr3"></span>{tithe.date} ${tithe.amount}</div>
              </div>
              <ArchiveTitheButton className="bp3-button bp3-icon-archive" id={tithe.id}>Archive</ArchiveTitheButton>
            </div>
            <TitheEditForm />
          </div>
        </div>
      </DashboardLayoutA>
    )
  }

}


export default connect(
  (state) => {
    return {
      tithe: state.tithe.item || {}
    }
  },
  (dispatch) => (
    bindActionCreators({
      getOne, getTags
    }, dispatch)
  )
)(TitheEditPage);