// export function getEmailsFromRoles(roles) {
//
//   var result = { emails : [], missing : [] };
//
//   roles.forEach((role) => {
//     var roleResult = getEmailsFromRole(role);
//     result.emails = result.emails.concat(roleResult.emails);
//     result.missing = result.missing.concat(roleResult.missing);
//   });
//
//   result.emails = result.emails.reduce((prev, next) => prev.includes(next) ? prev : [...prev, next], []);
//
//   return result;
//
// }

export function getEmailsFromRole(role) {
  var info = {
    emails: role.persons
      .map((person) => person.email)
      .concat(
        role.teams
          .reduce((prev, next) => [...prev, ...next.members], [])
          .map((member) => member.email)
      )
      .reduce((prev, next) => prev.includes(next) ? prev : [...prev, next], [])
      .filter((email) => !!email),
    missing: role.persons
      .concat(
        role.teams.reduce((prev, next) => [...prev, ...next.members], [])
      )
      .filter(p => !p.email)
  };
  return info;

}

export function getEmailsFromRoles(roles) {

  var personInfo = {
    withEmails : {},
    withoutEmails : {}
  };

  roles.forEach((role) => {
    var persons = getPersonsFromRole(role);

    persons.forEach((person) => {
      if (person.email) personInfo.withEmails[person.id] = person;
      else personInfo.withoutEmails[person.id] = person;
    })

  });

  return {
    emails: Object.keys(personInfo.withEmails).map((key) => personInfo.withEmails[key].email),
    missing: Object.keys(personInfo.withoutEmails).map((key) => personInfo.withoutEmails[key])
  };

}

export function getPersonsFromRole(role) {

  var persons = {};

  role.persons.forEach((person) => {
    persons[person.id] = person;
  });

  role.teams.forEach((team) => {
    team.members.forEach((member) => {
      persons[member.id] = member;
    })
  });

  return Object.keys(persons).map((key) => persons[key]);

};