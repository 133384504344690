import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button, Intent, Popover, Menu, Position, Tooltip, AnchorButton} from '@blueprintjs/core';
import {Link} from 'gatsby'
import {DashboardLayoutA} from "../layouts";
import {ServiceEditForm} from './containers'
import {AddSongToServiceDialog, AddSermonToServiceDialog, BuildSongEmailDialog, AddRoleToServicePopover, Participants, EmailDialog} from './components'
import {archive, getOne, getTags, getRoles as getServiceRoles, link, archivePrompt} from "../../state/service/actions";
import {getAll as getPersons} from "../../state/person/actions";
import {getAll as getTeams} from "../../state/team/actions";
import {getAll as getRoles} from "../../state/role/actions";
import * as Paths from '../../constants/Paths'
import {RoleDialog} from '../role/components'
import moment from 'moment-timezone'
import {Toaster} from "../../components/index";
import {getEmailsFromRoles} from "../../state/role/utils";
import {getInformalFullname} from "../../state/person/util";
import {copyToClipboard} from '../../utils/utils'
import {navigate} from 'gatsby'

class ServiceEditPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      roleDialog: false,
      emailDialog: false
    }

  }

  componentDidMount() {
    Promise.all([
      this.props.getOne(this.props.id),
      this.props.getServiceRoles(this.props.id),
      this.props.getRoles({noFilters: true}),
      this.props.getPersons({noFilters: true}),
      this.props.getTeams({noFilters: true})
    ]).then(() => this.setState({loading: false}))
  }

  render() {

    const { service, archivePrompt, roles = [], serviceRoles, link, getServiceRoles } = this.props;

    if (!service) return null;

    return (
      <DashboardLayoutA className="page-content-narrow ServiceEditPage" loading={this.state.loading}>
        <EmailDialog
          dialogProps={{isOpen: this.state.emailDialog, onClose: () => this.setState({emailDialog: false})}}
          service={service}
          serviceRoles={serviceRoles}
        />
        <div className="ServiceEditPage">
          <div className="card-settings s5">
            <div className="inner-header grid-row v-align">
              <Tooltip content="Back to all services." position={Position.RIGHT}>
                <AnchorButton
                  icon="arrow-left"
                  outlined
                  minimal
                  href={Paths.SERVICE_LIST}
                  onClick={(e) => {e.preventDefault(); navigate(Paths.SERVICE_LIST) }}
                  className="heading-back"
                />
              </Tooltip>
              <div className="grid-item">
                <div className="heading"><span className="bp3-icon-standard bp3-icon-bookmark sr3"></span>{moment(service.date).format("dddd, MMMM Do YYYY, h:mm a")}</div>
              </div>
              <Popover
                position={Position.BOTTOM_RIGHT}
                className="three-dot-menu"
                content={(
                  <Menu className={"bp3-minimal"} >
                    <Menu.Item
                      text={"Generate Service Email"}
                      onClick={() => this.setState({emailDialog: true})}
                    />
                    <Menu.Divider />
                    <Menu.Item
                      text={"Archive Service"}
                      intent={Intent.DANGER}
                      onClick={() => archivePrompt(service.id).then(() => navigate(Paths.SERVICE_LIST))}
                    />
                  </Menu>
                )}>
                <span className="bp3-icon-standard bp3-icon-more"></span>
              </Popover>
            </div>
            <ServiceEditForm />
          </div>
          <div className="card-list grid-item s5">
            <div className="header">
              <div className="title grid-item">Roles & Participants:</div>
              <RoleDialog
                dialogProps={{
                  isOpen: this.state.roleDialog,
                  onClose: () => this.setState({roleDialog: false})
                }} />
              <AddRoleToServicePopover
                serviceId={service.id}
                roles={roles}
                link={link}
                getServiceRoles={getServiceRoles}
              />
              <Popover
                position={Position.BOTTOM_RIGHT}
                className="three-dot-menu"
                content={(
                  <Menu className={"bp3-minimal"} >
                    <Menu.Item
                      icon="clipboard"
                      text={"Copy Emails to Clipboard"}
                      onClick={() => this.copyToClipboard(serviceRoles)}
                    />
                    <Menu.Item
                      icon="social-media"
                      text={"Manage Role Types"}
                      onClick={() => this.setState({roleDialog: true})}
                    />
                  </Menu>
                )}>
                <span className="bp3-icon-standard bp3-icon-more"></span>
              </Popover>
            </div>
            <Participants serviceRoles={serviceRoles} serviceId={service.id} />
          </div>
          <div className="grid-row s30">
            <div className="card-list grid-item sr5">
              <div className="header">
                <div className="title grid-item">Songs in service:</div>
                <BuildSongEmailDialog className="bp3-button bp3-icon-envelope" trigger={(open) => (
                  <Button className="sr2" text="Build Email" onClick={() => open()}/>
                )}/>
                <AddSongToServiceDialog trigger={(open) => (
                  <Button text="Edit" onClick={() => open()} intent={Intent.PRIMARY}/>
                )} />
              </div>
              {service.songs.length > 0 ? (
                <table className="bp3-table">
                  <tbody>
                  {service.songs.map((song, idx) => (
                    <tr key={idx}>
                      <td className="td-icon"><span className="bp3-icon-standard bp3-icon-music"></span></td>
                      <td className="td-name"><Link
                        to={Paths.withId(Paths.SONG_EDIT, song.id)}>{song.name}</Link>
                      </td>
                      <td className="td-name">{song.author}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              ) : (
                <div className="inner-body">
                  <div className="bp3-non-ideal-state">
                    <div className="bp3-non-ideal-state-visual bp3-non-ideal-state-icon">
                      <span className="bp3-icon bp3-icon-music"></span>
                    </div>
                    <h5 className="bp3-non-ideal-state-title">Nothing recorded.</h5>
                  </div>
                </div>
              )}
            </div>
            <div className="card-list grid-item">
              <div className="header">
                <div className="title grid-item">Messages in service:</div>
                <AddSermonToServiceDialog trigger={(open) => (
                  <Button text="Edit" onClick={() => open()} intent={Intent.PRIMARY}/>
                )} />
              </div>
              {service.sermons.length > 0 ? (
                <table className="bp3-table">
                  <tbody>
                  {service.sermons.map((sermon, idx) => (
                    <tr key={idx}>
                      <td className="td-icon"><span className="bp3-icon-standard bp3-icon-feed"></span></td>
                      <td className="td-name"><Link
                        to={Paths.withId(Paths.SERMON_EDIT, sermon.id)}>{sermon.name}</Link>
                      </td>
                      <td className="td-name">{sermon.speaker_id}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              ) : (
                <div className="inner-body">
                  <div className="bp3-non-ideal-state">
                    <div className="bp3-non-ideal-state-visual bp3-non-ideal-state-icon">
                      <span className="bp3-icon bp3-icon-feed"></span>
                    </div>
                    <h5 className="bp3-non-ideal-state-title">Nothing recorded.</h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </DashboardLayoutA>
    )
  }

  copyToClipboard = (roles) => {


    var email = getEmailsFromRoles(roles);

    copyToClipboard(email.emails.join(", "));

    var message = `${email.emails.length} email(s) copied to your clipboard.`;

    if (email.missing.length > 0) {
      message = `${email.emails.length} email(s) of ${email.emails.length + email.missing.length} copied to your clipboard. Emails not found for: ${email.missing.map((p) => getInformalFullname(p)).join(", ")}`;
    } else {
      message = `${email.emails.length} email(s) copied to your clipboard.`;
    }

    Toaster.success(message)

  };

}

ServiceEditPage.defaultProps = {
  service: {id: 0, name: ""}
}

export default connect(
  (state) => {
    return {
      service: state.service.item,
      serviceRoles: state.service.roles,
      roles: state.role.items
    }
  },
  (dispatch) => (
    bindActionCreators({
      getOne, getTags, archive, getRoles, getServiceRoles, link, getPersons, getTeams, archivePrompt
    }, dispatch)
  )
)(ServiceEditPage);