import React from 'react'
import {API_FIELDS} from '../../../../state/entityapi/constants'
import {Tag, Intent, Button, Switch, Collapse, Icon, AnchorButton} from '@blueprintjs/core'
import deepClone from 'deep-clone'
import ClassName from 'classnames'
import {getInformalFullname} from "../../../../state/person/util";

class ApiConfigureBlock extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      enabled: props.intialEntityApi.enabled,
      allowedTags: deepClone(props.intialEntityApi.tags),
      allowedFields: deepClone(props.intialEntityApi.fields),
      isPristine: true,
      saving: false
    }

  }

  render() {

    const { entity, tags, expanded, onToggleExpand, context, title } = this.props;
    const { enabled, allowedTags, allowedFields, isPristine, saving } = this.state;

    return (
      <div className="ApiConfigureBlock">
        <div className="title-expander grid-row v-align" onClick={() => onToggleExpand()}>
          <Icon icon={expanded ? "caret-up" : "caret-down"} className="sr3"/>
          <div className="grid-item sharing-title">
            {(title || entity).charAt(0).toUpperCase() + (title || entity).slice(1)} Data
          </div>
          {!isPristine && (
            <>
              <Button small className="sr2" onClick={(e) => {e.stopPropagation(); this.discardChanges()}}>Discard Changes</Button>
              <Button
                small
                loading={saving}
                intent={Intent.PRIMARY}
                onClick={(e) => {e.stopPropagation(); this.saveChanges()}}>
                Save Changes
              </Button>
            </>
          )}
          <AnchorButton onClick={(e) => e.stopPropagation()} minimal icon="share" href={`${process.env.GATSBY_API_URL}/public/${context.ownerType}/${context.ownerId}/${entity}`} target="_blank"/>
        </div>
        <Collapse isOpen={expanded}>
          <div className="rules-body">
            <Switch checked={enabled} label={"Enable Sharing"} onChange={() => this.setState({isPristine: false, enabled: !enabled})}/>
            <div className={ClassName("tags-and-fields", {"active": enabled})}>
              <div className="rule-section-header s2">Public Tags:</div>
              {tags.map((tag, idx) => (
                <div className="public-tag" key={idx}>
                  <Switch checked={allowedTags.includes(tag.id)} label={tag.name} onChange={() => this.onTagChange(tag)}/>
                </div>
              ))}
              <div className="rule-section-header s2">Public Fields:</div>
              {API_FIELDS[entity].map((field, idx) => (
                <div className="public-field" key={idx}>
                  <Switch checked={allowedFields.includes(field)} label={field} onChange={() => this.onFieldChange(field)}/>
                </div>
              ))}
            </div>
          </div>
        </Collapse>
      </div>
    )

  }

  discardChanges = () => {

    const { intialEntityApi } = this.props;

    this.setState({
      enabled: intialEntityApi.enabled,
      allowedTags: deepClone(intialEntityApi.tags),
      allowedFields: deepClone(intialEntityApi.fields),
      isPristine: true
    })
  }

  saveChanges = () => {

    this.setState({saving: true});

    this.props.upsertEntityApi({
      collection: this.props.entity,
      rules: {
        version: "1",
        enabled: this.state.enabled,
        tags: this.state.allowedTags,
        fields: this.state.allowedFields
      }
    }).then(() => {
      this.setState({
        saving: false,
        isPristine: true
      })
    }).catch(() => {
      this.setState({
        saving: false
      })
    })

  };

  onTagChange = (tag) => {

    const { allowedTags, enabled } = this.state;

    if (!enabled) return;

    if (!allowedTags.includes(tag.id)) {
      this.setState({allowedTags: [...allowedTags, tag.id], isPristine: false})
    } else {


      var idxOfTag = this.state.allowedTags.indexOf(tag.id);
      allowedTags.splice(idxOfTag, 1);

      this.setState({allowedTags: [...allowedTags], isPristine: false})

    }

  }

  onFieldChange = (field) => {

    const { allowedFields, enabled } = this.state;

    if (!enabled) return;

    if (!allowedFields.includes(field)) {
      this.setState({allowedFields: [...allowedFields, field], isPristine: false})
    } else {


      var idxOfField = this.state.allowedFields.indexOf(field);
      allowedFields.splice(idxOfField, 1);

      this.setState({allowedFields: [...allowedFields], isPristine: false})

    }

  }

}

export default ApiConfigureBlock;