import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Formsy from 'formsy-react';
import {TextInput, Textarea} from '../../../components/form';
import {getOne, update, patch, linkSync, createTag} from "../../../state/team/actions";
import {getAll as getAllPersons} from "../../../state/person/actions";

class TeamEditForm extends React.Component {

  componentDidMount() {
    if (this.props.id) {
      this.props.getOne(this.props.id);
      this.props.getAllPersons({noFilters: true});
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      isProcessing: false,
      message: null,
      messageIsError: false,
      isPristine: true
    };

  }

  render() {

    const { showErrors, isProcessing, message, messageIsError, isPristine, reset } = this.state;
    const { team } = this.props;

    return (
      <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
              onChange={(values, isChanged) => this.checkForPristine(values, isChanged) }
              onInvalidSubmit={() => this.onInvalidSubmit() }
              ref="form">
        <div className="form-fields">
          <TextInput
            name="name"
            type="text"
            icon="build"
            value={team.name}
            label="Message name:"
            showErrors={showErrors}
            required
          />
          <Textarea name="notes"
                    value={team.notes}
                    label="Internal Notes:"
                    showErrors={showErrors}
          />
        </div>
        <div className="form-submit-group grid-row">
          <div className="grid-item grid-row v-align">
            {message && (
              <div className={"message" + (messageIsError ? " error-message" : "")}>
                {message}
              </div>
            )}
          </div>
          <div>
            <button className={"bp3-button sr2" + (isPristine ? " bp3-disabled" : "")}
                    type="button"
                    onClick={() => this.onCancel() }>Cancel</button>
            {!isProcessing ? (
              <button type="submit"
                      className={"bp3-button bp3-intent-primary" + (isPristine ? " bp3-disabled" : "")}>Submit</button>
            ) : (
              <button type="submit"
                      className="bp3-button bp3-disabled ">Submitting...</button>
            )}
          </div>
        </div>
      </Formsy>
    );
  }

  onValidSubmit(model) {

    const { isPristine } = this.state;
    const { team, patch, getOne } = this.props;
    if (isPristine) return;

    this.setState({isProcessing: true, serverError: null});

    patch(team.id, {
      name: model.name,
      notes: model.notes
    })
    .then(() => getOne(team.id))
    .then(() => {
      this.setState({
        isProcessing: false,
        message: "Your changes were applied successfully.",
        messageIsError: false,
        reset: true
      }, () => this.setState({reset: false}));

    })
    .catch((e) => {
      this.setState({
        isProcessing: false,
        message: e.message,
        messageIsError: true
      });
    });
  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

  checkForPristine(values, isChanged) {
    this.setState({isPristine: !isChanged});
  }

  onCancel() {

    const { isPristine } = this.state;
    if (isPristine) return;

    this.refs.form.reset();
    this.setState({
      message : "Your changes have been discarded.",
      messageIsError: false
    });
  }

}

export default connect(
  (state, props) => {
    return {
      team: props.team ? props.team : state.team.item,
      persons: state.person.items
    }
  },
  (dispatch) => (
    bindActionCreators({
      update, createTag, getOne, patch, linkSync, getAllPersons
    }, dispatch)
  )
)(TeamEditForm);
