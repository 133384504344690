import React from 'react'
import { Router } from "@reach/router"
import * as Paths from '../constants/Paths'

import {OrgsOverviewPage, OrgInfoPage, OrgCreatePage} from './org'
import {UserInfoPage, UserBillingPage} from './user'
import {SongListPage, SongEditPage} from './song'
import {ServiceListPage, ServiceEditPage} from './service'
import {PersonListPage, PersonEditPage} from './person'
import {SermonListPage, SermonEditPage} from './sermon'
import {TitheListPage, TitheEditPage} from './tithe'
import {ResourceListPage, ResourceEditPage} from './resource'
import {TeamListPage, TeamEditPage} from './team'
import ChurchOverview from './overview/ChurchOverview'
import ShareOverview from "./share/ShareOverview";
import NotificationOverview from "./notification/Overview";

class DashboardIndex extends React.Component {
  render() {

    if (typeof window === "undefined") return null;

    return (
      <Router>
        <OrgsOverviewPage path={Paths.ORG_OVERVIEW} />
        <OrgInfoPage path={Paths.ORG_INFO} />
        <OrgCreatePage path={Paths.ORG_CREATE} />
        <UserInfoPage path={Paths.PROFILE}/>
        <UserBillingPage path={Paths.BILLING}/>
        <ChurchOverview path={Paths.CHURCH_OVERVIEW}/>
        <ShareOverview path={Paths.SHARE_OVERVIEW}/>
        <NotificationOverview path={Paths.NOTIFICATION_OVERVIEW}/>
        <SongListPage path={Paths.SONG_LIST}/>
        <SongEditPage path={Paths.SONG_EDIT}/>
        <ServiceListPage path={Paths.SERVICE_LIST}/>
        <ServiceEditPage path={Paths.SERVICE_EDIT}/>
        <PersonListPage path={Paths.PERSON_LIST}/>
        <PersonEditPage path={Paths.PERSON_EDIT}/>
        <SermonListPage path={Paths.SERMON_LIST}/>
        <SermonEditPage path={Paths.SERMON_EDIT}/>
        <TitheListPage path={Paths.TITHE_LIST}/>
        <TitheEditPage path={Paths.TITHE_EDIT}/>
        <ResourceListPage path={Paths.RESOURCE_LIST}/>
        <ResourceEditPage path={Paths.RESOURCE_EDIT}/>
        <TeamListPage path={Paths.TEAM_LIST}/>
        <TeamEditPage path={Paths.TEAM_EDIT}/>
      </Router>
    );
  }
}

export default DashboardIndex;