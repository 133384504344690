import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ClassNames from 'classnames';
import {loadOnce} from '../../managers/layout'
import {getOrgs, getVerseOfDay} from '../../state/org/actions'
import Spinner from "../../components/Spinner";
import '../../scss/entry.scss'
import {SessionProvider} from "../../components";

class BaseLayout extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    loadOnce(this.props.getOrgs, "getOrgs");
    loadOnce(this.props.getVerseOfDay, "getVerseOfDay");
  }

  render() {

    const { switchingContexts, style = {} } = this.props;
    const classNames = ClassNames(this.props.className);

    return (
      <SessionProvider >
        {switchingContexts ? (
          <Spinner />
        ) : (
          <div className={classNames} style={style}>
            {this.props.children}
          </div>
        )}
      </SessionProvider>
    )
  }

}

export default connect(
  (state) => {
    return {
      switchingContexts: state.context.refreshing
    }
  },
  (dispatch) => (
    bindActionCreators({
      getOrgs, getVerseOfDay
    }, dispatch)
  )
)(BaseLayout);
