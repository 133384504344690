import React from 'react'
import { resendInvite } from "../../../../api";
import { Toaster } from '../../../../components';
import { Intent, Spinner, Classes } from '@blueprintjs/core';

class ResendInviteButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      processing: false
    }
  }

  render() {

    const { processing } = this.state;

    return (
      <button {...this.props} onClick={() => this.onResendInvite()}>
        {processing && (
          <Spinner className={Classes.SMALL} intent={Intent.PRIMARY} />
        )}
        Resend invite
      </button>
    )
  }

  onResendInvite() {
    const { org, invite } = this.props;

    this.setState({processing: true});

    resendInvite(org.id, invite.id)
      .then(() => {

      this.setState({processing: false});

        Toaster.show({
          message: "Successfully resent the invitation.",
          iconName: "tick",
          intent: Intent.SUCCESS
        });

      })
      .catch((e) => {
        this.setState({processing: false});
        Toaster.show({
          message: e.message,
          iconName: "warning-sign",
          intent: Intent.DANGER
        });
      })
  }

}

export default ResendInviteButton;
