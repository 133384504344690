import { withFormsy } from 'formsy-react';
import React from 'react';
import {FormGroup, InputGroup} from "@blueprintjs/core";

class Input extends React.Component {

  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    this.props.setValue(event.currentTarget.value);
  }

  render() {
    const {
      type = "text",
      placeholder = "",
      name,
      id,
      autofocus = false,
    } = this.props;

    return (
        <input id={id || name}
               name={name}
               placeholder={placeholder}
               autoFocus={autofocus}
               onChange={this.changeValue}
               type={type}
               value={this.props.getValue() || ''}
        />
    );
  }
}

export default withFormsy(Input);