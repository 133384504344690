import * as SermonApi from "../../api/SermonApi";
import * as actionTypes from "./actionTypes";
import {getContext} from '../context/selectors';
import * as ServiceApi from "../../api/ServiceApi";

export const getOne = (sermonId) => (dispatch, state) =>
  SermonApi.getOne(sermonId, getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_ONE, item: result.item }));

export const getAll = () => (dispatch, state) =>
  SermonApi.getAll(
    { orderby:  state().sermon.orderBy, orderdir:  state().sermon.orderDir, tags:  state().sermon.selectedTags },
    getContext(state)
  ).then((result) => {
    dispatch({ type: actionTypes.GET_ALL, items: result.items })
  });

export const create = (sermon) => (dispatch, state) =>
  SermonApi.create(sermon, getContext(state))
    .then((result) => {
      dispatch({ type: actionTypes.CREATE, item: result.item });
      return result;
    });

export const update = (sermonId, sermon) => (dispatch, state) =>
  SermonApi.update(sermonId, sermon, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE }));

export const patch = (sermonId, patch) => (dispatch, state) =>
  SermonApi.patch(sermonId, patch, getContext(state))
    .then(() => dispatch({ type: actionTypes.PATCH }));

export const linkOne = (sermonId, linkId, linkType) => (dispatch, state) =>
  SermonApi.linkOne(sermonId, linkId, linkType, getContext(state))
    .then(() => dispatch({ type: actionTypes.LINK_ONE, entityId: sermonId, linkId, linkType }));

export const unlinkOne = (sermonId, linkId, linkType) => (dispatch, state) =>
  SermonApi.linkOne(sermonId, linkId, getContext(state), linkType)
    .then(() => dispatch({ type: actionTypes.UNLINK_ONE, entityId: sermonId, linkId, linkType }));

export const linkSync = (serviceId, linkIds, linkType = "tag") => (dispatch, state) =>
  SermonApi.linkSync(serviceId, linkIds, getContext(state), linkType)
    .then(() => dispatch({ type: actionTypes.LINKSYNC }));

export const duplicate = (sermonId) => (dispatch, state) =>
  SermonApi.duplicate(sermonId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DUPLICATE }));

export const archive = (sermonId) => (dispatch, state) =>
  SermonApi.archive(sermonId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE }));

export const unArchive = (sermonId) => (dispatch, state) =>
  SermonApi.unArchive(sermonId, getContext(state))
    .then(() => dispatch({ type: actionTypes.UNARCHIVE }));

export const hardDelete = (sermonId) => (dispatch, state) =>
  SermonApi.hardDelete(sermonId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DELETE }));

export const getTags = () => (dispatch, state) =>
  SermonApi.getTags(getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_TAGS, items: result.items }));

export const createTag = (name) => (dispatch, state) =>
  SermonApi.createTag({ name }, getContext(state))
    .then((result) => dispatch({ type: actionTypes.CREATE_TAG, item: result.item }));

export const archiveTag = (tagId) => (dispatch, state) =>
  SermonApi.archiveTag(tagId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE_TAG }));

export const updateTag = (tagId, name) => (dispatch, state) =>
  SermonApi.updateTag(tagId, name, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE_TAG }));

export const setOrdering = ({orderBy, orderDir}) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ORDER,
    orderBy,
    orderDir
  });
  return Promise.resolve();
};

export const toggleSelectedTag = (tagId) => (dispatch, state) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAGS,
    selectedTags: state().sermon.selectedTags.includes(tagId)
      ? state().sermon.selectedTags.filter((eTagId) => eTagId !== tagId)
      : [...state().sermon.selectedTags, tagId]

  });
  return Promise.resolve();
};

export const clearSelectedTags = () => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAGS,
    selectedTags: []
  });

  return Promise.resolve();
};