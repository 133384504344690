import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Moment from 'moment'
import { getNextInvoice as getOrgNextInvoice } from '../../../state/org/actions'
import { Spinner, Money } from "../../../components";
import { getNextInvoice } from "../../../state/account/actions";

class NextInvoice extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false
    }
  }

  componentDidMount() {

    const { getNextInvoice, getOrgNextInvoice } = this.props;
    
    this.setState({loading: true, error: false});

    (this.props.personal ? getNextInvoice() : getOrgNextInvoice())
      .then(() => this.setState({loading: false}))
      .catch((e) => {this.setState({ error: e.message, loading: false })})
  }

  render() {

    const { orgNextInvoice, nextInvoice } = this.props;
    const { loading } = this.state;
    const viewedNextInvoice = this.props.personal ? nextInvoice : orgNextInvoice;

    if (loading) return null;

    if (!viewedNextInvoice) return (
      <div className="bp3-non-ideal-state">
        <h3 className="bp3-non-ideal-state-title">No upcoming invoice</h3>
      </div>
    );

    return (
      <div className="SubscriptionSummary full-width">
        <div className="grid-row s1">
          <div className="grid-item f-strong sr3">Total: </div>
          <div className="grid-item-3"><Money value={viewedNextInvoice.total} /></div>
        </div>
        {viewedNextInvoice.starting_balance < 0 && (
          <div className="grid-row s1">
            <div className="grid-item f-strong sr3">Credit: </div>
            <div className="grid-item-3"><Money value={viewedNextInvoice.starting_balance} /></div>
          </div>
        )}
        <div className="grid-row s1">
          <div className="grid-item f-strong sr3">Due: </div>
          <div className="grid-item-3"><Money value={viewedNextInvoice.amount_due}/> on {this.fDate(viewedNextInvoice.date)}</div>
        </div>
        <div className="grid-row s1">
          <div className="grid-item f-strong sr3">For usage period:</div>
          <div className="grid-item-3">{this.fDate(viewedNextInvoice.period_start)} - {this.fDate(viewedNextInvoice.period_end)}</div>
        </div>
      </div>
    )
  }

  fDate(date) {
    return Moment.unix(date).format("LL");
  }

}

export default connect(
  (state) => {
    return {
      nextInvoice: state.account.nextInvoice,
      orgNextInvoice: state.org.nextInvoice
    }
  },
  (dispatch) => (
    bindActionCreators({
      getOrgNextInvoice, getNextInvoice
    }, dispatch)
  )
)(NextInvoice);

