import * as SermonApi from "../../api/SermonApi";
import * as ServiceApi from "../../api/ServiceApi";
import * as ResourceApi from "../../api/ResourceApi";
import * as actionTypes from "./actionTypes";
import {getContext} from '../context/selectors';
import moment from 'moment-timezone'

export const getRecentMessages = () => (dispatch, state) => {

  var selectedTagId = state().overview.recentMessages.selectedTagId;
  var limit = state().overview.recentMessages.limit;

  return SermonApi.getAll(
    { orderby:  "id", orderdir: "desc", tags: selectedTagId ? [selectedTagId] : [] },
    getContext(state)
  ).then((result) => {

    // order by service date, and get top 5;

    var withRecentDates = result.items.map((item) => {

      var sortedServices =
        item.service.sort((aa, bb) => moment(aa.date).valueOf() < moment(bb.date).valueOf() ? 1 : -1);

      return {
        ...item,
        mostRecentService: sortedServices[0] ? {
          date: moment(sortedServices[0].date).valueOf(),
          id: sortedServices[0].id
        } : {
          id: null,
          date: 0
        }
      }
    });

    var sortedItems =
      withRecentDates
        .sort((a, b) => a.mostRecentService.date < b.mostRecentService.date ? 1 : -1)
        .filter((item, idx) => idx < limit);

    dispatch({ type: actionTypes.GET_RECENT_MESSAGES, items: sortedItems  })

  });
}


export const getRecentServices = () => (dispatch, state) =>
  ServiceApi.getAll(
    { orderby:  "id", orderdir: "desc", limit: 10, tags: state().overview.recentServices.selectedTagId },
    getContext(state)
  ).then((result) => {

    // find last and next services. return just those two...

    dispatch({ type: actionTypes.GET_RECENT_SERVICES, items: result.items })
  });

export const getPrayerResources = () => (dispatch, state) =>
  ResourceApi.getAll(
    { orderby:  "id", orderdir: "desc", limit: 10, tags: state().overview.prayerRequests.selectedTagId },
    getContext(state)
  ).then((result) => {
    dispatch({ type: actionTypes.GET_PRAYER_REQUESTS, items: result.items })
  });

export const setRecentMessageTag = (tagId) => (dispatch, state) => {
  dispatch({ type: actionTypes.SET_RECENT_MESSAGES_TAG, tagId  });
  return getRecentMessages()(dispatch, state)
};

export const setRecentServiceTag = (tagId) => (dispatch, state) => {
  dispatch({ type: actionTypes.SET_RECENT_SERVICES_TAG, tagId  });
  return getRecentServices()(dispatch, state)
};

export const setResourcesTag = (tagId) => (dispatch, state) => {
  dispatch({ type: actionTypes.SET_PRAYER_REQUESTS_TAG, tagId  });
  return getPrayerResources()(dispatch, state)
};

export const setRecentMessageLimit = (limit) => (dispatch, state) => {
  dispatch({ type: actionTypes.SET_RECENT_MESSAGES_LIMIT, limit  });
  return getRecentMessages()(dispatch, state)
};
