import React from 'react'
import * as Paths from "../../../constants/Paths";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {getPrayerResources, setResourcesTag} from '../../../state/overview/actions'
import {getTags} from '../../../state/resource/actions'
import moment from 'moment-timezone'

class PrayerRequests extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getPrayerResources();
    this.props.getTags()
  }

  render() {

    const { resources } = this.props;

    return (
      <div className="PrayerRequests">
        <div className="card-list grid-item sr5">
          <div className="header">
            <div className="title">Resources:</div>
          </div>
          <div className="PrayerRequests-resources">
            {resources.map((resource, idx) => (
              <div className="PrayerRequests-resource" key={idx}>
                <div className="grid-row">
                  <div className="grid-item">
                    <div className="PrayerRequests-title">{resource.title}</div>
                    {/*<div className="PrayerRequests-resourceer">*/}
                    {/*  {(resource.resourceer.spoken_first || resource.resourceer.first) ? (*/}
                    {/*      <React.Fragment>*/}
                    {/*    <span>*/}
                    {/*      {resource.resourceer.spoken_first || resource.resourceer.first} {resource.resourceer.last}*/}
                    {/*    </span>*/}
                    {/*        {resource.resourceer.email && (*/}
                    {/*          <span>*/}
                    {/*        {" "}( {resource.resourceer.email} )*/}
                    {/*      </span>*/}
                    {/*        )}*/}
                    {/*      </React.Fragment>*/}
                    {/*    ) :*/}
                    {/*    "Anonymous"*/}
                    {/*  }*/}
                    {/*</div>*/}
                    <div className="PrayerRequests-date">Last updated: {moment(resource.updated).format("LLL")}</div>
                  </div>
                  <div>
                    {resource.tags && resource.tags.map((tag, idx) => (
                      <div key={idx}>{tag.name}</div>
                    ))}
                  </div>
                </div>
                <div className="PrayerRequests-text">{addLineBreaks(resource.text)}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

}

const addLineBreaks = string =>
  string.split('\n').map((text, index) => (
    <React.Fragment key={`${text}-${index}`}>
      {text}
      <br />
    </React.Fragment>
  ));


export default connect(
  (state) => {
    return {
      resources: state.overview.prayerRequests.items,
      selectedTag: state.overview.recentMessages.selectedTag
    }
  },
  (dispatch) => (
    bindActionCreators({
      getPrayerResources, setResourcesTag, getTags
    }, dispatch)
  )
)(PrayerRequests);

