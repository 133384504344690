import React from 'react'
import {DashboardLayoutA} from '../layouts'
import {PaymentMethod, NextInvoice, ChargesList, SubscriptionSummary } from '../billing/components'

class UserBillingPage extends React.Component {
  render() {
    return (
      <DashboardLayoutA className="UserBillingPage">
        <div className="grid-restrain-narrow full-width">
          <div className="card-settings s5">
            <div className="inner-header">
              <div className="heading">Next Charge</div>
            </div>
            <div className="inner-body">
              <SubscriptionSummary personal />
            </div>
          </div>
          <div className="card-settings s5">
            <div className="inner-header">
              <div className="heading">Next Charge</div>
            </div>
            <div className="inner-body">
              <ChargesList personal />
            </div>
          </div>
          <div className="card-settings s4">
            <div className="inner-header">
              <div className="heading">Payment Method</div>
            </div>
            <div className="inner-body">
              <PaymentMethod personal />
            </div>
          </div>
          <div className="card-settings s5">
            <div className="inner-header">
              <div className="heading">Next Charge</div>
            </div>
            <div className="inner-body">
              <NextInvoice personal />
            </div>
          </div>
        </div>
      </DashboardLayoutA>
    )
  }

}

export default UserBillingPage;
