import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { CancelSubscriptionButton } from './'
import { getSubscription } from '../../../state/account/actions'
import { getSubscription as getOrgSubscription } from '../../../state/org/actions'
import { Link } from 'gatsby';
import Moment from 'moment'
import * as Paths from '../../../constants/Paths'

class SubscriptionSummary extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false
    }
  }

  componentDidMount() {

    const { personal = false, getSubscription, getOrgSubscription } = this.props;

    this.setState({loading: true, error: false});

    (personal ? getSubscription() : getOrgSubscription())
      .then((results) => this.setState({loading: false}))
      .catch((e) => {this.setState({ error: e.message, loading: false })})

  }

  render() {

    const { personal, orgSubscription, subscription } = this.props;
    var viewedSubscription = personal ? subscription : orgSubscription;

    if (!viewedSubscription) return (
      <div className="bp3-non-ideal-state">
        <h3 className="bp3-non-ideal-state-title">No subscription.</h3>
      </div>
    );

    return (
      <div className="SubscriptionSummary">
        {/*<div className="grid-row">*/}
          {personal ? (
            <div className="grid-row v-align sub-name">Personal Premium Subscription</div>
          ) : (
            <div className="s2">
              <div className="sub-name">
                Team Subscription<span className="seats">with {viewedSubscription.quantity} / seats</span>
              </div>
              <div className="alter-sub"><Link to={Paths.CONTACT}>Contact support</Link> to upgrade or downgrade.</div>
            </div>
          )}
        {/*</div>*/}
        <div className="grid-row v-align">
          <div className="bp3-tag bp3-intent-success sr2">{viewedSubscription.status === "trialing" ? "7-day Trial" : viewedSubscription.status}</div>
          <div className="grid-item grid-row b-align">
            {viewedSubscription.cancel_at_period_end && (
              <div className="sr2">Ends {Moment.unix(viewedSubscription.current_period_end).fromNow()}</div>
            )}
            {!viewedSubscription.cancel_at_period_end ? (
              <div className="f-subdued">Will auto-renew on {Moment.unix(viewedSubscription.current_period_end).format("LL")}</div>
            ) : (
              <div className="f5 f-subdued">Will cancel on {Moment.unix(viewedSubscription.current_period_end).format("LL")}</div>
            )}
          </div>
          {viewedSubscription.cancel_at_period_end ? (
            <CancelSubscriptionButton undo personal={personal} />
          ) : (
            <CancelSubscriptionButton personal={personal} />
          )}
        </div>
      </div>
    )
  }

}

export default connect(
  (state) => {
    return {
      orgSubscription: state.org.subscription,
      subscription: state.account.subscription
    }
  },
  (dispatch) => (
    bindActionCreators({
      getOrgSubscription, getSubscription
    }, dispatch)
  )
)(SubscriptionSummary);

