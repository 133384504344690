import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {DashboardLayoutA} from "../layouts";
import {ApiConfigure, MessageListConfigure} from './components'
import {Menu, Popover, Button, Intent} from '@blueprintjs/core'
import {getAll as getAllWidgets, create as createWidget} from '../../state/widget/actions'
import {getAll as getAllEntityApis} from '../../state/entityapi/actions'
import {getTags as getServiceTags} from '../../state/service/actions'
import {getTags as getSermonTags} from '../../state/sermon/actions'
import {getTags as getPersonTags} from '../../state/person/actions'
import {getTags as getSongTags} from '../../state/song/actions'
import {getTags as getTitheTags} from '../../state/tithe/actions'
import {getTags as getResourceTags} from '../../state/resource/actions'

class ShareOverview extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }

  }

  componentDidMount() {
    Promise.all([
      this.props.getServiceTags(),
      this.props.getSermonTags(),
      this.props.getPersonTags(),
      this.props.getSongTags(),
      this.props.getResourceTags(),
      this.props.getTitheTags(),
      this.props.getAllEntityApis(),
      this.props.getAllWidgets()
    ]).then(() => {
      this.setState({loading: false})
    })
  }

  render() {
    const { loading } = this.state;
    const { widgets } = this.props;

    return (
      <DashboardLayoutA className="page-content">
        <div className="ShareOveriewPage">
          <div className="grid-row">
            <div className="grid-item-3 sr5">
              <div className="card-list">
                <div className="header grid-row v-align">
                  <div className="title grid-item">Widgets</div>
                  <Popover
                    content={
                      <Menu>
                        <Menu.Item
                          icon="cube"
                          text="Messages List"
                          onClick={() => this.onNewWidget("sermonlist")}
                        />
                      </Menu>
                    }
                    target={
                      <Button intent={Intent.PRIMARY} text={"Add Widget"} icon="add" />
                    }
                  />
                </div>
                {widgets.length === 0 && (
                  <div className="bp3-non-ideal-state">
                    <div className="bp3-non-ideal-state-visual bp3-non-ideal-state-icon">
                      <span className="bp3-icon bp3-icon-folder-open"></span>
                    </div>
                    <h4 className="bp3-non-ideal-state-title">You don't have any widgets yet.</h4>
                  </div>
                )}
                {widgets.map((widget, idx) => (
                  widget.type === "sermonlist" ?
                    <div className="widget-config">
                      <MessageListConfigure widget={widget} />
                    </div>
                    : null
                ))}
              </div>
            </div>
            <div className="grid-item-2">
              <div className="card">
                <div className="header">
                  <div className="title">Data Sharing</div>
                </div>
                {!loading && (<ApiConfigure />)}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayoutA>
    )
  }

  onNewWidget = (type) => {

    const { createWidget, getAllWidgets } = this.props;

    createWidget({
      "type": type,
      "version": "1",
      "name": "Unnamed Message List Widget",
      "config": {
        "css": "",
        "title": "",
        "limit": 10,
        "tags": [],
        "showTagFilters": false
      },
    }).then(() => getAllWidgets())
  }

}

export default connect(
  (state) => {
    return {
      widgets: state.widget.items
    }
  },
  (dispatch) => (
    bindActionCreators({
      getServiceTags,
      getSermonTags,
      getSongTags,
      getTitheTags,
      getResourceTags,
      getPersonTags,
      getAllEntityApis,
      getAllWidgets,
      createWidget
    }, dispatch)
  )
)(ShareOverview);
