import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {DashboardLayoutA} from "../layouts";
import {ServiceList} from './containers'

class ServiceListPage extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (
      <DashboardLayoutA className="page-content">
        <div className="ServiceListPage">
          <ServiceList />
        </div>
      </DashboardLayoutA>
    )
  }

}

export default connect(
  (state) => {
    return {

    }
  },
  (dispatch) => (
    bindActionCreators({

    }, dispatch)
  )
)(ServiceListPage);