import {statusErrorFilter, apiErrorFilter, headers} from './common/apicommons';
import queryString from 'query-string';

export function getOne(sermonId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/sermon/${sermonId}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function getAll(
{
  offset = 0,
  limit = 1000,
  archived = false,
  orderby="id",
  orderdir="desc",
  tags,
  showPublic=false
}, {ownerType, ownerId}) {

  var query = queryString.stringify({
    offset,
    limit,
    archived: archived ? 1 : 0,
    orderby,
    orderdir,
    tags
  }, {
    arrayFormat: "bracket"
  });

  return fetch(`${process.env.GATSBY_API_URL}${showPublic ? "/public" : ""}/${ownerType}/${ownerId}/sermon?${query}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function create(sermon, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/sermon`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(sermon),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function update(sermonId, sermon, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/sermon/${sermonId}`, {
    method: 'put',
    credentials: 'include',
    body: JSON.stringify(sermon),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function patch(sermonId, patch, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/sermon/${sermonId}`, {
    method: 'PATCH',
    credentials: 'include',
    body: JSON.stringify(patch),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function linkOne(sermonId, tagId, linkType = "tag", {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/sermon/${sermonId}/${linkType}/${tagId}`, {
    method: 'post',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function unlinkOne(sermonId, tagId, linkType = "tag", {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/sermon/${sermonId}/${linkType}/${tagId}`, {
    method: 'delete',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function linkSync(sermonId, linkIds, {ownerType, ownerId}, linkType = "tag") {

  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/sermon/${sermonId}/${linkType}/sync`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(linkType === "tag" ? {tags: linkIds} : {ids: linkIds}),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function duplicate(sermonId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/sermon/${sermonId}/duplicate`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function archive(sermonId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/sermon/${sermonId}/archive`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function unArchive(sermonId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/sermon/${sermonId}/unarchive`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function hardDelete(sermonId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/sermon/${sermonId}`, {
    method: 'delete',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function getTags({ ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/sermon/tag`, {
    method: 'get',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function createTag(tag, { ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/sermon/tag`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(tag),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function updateTag(tagId, name, { ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/sermon/tag/${tagId}`, {
    method: 'put',
    credentials: 'include',
    body: JSON.stringify({name}),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function archiveTag(tagId, { ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/sermon/tag/${tagId}/archive`, {
    method: 'post',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function tagLinkSync(sermonId, tagIds, { ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/sermon/${sermonId}/tag/sync`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify({tags: tagIds}),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}
