import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ClassNames from 'classnames';
import {Icon, Menu, MenuDivider, MenuItem, Popover, Position, Spinner, Tooltip, Tag, Intent, Classes} from '@blueprintjs/core'
import {
  archive,
  archiveTag,
  clearSelectedTags,
  createTag,
  duplicate,
  getAll,
  getTags,
  setOrdering,
  toggleSelectedTag,
  updateTag,
  linkOne
} from '../../../state/tithe/actions'
import {AddTitheButton} from "./";
import {Link} from 'gatsby'
import {ListFilter, QuickAddTag} from "../../components/index";
import {Toaster} from "../../../components/index";
import * as Paths from "../../../constants/Paths";
import {generateCSV} from "../../../managers/csv";
import {getContext} from "../../../state/context/selectors";
import {buildLookup} from '../../../utils/utils'

class TitheList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };

  }

  componentDidMount() {
    this.getAllTithes();
    this.props.getTags();
  }

  getAllTithes = () => {
    this.setState({loading: true});
    this.props.getAll()
      .then(() => this.setState({loading: false}))
      .catch((e) => this.setState({loading: false}))
  }

  render() {

    const {
      tithes,
      tags,
      orderBy,
      orderDir,
      toggleSelectedTag,
      clearSelectedTags,
      selectedTags,
      archiveTag,
      getTags,
      updateTag,
      createTag,
      context,
      tagLookup,
      linkOne
    } = this.props;

    const { loading } = this.state;

    const sorteableHeader = (columnName, columnTitle) => (
      <th
        className={
          ClassNames("th-name ordereable", {
            ordered: orderBy === columnName,
            "ordered-asc": orderBy === columnName && orderDir === "asc",
            "ordered-desc": orderBy === columnName && orderDir === "desc"
          })
        }
        onClick={() => this.onHeaderOrder(columnName)}>
        {orderBy === columnName && (
          orderDir === "asc" ?
            <Icon icon="arrow-up"/>
          : orderDir === "desc" ?
            <Icon icon="arrow-down"/> : null
          )}
        {columnTitle}
      </th>
    );

    return (
      <div className="TitheList">
        <div className="card-list">
          <div className="header grid-row v-align">
            <div className="grid-item">
              <ListFilter
                buttonLabel={"Tithe Categories"}
                className="sr3"
                tags={tags}
                archiveTag={archiveTag}
                updateTag={updateTag}
                createTag={createTag}
                getTags={getTags}
                getAll ={this.getAllTithes}
                toggleSelectedTag={toggleSelectedTag}
                selectedTags={selectedTags}
                clearSelectedTags={clearSelectedTags}
              />
              {selectedTags.map((selectedTag, idx) => (
                <Tag
                  key={idx}
                  large
                  onRemove={() => toggleSelectedTag(selectedTag).then(this.getAllTithes)}
                  intent={Intent.SUCCESS}
                  className="sr1"
                >
                  {tagLookup[selectedTag] ? tagLookup[selectedTag].name : "...loading"}
                </Tag>
              ))}
            </div>
            <AddTitheButton className="bp3-button bp3-icon-add bp3-intent-primary">Add New Giving Report</AddTitheButton>
          </div>
          <div className="list-body">
            {loading ? (
              <Spinner size={50} />
            ) :
            tithes.length === 0 ? (
              <div className="bp3-non-ideal-state">
                <div className="bp3-non-ideal-state-visual bp3-non-ideal-state-icon">
                  <span className="bp3-icon bp3-icon-folder-open"></span>
                </div>
                <h4 className="bp3-non-ideal-state-title">You don't have any tithes yet.</h4>
              </div>
            ) : (
              <table className="bp3-table">
                <thead>
                <tr>
                  <th className="th-icon"></th>
                  {sorteableHeader("date", "Date")}
                  {sorteableHeader("amount", "Amount")}
                  <th className="th-name" style={{'max-width' : 200}}>
                    <Tooltip content={`To filter by category/tag, use the "filter" button in the top left of this list.`} position={Position.RIGHT}>
                      <span>Category</span>
                    </Tooltip>
                  </th>
                  <th className="th-menu"></th>
                </tr>
                </thead>
                <tbody>
                {tithes.map((tithe, idx) => (
                  <tr key={idx}>
                    <td className="td-icon"><span className="bp3-icon-standard bp3-icon-dollar"></span></td>
                    <td className="td-name"><Link
                      to={Paths.withId(Paths.TITHE_EDIT, tithe.id)}>{tithe.date}</Link>
                    </td>
                    <td className="td-name">{tithe.amount}</td>
                    <td className="td-name">
                      <QuickAddTag
                        tags={tags}
                        linkOne={linkOne}
                        entityId={tithe.id}
                      />
                      {tithe.tags && tithe.tags.map((tag, idx) => (
                        <span key={idx} className="bp3-tag bp3-minimal bp3-intent-success sr1">{tag.name}</span>
                      ))}
                    </td>
                    <td className="td-menu">
                      <Popover
                        position={Position.BOTTOM_RIGHT}
                        content={(
                          <Menu className="bp3-minimal">
                            <li>
                              <Link className="bp3-menu-item bp3-icon-edit"
                                    to={Paths.withId(Paths.TITHE_EDIT, tithe.id)}>Edit</Link>
                            </li>
                            <MenuItem text="Clone" iconname="duplicate" onClick={() => this.onDuplicate(tithe)}/>
                            <MenuDivider/>
                            <li className={Classes.POPOVER_DISMISS}>
                              <a className="bp3-menu-item tithe bp3-intent-danger"
                                 onClick={() => this.onArchive(tithe)}>Archive</a>
                            </li>
                          </Menu>
                         )}>
                        <span className="bp3-icon-standard bp3-icon-more"></span>
                      </Popover>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    )
  }

  onHeaderOrder(header) {

    const { setOrdering, orderDir, orderBy } = this.props;

    if (orderBy === header) {
      if (orderDir === "asc") setOrdering({orderDir: "desc", orderBy});
      else setOrdering({orderBy: null, orderDir: null})
    } else {
      setOrdering({orderBy: header, orderDir: "asc"});
    }

    this.props.getAll()
  }

  onArchive(tithe) {

    const { archive, getAll } = this.props;

    confirm("Are you sure?") ? archive(tithe.id)
      .then(() => getAll())
      .then(() => Toaster.success("Successfully archived tithe."))
      .catch((e) => Toaster.error(e.message)) : null;

  }

  onDuplicate(tithe) {

    const { duplicate, getAll } = this.props;

    duplicate(tithe.id)
      .then(() => getAll())
      .then(() => Toaster.success("Successfully duplicated tithe."))
      .catch((e) => Toaster.error(e.message))

  }

  onExport() {
    generateCSV([["name"]]
      .concat(this.props.tithes.map((tithe) => [
        tithe.name
      ])), `Tithes`);
  }

}

TitheList.defaultProps = {
  tithes: [],
  tags: []
}

export default connect(
  (state) => {
    return {
      tithes: state.tithe.items,
      selectedTags: state.tithe.selectedTags,
      tagLookup: buildLookup(state.tithe.tags, "id"),
      tags: state.tithe.tags,
      orderBy: state.tithe.orderBy,
      orderDir: state.tithe.orderDir,
      context: getContext(state)
    }
  },
  (dispatch) => (
    bindActionCreators({
      getAll,
      archive,
      duplicate,
      getTags,
      setOrdering,
      toggleSelectedTag,
      clearSelectedTags,
      archiveTag,
      updateTag,
      createTag,
      linkOne
    }, dispatch)
  )
)(TitheList);