import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'gatsby'
import {DashboardLayoutA} from "../layouts";
import {PersonEditForm} from './containers'
import {ArchiveButton} from '../components'
import {archive, getOne, getTags} from "../../state/person/actions";
import * as Paths from '../../constants/Paths'
import {Button, Intent, Popover, Menu, Position, Tooltip, AnchorButton} from '@blueprintjs/core';
import {navigate} from 'gatsby'
import {getInformalFullname} from "../../state/person/util";

class PersonEditPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }

  }

  componentDidMount() {
    Promise.all([
      this.props.getOne(this.props.id),
      this.props.getTags()
    ]).then(() => this.setState({loading: false}))
  }

  render() {

    const { person, archive } = this.props;

    if (!person) return null;

    return (
      <DashboardLayoutA className="page-content-narrow PersonEditPage" loading={this.state.loading}>
        <div className="PersonEditPage">
          <div className="card-settings s5">
            <div className="inner-header grid-row v-align">
              <Tooltip content="Back to all People." position={Position.RIGHT}>
                <AnchorButton
                  icon="arrow-left"
                  outlined
                  minimal
                  href={Paths.PERSON_LIST}
                  onClick={(e) => {e.preventDefault(); navigate(Paths.PERSON_LIST) }}
                  className="heading-back"
                />
              </Tooltip>
              <div className="grid-item">
                <div className="heading"><span className="bp3-icon-standard bp3-icon-person sr3"></span>{getInformalFullname(person)}</div>
              </div>
              <ArchiveButton
                className="bp3-button bp3-icon-archive"
                id={person.id}
                navigateTo={Paths.PERSON_LIST}
                entityName="person"
                archive={archive}>
                Archive
              </ArchiveButton>
            </div>
            <PersonEditForm />
          </div>
        </div>
      </DashboardLayoutA>
    )
  }

}

PersonEditPage.defaultProps = {
  person: {id: 0, name: ""}
}

export default connect(
  (state) => {
    return {
      person: state.person.item
    }
  },
  (dispatch) => (
    bindActionCreators({
      getOne, getTags, archive
    }, dispatch)
  )
)(PersonEditPage);