import React from 'react'
import {getAll} from '../../../../api/SermonApi'
import moment from 'moment-timezone'
import {Icon, Spinner} from '@blueprintjs/core'

class MessageList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      messages: [],
      loading: true
    }

  }

  componentDidMount() {

    const { widget } = this.props;

    getAll(
      {
        showPublic: true,
        orderby: "date",
        orderdir: "desc",
        limit: widget.config.limit,
        ...(widget.config.tags && widget.config.tags.length > 0) && { tags: widget.config.tags }
      },
      {ownerType: widget.owner_type, ownerId: widget.owner_id}
    ).then((result) => {
      this.setState({messages: result.items, loading: false})
    });

    if (widget.config.css) {
      // Create the <style> tag
      this.style = document.createElement("style");
      var processedCSS = widget.config.css.replace(/\#widget/g, `#MessageList-${widget.id}`);
      this.style.appendChild(document.createTextNode(processedCSS));
      document.head.appendChild(this.style);
    }

  }

  componentWillUnmount() {
    if (this.style) this.style.remove();
  }

  render() {

    const { widget } = this.props;
    const { messages, loading } = this.state;

    if (loading) {
      return (
        <div className="full-height grid-row v-align h-align">
          <Spinner size={30} />
        </div>
      )
    }

    return (
      <div className="MessageList oc-widget-preset-1" id={`MessageList-${widget.id}`}>
        <div className="oc-title">{widget.config.title}</div>
        {messages.map((message, idx) => (
          <div key={idx} className="oc-row">
            <div className="oc-messagename">
              {message.link ? (
                <a href={message.link} target="_blank">
                  <Icon icon="feed" /> {message.name}
                </a>
              ) : message.name}
            </div>
            {message.speaker && (
              <div className="oc-messagespeaker">
                {message.speaker.first} {message.speaker.last}
              </div>
            )}
            {message.date && (
              <div className="oc-messagedate">
                {moment(message.date).format(widget.config.dateFormat || "l")}
              </div>
            )}
          </div>
        ))}
      </div>
    )
  }

}

export default MessageList;
