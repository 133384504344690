import {statusErrorFilter, apiErrorFilter, headers} from './common/apicommons';
import queryString from 'query-string';

export function getOne(widgetId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/widget/${widgetId}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function getOnePublic(widgetId) {
  return fetch(`${process.env.GATSBY_API_URL}/public/widget/${widgetId}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function getAll({offset = 0, limit = 1000, archived = false, orderby="id", orderdir="desc", tags}, {ownerType, ownerId}) {

  var query = queryString.stringify({
    offset,
    limit,
    archived: archived ? 1 : 0,
    orderby,
    orderdir,
    tags
  }, {
    arrayFormat: "bracket"
  });

  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/widget?${query}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function create(widget, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/widget`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(widget),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function update(widgetId, widget, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/widget/${widgetId}`, {
    method: 'put',
    credentials: 'include',
    body: JSON.stringify(widget),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function patch(widgetId, patch, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/widget/${widgetId}`, {
    method: 'PATCH',
    credentials: 'include',
    body: JSON.stringify(patch),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function duplicate(widgetId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/widget/${widgetId}/duplicate`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function archive(widgetId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/widget/${widgetId}/archive`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function unArchive(widgetId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/widget/${widgetId}/unarchive`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function hardDelete(widgetId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/widget/${widgetId}`, {
    method: 'delete',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}
