import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { Toaster } from '../../../../components';
import { TextInput } from '../../../../components/form';
import Formsy from 'formsy-react';
import _ from 'lodash'
import { Dialog, Button, Intent } from "@blueprintjs/core";
import {getSelectedOrg} from "../../../../state/org/selectors";
import {createInvite} from "../../../../state/org/actions";

class AddCollaboratorDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {showErrors: false}
  }

  render() {

    const { onClose = _.noop, isOpen = false } = this.props;
    const { showErrors = false } = this.state;

    return (
      <div className="AddCollaboratorDialog">
        <Dialog
          iconName="user"
          isOpen={isOpen}
          onClose={() => onClose() }
          title="Add Collaborator"
        >
          <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
                  onInvalidSubmit={() => this.onInvalidSubmit() }
                  ref="form">
            <div className="bp3-dialog-body">
              <TextInput name="email"
                     type="email"
                     validationError="This is not a valid email"
                     icon="build"
                     label="Email:"
                     showErrors={showErrors}
                     autofocus
                     required />
            </div>
            <div className="bp3-dialog-footer">
              <div className="bp3-dialog-footer-actions">
                <Button text="Cancel" onClick={() => onClose()}/>
                <Button
                  intent={Intent.PRIMARY}
                  onClick={() => this.refs.form.submit() }
                  text="Submit"
                />
              </div>
            </div>
          </Formsy>
        </Dialog>
      </div>
    )
  }

  onValidSubmit(model) {

    const { onClose, createInvite } = this.props;

    createInvite(model.email)
      .then((result) => onClose())
      .catch((e) => Toaster.show({ message: e.message,  iconName: "warning-sign",  intent: Intent.DANGER }));

  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

}

export default connect(
  (state) => {
    return {
      selectedOrg: getSelectedOrg(state)
    }
  },
  (dispatch) => (
    bindActionCreators({
      createInvite
    }, dispatch)
  )
)(AddCollaboratorDialog);

