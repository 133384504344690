import {statusErrorFilter, apiErrorFilter, headers} from './common/apicommons';
import queryString from 'query-string';

export function getOne(titheId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe/${titheId}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function getAll({offset = 0, limit = 1000, archived = false, orderby="id", orderdir="desc", tags}, {ownerType, ownerId}) {

  var query = queryString.stringify({
    offset,
    limit,
    archived: archived ? 1 : 0,
    orderby,
    orderdir,
    tags
  }, {
    arrayFormat: "bracket"
  });

  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe?${query}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function create(tithe, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(tithe),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function update(titheId, tithe, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe/${titheId}`, {
    method: 'put',
    credentials: 'include',
    body: JSON.stringify(tithe),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function patch(titheId, patch, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe/${titheId}`, {
    method: 'PATCH',
    credentials: 'include',
    body: JSON.stringify(patch),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function linkSync(titheId, linkIds, {ownerType, ownerId}) {

  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe/${titheId}/tag/sync`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify({tags: linkIds}),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function duplicate(titheId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe/${titheId}/duplicate`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function archive(titheId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe/${titheId}/archive`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function unArchive(titheId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe/${titheId}/unarchive`, {
    method: 'post',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function hardDelete(titheId, {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe/${titheId}`, {
    method: 'delete',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function getTags({ ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe/tag`, {
    method: 'get',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function createTag(tag, { ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe/tag`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(tag),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function updateTag(tagId, name, { ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe/tag/${tagId}`, {
    method: 'put',
    credentials: 'include',
    body: JSON.stringify({name}),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function archiveTag(tagId, { ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe/tag/${tagId}/archive`, {
    method: 'post',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function linkOne(sermonId, tagId, linkType = "tag", {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe/${sermonId}/${linkType}/${tagId}`, {
    method: 'post',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function unlinkOne(sermonId, tagId, linkType = "tag", {ownerType, ownerId}) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe/${sermonId}/${linkType}/${tagId}`, {
    method: 'delete',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function tagLinkSync(titheId, tagIds, { ownerType, ownerId }) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/tithe/${titheId}/tag/sync`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify({tags: tagIds}),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}
