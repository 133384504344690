import React from 'react'
import { Popover, Button, Menu, Intent } from "@blueprintjs/core";

class AddRoleToServicePopover extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    }
  }

  render() {

    const { roles = [] } = this.props;

    return (
      <Popover
        content={
          <Menu>
            {roles.map((role, idx) => (
              <Menu.Item
                icon="social-media"
                text={role.name}
                onClick={(e) => this.addRole(e, role)}
              />
            ))}
          </Menu>
        }
        target={
          <Button intent={Intent.PRIMARY} text="Add" loading={this.state.loading} />
        }
      />
    )
  }

  addRole = (e, role) => {

    const { link, serviceId, getServiceRoles } = this.props;
    this.setState({loading: true});

    link(serviceId, role.id, 'role')
      .then(() => getServiceRoles(serviceId))
      .then(() => this.setState({loading: false}))

  }

}

export default AddRoleToServicePopover;
