export const TAG_FIELDS = [
  "id",
  "name",
  "owner_type",
  "owner_id",
  "updated",
  "created",
  "archived"
];

export const PERSON_FIELDS = [
  "id", 
  "first", 
  "spoken_first", 
  "last", 
  "phone", 
  "address", 
  "email",
  "birth_month",
  "birth_day",
  "hometown", 
  "requests", 
  "notes", 
  "owner_type", 
  "owner_id", 
  "created", 
  "updated", 
  "archived", 
  "deleted", 
];

export const SERVICE_FIELDS = [
  "id",
  "date",
  "owner_id",
  "owner_type",
  "updated",
  "created",
  "organized",
  "archived",
  "notes"
];

export const SERMON_FIELDS = [
  "id",
  "date",
  "name",
  "description",
  "link",
  "speaker_id",
  "notes",
  "owner_id",
  "owner_type",
  "updated",
  "created",
  "archived"
];

export const SONG_FIELDS = [
  "id",
  "name",
  "author",
  "videolink",
  "sheetpdflink",
  "chordpdflink",
  "slideslink",
  "notes",
  "owner_id",
  "owner_type",
  "updated",
  "created",
  "archived"
];

export const TEAM_FIELDS = [
  "id",
  "name",
  "notes",
  "owner_id",
  "owner_type",
  "updated",
  "created",
  "archived"
];

export const TITHE_FIELDS = [
  "id",
  "date",
  "amount",
  "notes",
  "owner_id",
  "owner_type",
  "updated",
  "created",
  "archived"
];

export const RESOURCE_FIELDS = [
  "id",
  "date",
  "name",
  "link",
  "text",
  "markdown",
  "urgency",
  "notes",
  "owner_id",
  "owner_type",
  "updated",
  "created",
  "archived"
];

export const API_FIELDS = {
  service: [
    ...SERVICE_FIELDS,
    ...TAG_FIELDS.map((field) => "tags." + field),
    ...SONG_FIELDS.map((field) => "songs." + field),
    ...SERMON_FIELDS.map((field) => "sermons." + field),
  ],
  sermon: [
    ...SERMON_FIELDS,
    ...TAG_FIELDS.map((field) => "tags." + field),
    ...PERSON_FIELDS.map((field) => "speaker." + field)
  ],
  person: [
    ...PERSON_FIELDS,
    ...TAG_FIELDS.map((field) => "tags." + field),
  ],
  song: [
    ...SONG_FIELDS,
    ...TAG_FIELDS.map((field) => "tags." + field),
    ...SERVICE_FIELDS.map((field) => "service." + field)
  ],
  team: [
    ...TEAM_FIELDS,
    ...PERSON_FIELDS.map((field) => "persons." + field)
  ],
  tithe: [
    ...TITHE_FIELDS,
    ...TAG_FIELDS.map((field) => "tags." + field),
  ],
  resource: [
    ...RESOURCE_FIELDS,
    ...TAG_FIELDS.map((field) => "tags." + field),
  ]
};