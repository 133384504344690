import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Formsy from 'formsy-react';
import {TextInput, MultiSelect, Textarea, DateTimeInput} from '../../../components/form';
import {getOne, update, patch, linkSync, createTag} from "../../../state/sermon/actions";
import {getAll as getAllPersons} from "../../../state/person/actions";
import Select from "../../../components/form/Select";
import Moment from 'moment-timezone'
import {Button, Intent} from '@blueprintjs/core'

class SermonEditForm extends React.Component {

  componentDidMount() {
    if (this.props.id) {
      this.props.getOne(this.props.id);
      this.props.getAllPersons({noFilters: true});
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      isProcessing: false,
      message: null,
      messageIsError: false,
      isPristine: true
    };

  }

  render() {

    const { showErrors, isProcessing, message, messageIsError, isPristine, reset } = this.state;
    const { sermon, tags, persons } = this.props;

    return (
      <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
              onChange={(values, isChanged) => this.checkForPristine(values, isChanged) }
              onInvalidSubmit={() => this.onInvalidSubmit() }
              ref="form">
        <div className="form-fields">
          <TextInput
            name="name"
            type="text"
            icon="build"
            value={sermon.name}
            label="Message name:"
            showErrors={showErrors}
            required
          />
          <TextInput
            name="description"
            type="text"
            icon="build"
            value={sermon.description}
            label="Description:"
            showErrors={showErrors}
          />
          <TextInput
            name="link"
            type="text"
            link={sermon.link}
            icon="link"
            value={sermon.link}
            label="Link to message:"
            showErrors={showErrors}
          />
          <DateTimeInput
            name="date"
            icon="calendar"
            value={sermon.date}
            closeOnSelection={false}
            placeholder="No date."
            rightContent={(
              sermon.service.length > 0 && (sermon.service.length === 1 ? (
                <Button
                  type="button"
                  small
                  className="ml2"
                  onClick={this.setFirstServiceAsDate} >
                  As First Service
                </Button>
              ) : (
                <>
                  <Button
                    type="button"
                    small
                    className="ml2 sr2"
                    onClick={this.setFirstServiceAsDate}>
                    As First Service
                  </Button>
                  <Button
                    type="button"
                    small
                    onClick={this.setLastServiceAsDate}>
                    As Last Service
                  </Button>
                </>
              ))
            )}
            label="Date of sermon:" />

          <Select
            name="speaker_id"
            type="text"
            value={sermon.speaker_id + ""}
            label="Speaker:"
            options={persons.sort((a, b) => (a.spoken_first || a.first) > (b.spoken_first || b.first) ? 1 : -1). map((person) => ({
              name: (person.spoken_first || person.first) + " " + person.last,
              value: person.id + ""})
            )}
            showErrors={showErrors}
          />
          <Textarea name="notes"
                    value={sermon.notes}
                    label="Internal Notes:"
                    showErrors={showErrors}
          />
          <MultiSelect
            name="tags"
            type="text"
            icon="build"
            createable
            value={sermon.tags.map((tag) => ({
              value: tag.id + "",
              label: tag.name
            }))}
            isMulti
            options={tags.map((tag) => ({
              value: tag.id + "",
              label: tag.name
            }))}
            label="Message tags:"
            showErrors={showErrors}
          />
        </div>
        <div className="form-submit-group grid-row">
          <div className="grid-item grid-row v-align">
            {message && (
              <div className={"message" + (messageIsError ? " error-message" : "")}>
                {message}
              </div>
            )}
          </div>
          <div>
            <button className={"bp3-button sr2" + (isPristine ? " bp3-disabled" : "")}
                    type="button"
                    onClick={() => this.onCancel() }>Discard Changes</button>
            {!isProcessing ? (
              <button type="submit"
                      className={"bp3-button bp3-intent-primary" + (isPristine ? " bp3-disabled" : "")}>Save Changes</button>
            ) : (
              <button type="submit"
                      className="bp3-button bp3-disabled ">Submitting...</button>
            )}
          </div>
        </div>
      </Formsy>
    );
  }

  setFirstServiceAsDate = () => {
    const { sermon } = this.props;

    var lastServiceDate = Moment(sermon.service[0].date).toDate();

    this.refs.form.inputs[3].setValue(lastServiceDate)
  }

  setLastServiceAsDate = () => {
    const { sermon } = this.props;

    var lastServiceDate = Moment(sermon.service[sermon.service.length -1].date).toDate();

    this.refs.form.inputs[3].setValue(lastServiceDate)
  }

  onValidSubmit(model) {

    const { isPristine } = this.state;
    const { sermon, linkSync, patch, getOne, createTag } = this.props;
    if (isPristine) return;

    this.setState({isProcessing: true, serverError: null});

    var selectedTags = model.tags || [];

    Promise
      .all(selectedTags.filter((tag) => tag.__isNew__).map((tag) => createTag(tag.label)))
      .then((results) => {
        // find new tags and link them.

        var newTags = results.map((result) => result.item.id);
        var currentTags = selectedTags.filter((tag) => !tag.__isNew__).map((tag) => parseInt(tag.value));

        return Promise.all([
          patch(sermon.id, {
            name: model.name,
            description: model.description,
            link: model.link,
            speaker_id: parseInt(model.speaker_id),
            notes: model.notes,
            date: model.date ? Moment(model.date).format("YYYY-MM-DD HH:mm:ss") : null,
          }),
          linkSync(sermon.id, [...newTags, ...currentTags])
        ]);

      })
      .then((result) => getOne(sermon.id))
      .then((result) => {
        this.setState({
          isProcessing: false,
          message: "Your changes were applied successfully.",
          messageIsError: false,
          reset: true
        }, () => this.setState({reset: false}));

      })
      .catch((e) => {
        this.setState({
          isProcessing: false,
          message: e.message,
          messageIsError: true
        });
      });
  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

  checkForPristine(values, isChanged) {
    this.setState({isPristine: !isChanged});
  }

  onCancel() {

    const { isPristine } = this.state;
    if (isPristine) return;

    this.refs.form.reset();
    this.setState({
      message : "Your changes have been discarded.",
      messageIsError: false
    });
  }

}

export default connect(
  (state, props) => {
    return {
      sermon: props.sermon ? props.sermon : state.sermon.item,
      persons: state.person.items,
      tags: state.sermon.tags || [],
    }
  },
  (dispatch) => (
    bindActionCreators({
      update, createTag, getOne, patch, linkSync, getAllPersons
    }, dispatch)
  )
)(SermonEditForm);
