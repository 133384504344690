import _ from 'lodash'

export const getSelectedContextName = (state) => {
  if (state.context.type === "org") {
    var org = _.find(state.account.orgs, {id: state.context.id});

    if (org) return org.name;
  }

  return "";
};

export const getContext = (state) => {

  var snapshot = typeof state === "function" ? state() : state;

  return {
    ownerType: snapshot.context.type,
    ownerId: snapshot.context.id
  }
}
