import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Formsy from 'formsy-react';
import {FormGroup} from '@blueprintjs/core'
import {Textarea, TextInput, SelectSimple} from '../../../components/form';
import {createTag, getOne, linkSync, patch, update} from "../../../state/person/actions";
import MultiSelect from "../../../components/form/MultiSelect";
import {MonthsDays, MonthsDaysLookup} from '../../../constants/MonthsDays'

class PersonEditForm extends React.Component {

  componentDidMount() {
    if (this.props.id) {
      this.props.getOne(this.props.id);
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      isProcessing: false,
      message: null,
      messageIsError: false,
      isPristine: true
    };

  }

  render() {

    const { showErrors, isProcessing, message, messageIsError, isPristine, reset } = this.state;
    const { person, tags } = this.props;

    return (
      <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
              onChange={(values, isChanged) => this.checkForPristine(values, isChanged) }
              onInvalidSubmit={() => this.onInvalidSubmit() }
              ref="form">
        <div className="form-fields">
          <TextInput name="first"
                 type="text"
                 icon="build"
                 value={person.first}
                 label="First name:"
                 showErrors={showErrors}
                 required />
          <TextInput name="last"
                 type="text"
                 icon="build"
                 value={person.last}
                 label="Last name:"
                 showErrors={showErrors}
          />
          <TextInput name="spoken_first"
                     type="text"
                     icon="build"
                     value={person.spoken_first}
                     label="Spoken first name:"
                     showErrors={showErrors}
          />
          <TextInput name="phone"
                 type="text"
                 icon="build"
                 value={person.phone}
                 label="Phone Number:"
                 showErrors={showErrors} />
          <TextInput name="address"
                 type="text"
                 icon="build"
                 value={person.address}
                 label="Address:"
                 showErrors={showErrors} />
          <TextInput name="email"
                 type="email"
                 icon="build"
                 value={person.email}
                 label="Email:"
                 showErrors={showErrors} />
          <TextInput name="hometown"
                 type="text"
                 icon="build"
                 value={person.hometown}
                 label="Hometown:"
                 showErrors={showErrors} />
          <FormGroup
            label="Birthday:"
          >
            <SelectSimple
              name="birth_month"
              value={person.birth_month}
              label="Birth Month:"
              options={
                MonthsDays.map((month, idx) => ({name: month.name, value: idx + 1 }))
              }
              showErrors={showErrors}
            />
            <SelectSimple
              name="birth_day"
              value={person.birth_day}
              label="Birth Day:"
              options={
                Array.from(Array(31).keys()).map((day) =>  ({name: (day + 1) + "", value: (day + 1) + ""}))
              }
              showErrors={showErrors}
            />
          </FormGroup>
          <Textarea name="notes"
                    value={person.notes}
                    label="Internal Notes:"
                    showErrors={showErrors}
          />
          <MultiSelect
            name="tags"
            type="text"
            icon="build"
            createable
            value={person.tags.map((tag) => ({
              value: tag.id + "",
              label: tag.name
            }))}
            isMulti
            options={tags.map((tag) => ({
              value: tag.id + "",
              label: tag.name
            }))}
            label="Tags:"
            showErrors={showErrors}
          />
        </div>
        <div className="form-submit-group grid-row">
          <div className="grid-item grid-row v-align">
            {message && (
              <div className={"message" + (messageIsError ? " error-message" : "")}>
                {message}
              </div>
            )}
          </div>
          <div>
            <button className={"bp3-button sr2" + (isPristine ? " bp3-disabled" : "")}
                    type="button"
                    onClick={() => this.onCancel() }>Cancel</button>
            {!isProcessing ? (
              <button type="submit"
                      className={"bp3-button bp3-intent-primary" + (isPristine ? " bp3-disabled" : "")}>Submit</button>
            ) : (
              <button type="submit"
                      className="bp3-button bp3-disabled ">Submitting...</button>
            )}
          </div>
        </div>
      </Formsy>
    );
  }

  onValidSubmit(model) {

    const { isPristine } = this.state;
    const { person, linkSync, patch, getOne, createTag } = this.props;
    if (isPristine) return;

    this.setState({isProcessing: true, serverError: null});

    var selectedTags = model.tags || [];

    Promise
      .all(selectedTags.filter((tag) => tag.__isNew__).map((tag) => createTag(tag.label)))
      .then((results) => {
        // find new tags and link them.

        var newTags = results.map((result) => result.item.id);
        var currentTags = selectedTags.filter((tag) => !tag.__isNew__).map((tag) => parseInt(tag.value));

        return Promise.all([
          patch(person.id, {
            first: model.first,
            last: model.last,
            phone: model.phone,
            ...model.birth_month && { birth_month: parseInt(model.birth_month)},
            ...model.birth_day && { birth_day: parseInt(model.birth_day)},
            address: model.address,
            email: model.email,
            hometown: model.hometown,
            spoken_first: model.spoken_first,
            notes: model.notes
          }),
          linkSync(person.id, [...newTags, ...currentTags])
        ]);

      })
      .then((result) => getOne(person.id))
      .then((result) => {
        this.setState({
          isProcessing: false,
          message: "Your changes were applied successfully.",
          messageIsError: false,
          reset: true
        }, () => this.setState({reset: false}));

      })
      .catch((e) => {
        this.setState({
          isProcessing: false,
          message: e.message,
          messageIsError: true
        });
      });
  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

  checkForPristine(values, isChanged) {
    this.setState({isPristine: !isChanged});
  }

  onCancel() {

    const { isPristine } = this.state;
    if (isPristine) return;

    this.refs.form.reset();
    this.setState({
      message : "Your changes have been discarded.",
      messageIsError: false
    });
  }

}

export default connect(
  (state, props) => {
    return {
      person: props.person ? props.person : state.person.item,
      tags: state.person.tags || [],
    }
  },
  (dispatch) => (
    bindActionCreators({
      update, createTag, getOne, patch, linkSync
    }, dispatch)
  )
)(PersonEditForm);
