import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Dialog, Button} from '@blueprintjs/core'
import RoleList from "./RoleList";
import {AddRoleButton} from './'
import RoleEdit from "./RoleEdit";

class RoleDialog extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      role: null
    }

  }

  render() {

    const { dialogProps } = this.props;
    const { role } = this.state;

    return (
      <>
        <Dialog
          {...dialogProps}
          iconName="music"
          className="no-padding"
          title={
            <div className="grid-row v-align" style={{"margin" : "1px"}}>
              <div className="grid-item">
                {role ? (
                  <Button icon="arrow-left" onClick={() => this.setState({role: null})}>Back to Roles</Button>
                ) : (
                  "Manage Roles"
                )}
              </div>
              {!role && (
                <AddRoleButton className="bp3-button bp3-icon-add sr3" onSuccess={this.onRoleAdded}>Add New Role</AddRoleButton>
              )}
            </div>
          }
        >
          {!role ? (
            <RoleList onSelectRole={(selectedRole) => this.setState({ role: selectedRole })} />
          ) : (
            <RoleEdit role={role} onArchive={() => this.setState({role: null})} />
          )}
        </Dialog>
      </>
    )
  }

  onRoleAdded = (newRole) => {
    this.setState({view: "edit", role: newRole});
  }

}

export default connect(
  (state) => {
    return {
    }
  },
  (dispatch) => (
    bindActionCreators({

    }, dispatch)
  )
)(RoleDialog);