import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ClassNames from 'classnames';
import {Icon, Menu, MenuDivider, MenuItem, Popover, Position, Spinner, Intent, Tag, Tooltip, Classes} from '@blueprintjs/core'
import {
  archive,
  archiveTag,
  clearSelectedTags,
  createTag,
  duplicate,
  getAll,
  getTags,
  setOrdering,
  toggleSelectedTag,
  updateTag,
  link
} from '../../../state/service/actions'
import {AddServiceButton} from "../components";
import {Link} from 'gatsby'
import {Toaster} from "../../../components/index";
import * as Paths from "../../../constants/Paths";
import {generateCSV} from "../../../managers/csv";
import Moment from 'moment'
import {getContext} from "../../../state/context/selectors";
import {ListFilter, QuickAddTag} from "../../components";
import {buildLookup} from '../../../utils/utils'

class ServiceList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  componentDidMount() {
    this.getAllServices();
    this.props.getTags()
  }

  getAllServices = () => {
    this.setState({loading: true});
    this.props.getAll()
      .then(() => this.setState({loading: false}))
      .catch((e) => this.setState({loading: false}))
  };

  render() {

    const {
      services,
      tags,
      orderBy,
      orderDir,
      toggleSelectedTag,
      clearSelectedTags,
      selectedTags,
      archiveTag,
      getTags,
      updateTag,
      createTag,
      context,
      tagLookup,
      link
    } = this.props;

    const { loading } = this.state;

    const sorteableHeader = (columnName, columnTitle) => (
      <th
        className={
          ClassNames("th-name ordereable", {
            ordered: orderBy === columnName,
            "ordered-asc": orderBy === columnName && orderDir === "asc",
            "ordered-desc": orderBy === columnName && orderDir === "desc"
          })
        }
        onClick={() => this.onHeaderOrder(columnName)}>
        {orderBy === columnName && (
          orderDir === "asc" ?
            <Icon icon="arrow-up"/>
          : orderDir === "desc" ?
            <Icon icon="arrow-down"/> : null
          )}
        {columnTitle}
      </th>
    );

    return (
      <div className="ServiceList">
        <div className="card-list">
          <div className="header grid-row v-align">
            <div className="grid-item">
              <ListFilter
                buttonLabel={"Service Categories"}
                className="sr3"
                tags={tags}
                archiveTag={archiveTag}
                updateTag={updateTag}
                createTag={createTag}
                getTags={getTags}
                getAll ={this.getAllServices}
                toggleSelectedTag={toggleSelectedTag}
                selectedTags={selectedTags}
                clearSelectedTags={clearSelectedTags}
              />
              {selectedTags.map((selectedTag, idx) => (
                <Tag
                  key={idx}
                  large
                  onRemove={() => toggleSelectedTag(selectedTag).then(this.getAllServices())}
                  intent={Intent.SUCCESS}
                  className="sr1"
                >
                  {tagLookup[selectedTag] ? tagLookup[selectedTag].name : "...loading"}
                </Tag>
              ))}
            </div>
            <AddServiceButton className="bp3-button bp3-intent-primary bp3-icon-add">Add New Service</AddServiceButton>
          </div>
          <div className="body">
            {loading ? (
              <Spinner size={50} />
            ) :
            services.length === 0 ? (
              <div className="bp3-non-ideal-state">
                <div className="bp3-non-ideal-state-visual bp3-non-ideal-state-icon">
                  <span className="bp3-icon bp3-icon-folder-open"></span>
                </div>
                <h4 className="bp3-non-ideal-state-title">You don't have any services yet.</h4>
              </div>
            ) : (
              <table className="bp3-table">
                <thead>
                <tr>
                  <th className="th-icon"></th>
                  {sorteableHeader("date", "Date")}
                  {sorteableHeader("organized", "")}
                  <th className="th-head">Songs</th>
                  <th className="th-head">Messages</th>
                  <th className="th-name" style={{'max-width' : 200}}>
                    <Tooltip content={`To filter by category/tag, use the "filter" button in the top left of this list.`} position={Position.RIGHT}>
                      <span>Category</span>
                    </Tooltip>
                  </th>
                  <th className="th-menu"></th>
                </tr>
                </thead>
                <tbody>
                {services.map((service, idx) => (
                  <tr key={idx} className={Moment(service.date, "YYYY-MM-DD HH:mm:ss").valueOf() > Date.now() ? "future" : ""}>
                    <td className="td-icon"><span className="bp3-icon-standard bp3-icon-bookmark"></span></td>
                    <td className="td-name">
                      <div>
                        <Link to={Paths.withId(Paths.SERVICE_EDIT, service.id)}>
                          {Moment(service.date, "YYYY-MM-DD HH:mm:ss").format("LLLL")}
                        </Link>
                      </div>
                      <div>{Moment(service.date, "YYYY-MM-DD HH:mm:ss").fromNow()}</div>
                    </td>
                    <td className="td-tags">
                      {service.organized && (<span className="bp3-tag bp3-minimal bp3-intent-success">ORGANIZED</span>)}
                    </td>
                    <td>{service.songs.length}</td>
                    <td>{service.sermons.length}</td>
                    <td className="td-name">
                      <QuickAddTag
                        tags={tags}
                        linkOne={link}
                        entityId={service.id}
                      />
                      {service.tags.map((tag, idx) => (
                        <span key={idx} className="bp3-tag bp3-minimal bp3-intent-success sr1">{tag.name}</span>
                      ))}
                    </td>
                    <td className="td-menu">
                      <Popover
                        position={Position.BOTTOM_RIGHT}
                        content={(
                          <Menu className="bp3-minimal">
                            <li>
                              <Link className="bp3-menu-item bp3-icon-edit"
                                    to={Paths.withId(Paths.SERVICE_EDIT, service.id)}>Edit</Link>
                            </li>
                            <MenuItem text="Clone" iconname="duplicate" onClick={() => this.onDuplicate(service)}/>
                            <MenuDivider/>
                            <li className={Classes.POPOVER_DISMISS}>
                              <a className={"bp3-menu-item service bp3-intent-danger "}
                                 onClick={() => this.onArchive(service)}>Archive</a>
                            </li>
                          </Menu>
                         )}>
                        <span className="bp3-icon-standard bp3-icon-more"></span>
                      </Popover>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    )
  }

  onHeaderOrder(header) {

    const { setOrdering, orderDir, orderBy } = this.props;

    if (orderBy === header) {
      if (orderDir === "asc") setOrdering({orderDir: "desc", orderBy});
      else setOrdering({orderBy: null, orderDir: null})
    } else {
      setOrdering({orderBy: header, orderDir: "asc"});
    }

    this.props.getAll()
  }

  onArchive(service) {

    const { archive, getAll } = this.props;

    confirm("Are you sure?") ? archive(service.id)
      .then(() => getAll())
      .then(() => Toaster.success("Successfully archived service."))
      .catch((e) => Toaster.error(e.message)) : null;

  }

  onDuplicate(service) {

    const { duplicate, getAll } = this.props;

    duplicate(service.id)
      .then(() => getAll())
      .then(() => Toaster.success("Successfully duplicated service."))
      .catch((e) => Toaster.error(e.message))

  }

  onExport() {
    generateCSV([["name"]]
      .concat(this.props.services.map((service) => [
        service.name
      ])), `Services`);
  }

}

ServiceList.defaultProps = {
  services: []
}

export default connect(
  (state) => {
    return {
      services: state.service.items,
      orderBy: state.service.orderBy,
      orderDir: state.service.orderDir,
      context: getContext(state),
      selectedTags: state.service.selectedTags,
      tagLookup: buildLookup(state.service.tags, "id"),
      tags: state.service.tags,
    }
  },
  (dispatch) => (
    bindActionCreators({
      getAll,
      archive,
      duplicate,
      getTags,
      setOrdering,
      toggleSelectedTag,
      clearSelectedTags,
      archiveTag,
      updateTag,
      createTag,
      link
    }, dispatch)
  )
)(ServiceList);