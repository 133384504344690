import Formsy from 'formsy-react';
import React from 'react';
import {TextInput} from '../../../components/form';
import {Button} from '@blueprintjs/core'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {updateUser} from '../../../state/account/actions';

class EditUserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      isProcessing: false,
      message: null,
      messageIsError: false,
      isPristine: true
    };

  }

  onValidSubmit(model) {

    const { updateUser } = this.props;
    const { isPristine } = this.state;
    if (isPristine) return;

    this.setState({isProcessing: true, message: null});

    updateUser(model.first, model.last, model.email)
      .then((result) => {
        this.setState({
          isProcessing: false,
          message: "Your changes were applied successfully.",
          messageIsError: false
        });
      })
      .catch((e) => {
        this.setState({
          isProcessing: false,
          message: e.message,
          messageIsError: true
        });
      });

  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

  render() {

    const { showErrors, isProcessing, message, messageIsError, isPristine } = this.state;
    const { email, first, last } = this.props;

    return (
      <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
              onChange={(values, isChanged) => this.checkForPristine(values, isChanged) }
              onInvalidSubmit={() => this.onInvalidSubmit() }
              ref="form">
        <div className="form-fields">
          <TextInput name="email"
                 type="email"
                 value={email}
                 label="Email"
                 showErrors={showErrors}
                 autoComplete="off"
                 required />
          <TextInput name="first"
                 type="text"
                 value={first}
                 label="first name"
                 autoComplete="off"
                 showErrors={showErrors}
                 required />
          <TextInput name="last"
                 type="text"
                 value={last}
                 label="Last name"
                 autoComplete="off"
                 showErrors={showErrors}
                 required />
          </div>
          <div className="form-submit-group grid-row">
            <div className="grid-item grid-row v-align">
              {message && (
                <div className={"message" + (messageIsError ? " error-message" : "")}>
                  {message}
                </div>
              )}
            </div>
            <div>
              <Button type="button"
                      onClick={() => this.onCancel() }
                      minimal
                      disabled={isPristine}
                      text="Cancel"
                      className="sr2"
                      loading={isProcessing}
              />
              <Button type="submit"
                      intent="primary"
                      text="Submit"
                      loading={isProcessing}
              />
            </div>
          </div>
      </Formsy>
    );
  }

  checkForPristine(values, isChanged) {
    this.setState({isPristine: !isChanged, message: null});
  }

  onCancel() {

    const { isPristine } = this.state;
    if (isPristine) return;

    this.refs.form.reset();
    this.setState({
      message : "Your changes have been discarded.",
      messageIsError: false
    });
  }
}

export default connect(
  (state) => {
    return {
      email: state.account.user.email,
      first: state.account.user.first,
      last: state.account.user.last
    }
  },
  (dispatch) => (
    bindActionCreators({
      updateUser
    }, dispatch)
  )
)(EditUserForm);