import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { NewCardDialog } from './'
import { getPaymentMethod as getOrgPaymentMethod } from '../../../state/org/actions'
import { getPaymentMethod } from "../../../state/account/actions";
import {Button} from '@blueprintjs/core'

class PaymentMethod extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      isEnteringCard: false,
      loading: false,
      error: false
    }
  }

  componentDidMount() {

    const { personal, getPaymentMethod, getOrgPaymentMethod } = this.props;

    this.setState({loading: true, error: false});

    (personal ? getPaymentMethod() : getOrgPaymentMethod())
      .then(() => this.setState({loading: false}))
      .catch((e) => {this.setState({ error: e.message, loading: false })})
  }

  render() {

    const { personal, userPaymentMethod, orgPaymentMethod } = this.props;
    const { isEnteringCard, loading } = this.state;

    var viewedPaymentMethod = personal ? userPaymentMethod : orgPaymentMethod;

    if (loading) return null;

    if (!viewedPaymentMethod) return (
      <div className="bp3-non-ideal-state">
        <h4 className="bp3-non-ideal-state-title">No payment method entered.</h4>
        <Button text="Add Card" onClick={() => this.setState({isEnteringCard: true})} className="s3"/>
        <NewCardDialog isOpen={isEnteringCard}
                       personal={personal}
                       onClose={() => this.setState({isEnteringCard: false})} />
      </div>
    );

    return (
      <div className="PaymentMethod grid-row v-align">
        <div className="grid-item">{viewedPaymentMethod.brand} ****{viewedPaymentMethod.last4}</div>
        <Button text="Change Card" onClick={() => this.setState({isEnteringCard: true})} className="s3"/>
        <NewCardDialog isOpen={isEnteringCard}
                       personal={personal}
                       onClose={() => this.setState({isEnteringCard: false})} />
      </div>
    )
  }

}

export default connect(
  (state, props) => {
    return {
      userPaymentMethod: state.account.paymentMethod,
      orgPaymentMethod: state.org.paymentMethod
    }
  },
  (dispatch) => (
    bindActionCreators({
      getPaymentMethod, getOrgPaymentMethod
    }, dispatch)
  )
)(PaymentMethod);


