import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import _ from 'lodash'
import {Toaster} from "../../../components/index";
import Formsy from 'formsy-react';
import {navigate} from 'gatsby'
import {Button, Dialog, Intent} from "@blueprintjs/core";
import {create} from '../../../state/resource/actions'
import {TextInput} from '../../../components/form';
import * as Paths from '../../../constants/Paths'

class AddResourceDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {showErrors: false}
  }

  render() {

    const { onClose = _.noop, onSubmit = _.noop, isOpen = false } = this.props;
    const { showErrors = false } = this.state;

    return (
      <div className="AddResourceDialog">
        <Dialog
          iconName="inbox"
          isOpen={isOpen}
          onClose={() => onClose() }
          title="Dialog header"
        >
          <div className="bp3-dialog-body">
            <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
                    onInvalidSubmit={() => this.onInvalidSubmit() }
                    ref="form">
              <TextInput name="name"
                     type="text"
                     icon="build"
                     label="Resource Name"
                     showErrors={showErrors}
                     autofocus
                     required />
            </Formsy>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text="Cancel" onClick={() => onClose()}/>
              <Button
                intent={Intent.PRIMARY}
                onClick={() => this.refs.form.submit() }
                text="Submit"
              />
            </div>
          </div>
        </Dialog>
      </div>
    )
  }

  onValidSubmit(model) {

    const { create, onClose } = this.props;

    create({name: model.name})
      .then((result) => {
        onClose();
        navigate(Paths.withId(Paths.RESOURCE_EDIT, result.item.id));
      }).catch((e) => {
        Toaster.show({
          message: e.message,
          iconName: "warning-sign",
          intent: Intent.DANGER
        });
      })
  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

}

AddResourceDialog.defaultProps = {
  onClose: _.noop
};

export default connect(
  (state) => {
    return {

    }
  },
  (dispatch) => (
    bindActionCreators({
      create
    }, dispatch)
  )
)(AddResourceDialog);
