import * as SongApi from "../../api/SongApi";
import * as actionTypes from "./actionTypes";
import {getContext} from '../context/selectors';
import * as SermonApi from "../../api/SermonApi";

export const getOne = (songId) => (dispatch, state) =>
  SongApi.getOne(songId, getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_ONE, item: result.item }));

export const getAll = () => (dispatch, state) =>
  SongApi.getAll(
    { orderby:  state().song.orderBy, orderdir:  state().song.orderDir, tags:  state().song.selectedTags },
    getContext(state)
  ).then((result) => {
    dispatch({ type: actionTypes.GET_ALL, items: result.items })
  });

export const create = (song) => (dispatch, state) =>
  SongApi.create(song, getContext(state))
    .then((result) => {
      dispatch({ type: actionTypes.CREATE, item: result.item });
      return result;
    });

export const update = (songId, song) => (dispatch, state) =>
  SongApi.update(songId, song, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE }));

export const patch = (songId, patch) => (dispatch, state) =>
  SongApi.patch(songId, patch, getContext(state))
    .then(() => dispatch({ type: actionTypes.PATCH }));

export const linkOne = (songId, linkId, linkType) => (dispatch, state) =>
  SongApi.linkOne(songId, linkId, linkType, getContext(state))
    .then(() => dispatch({ type: actionTypes.LINK_ONE, entityId: songId, linkId, linkType }));

export const unlinkOne = (songId, linkId, linkType) => (dispatch, state) =>
  SongApi.linkOne(songId, linkId, linkType, getContext(state))
    .then(() => dispatch({ type: actionTypes.UNLINK_ONE, entityId: songId, linkId, linkType }));

export const linkSync = (songId, linkIds) => (dispatch, state) =>
  SongApi.linkSync(songId, linkIds, getContext(state))
    .then(() => dispatch({ type: actionTypes.LINKSYNC }));

export const duplicate = (songId) => (dispatch, state) =>
  SongApi.duplicate(songId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DUPLICATE }));

export const archive = (songId) => (dispatch, state) =>
  SongApi.archive(songId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE }));

export const unArchive = (songId) => (dispatch, state) =>
  SongApi.unArchive(songId, getContext(state))
    .then(() => dispatch({ type: actionTypes.UNARCHIVE }));

export const hardDelete = (songId) => (dispatch, state) =>
  SongApi.hardDelete(songId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DELETE }));

export const getTags = () => (dispatch, state) =>
  SongApi.getTags(getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_TAGS, items: result.items }));

export const createTag = (name) => (dispatch, state) =>
  SongApi.createTag({ name }, getContext(state))
    .then((result) => dispatch({ type: actionTypes.CREATE_TAG, item: result.item }));

export const archiveTag = (tagId) => (dispatch, state) =>
  SongApi.archiveTag(tagId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE_TAG }));

export const updateTag = (tagId, name) => (dispatch, state) =>
  SongApi.updateTag(tagId, name, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE_TAG }));

export const setOrdering = ({orderBy, orderDir}) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ORDER,
    orderBy,
    orderDir
  });
  return Promise.resolve();
};

export const toggleSelectedTag = (tagId) => (dispatch, state) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAGS,
    selectedTags: state().song.selectedTags.includes(tagId)
      ? state().song.selectedTags.filter((eTagId) => eTagId !== tagId)
      : [...state().song.selectedTags, tagId]

  });
  return Promise.resolve();
};

export const clearSelectedTags = () => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAGS,
    selectedTags: []
  });

  return Promise.resolve();
};