import React from 'react'
import DashboardApp from '../../app/app'

class DashboardIndex extends React.Component {
  render() {
    return(
      <DashboardApp />
    );
  }
}

export default DashboardIndex;
