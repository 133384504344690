import React from 'react'
import Formsy from 'formsy-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Dialog, Button, Intent} from '@blueprintjs/core'
import {MultiSelect} from '../../../components/form';
import {Toaster} from '../../../components';
import {getAll as getAllPeople} from '../../../state/person/actions'
import {linkSync, getOne} from '../../../state/team/actions'
import {getInformalFullname} from "../../../state/person/util";

class AddTeamToServiceDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false
    }

  }

  componentDidMount() {
    this.props.getAllPeople({noFilters: true});
  }

  render() {

    const { trigger, persons, team } = this.props;
    const { open } = this.state;

    return (
      <>
        {trigger(() => this.setState({open: true}))}
        <Dialog
          iconName="music"
          isOpen={open}
          onClose={() => this.setState({open: false}) }
          title="Link message to services"
        >
          <div className="bp3-dialog-body">
            <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
                    onInvalidSubmit={() => this.onInvalidSubmit() }
                    ref="form">
              <MultiSelect
                name="services"
                icon="calendar"
                value={team.persons.map((person) => ({
                  value: person.id + "",
                  label: getInformalFullname(person)
                }))}
                isMulti
                options={persons.map((person) => ({
                  value: person.id + "",
                  label: getInformalFullname(person)
                }))}
                label="Services with this message:" />
            </Formsy>
          </div>
          <div className="bp3-dialog-footer">
            <div className="bp3-dialog-footer-actions">
              <Button text="Cancel" onClick={() => this.setState({open: false})}/>
              <Button
                intent={Intent.PRIMARY}
                onClick={() => this.refs.form.submit() }
                text="Submit"
              />
            </div>
          </div>
        </Dialog>
      </>
    )
  }

  onValidSubmit(model) {

    const { linkSync, team, getOne } = this.props;

    var serviceIds = model.services ? model.services.map((service) => service.value) : [];

    linkSync(team.id, serviceIds.map((id) => parseInt(id)), "person")
      .then(() => this.setState({open: false}))
      .then(() => getOne(team.id))
      .catch((e) => {
        Toaster.show({
          message: e.message,
          iconName: "warning-sign",
          intent: Intent.DANGER
        });
      })
  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

}

export default connect(
  (state) => {
    return {
      team: state.team.item,
      persons: state.person.items
    }
  },
  (dispatch) => (
    bindActionCreators({
      getAllPeople, linkSync, getOne
    }, dispatch)
  )
)(AddTeamToServiceDialog);


