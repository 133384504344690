import * as TeamApi from "../../api/TeamApi";
import * as actionTypes from "./actionTypes";
import {getContext} from '../context/selectors';
import * as ServiceApi from "../../api/ServiceApi";
import * as PersonApi from "../../api/PersonApi";

export const getOne = (teamId) => (dispatch, state) =>
  TeamApi.getOne(teamId, getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_ONE, item: result.item }));

export const getAll = (options = {}) => (dispatch, state) =>
  (options.noFilters
      ? TeamApi.getAll({}, getContext(state))
      : TeamApi.getAll({ orderby:  state().team.orderBy, orderdir:  state().team.orderDir, tags:  state().team.selectedTags }, getContext(state))
  ).then((result) => {
    dispatch({ type: actionTypes.GET_ALL, items: result.items })
  });

export const create = (team) => (dispatch, state) =>
  TeamApi.create(team, getContext(state))
    .then((result) => {
      dispatch({ type: actionTypes.CREATE, item: result.item });
      return result;
    });

export const update = (teamId, team) => (dispatch, state) =>
  TeamApi.update(teamId, team, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE }));

export const patch = (teamId, patch) => (dispatch, state) =>
  TeamApi.patch(teamId, patch, getContext(state))
    .then(() => dispatch({ type: actionTypes.PATCH }));

export const linkOne = (teamId, linkId, linkType) => (dispatch, state) =>
  TeamApi.linkOne(teamId, linkId, linkType, getContext(state))
    .then(() => dispatch({ type: actionTypes.LINK_ONE, entityId: teamId, linkId, linkType }));

export const unlinkOne = (teamId, linkId, linkType) => (dispatch, state) =>
  TeamApi.linkOne(teamId, linkId, getContext(state), linkType)
    .then(() => dispatch({ type: actionTypes.UNLINK_ONE, entityId: teamId, linkId, linkType }));

export const linkSync = (serviceId, linkIds, linkType = "tag") => (dispatch, state) =>
  TeamApi.linkSync(serviceId, linkIds, getContext(state), linkType)
    .then(() => dispatch({ type: actionTypes.LINKSYNC }));

export const duplicate = (teamId) => (dispatch, state) =>
  TeamApi.duplicate(teamId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DUPLICATE }));

export const archive = (teamId) => (dispatch, state) =>
  TeamApi.archive(teamId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE }));

export const unArchive = (teamId) => (dispatch, state) =>
  TeamApi.unArchive(teamId, getContext(state))
    .then(() => dispatch({ type: actionTypes.UNARCHIVE }));

export const hardDelete = (teamId) => (dispatch, state) =>
  TeamApi.hardDelete(teamId, getContext(state))
    .then(() => dispatch({ type: actionTypes.DELETE }));

export const getTags = () => (dispatch, state) =>
  TeamApi.getTags(getContext(state))
    .then((result) => dispatch({ type: actionTypes.GET_TAGS, items: result.items }));

export const createTag = (name) => (dispatch, state) =>
  TeamApi.createTag({ name }, getContext(state))
    .then((result) => dispatch({ type: actionTypes.CREATE_TAG, item: result.item }));

export const archiveTag = (tagId) => (dispatch, state) =>
  TeamApi.archiveTag(tagId, getContext(state))
    .then(() => dispatch({ type: actionTypes.ARCHIVE_TAG }));

export const updateTag = (tagId, name) => (dispatch, state) =>
  TeamApi.updateTag(tagId, name, getContext(state))
    .then(() => dispatch({ type: actionTypes.UPDATE_TAG }));

export const setOrdering = ({orderBy, orderDir}) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ORDER,
    orderBy,
    orderDir
  });
  return Promise.resolve();
};

export const toggleSelectedTag = (tagId) => (dispatch, state) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAGS,
    selectedTags: state().team.selectedTags.includes(tagId)
      ? state().team.selectedTags.filter((eTagId) => eTagId !== tagId)
      : [...state().team.selectedTags, tagId]

  });
  return Promise.resolve();
};

export const clearSelectedTags = () => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAGS,
    selectedTags: []
  });

  return Promise.resolve();
};