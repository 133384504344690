import React from 'react'
import { LoginDialog } from './'

class CancelSubscriptionButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = { isDialogOpen: false }

  }

  render() {

    const { isDialogOpen } = this.state;
    const { undo = false, text } = this.props;

    return (
      <span className="CancelSubscriptionButton">
        <a onClick={() => this.setState({isDialogOpen: true})}>{text || "Login"}</a>
        <LoginDialog undo={undo} isOpen={isDialogOpen} onClose={() => this.setState({isDialogOpen: false})}/>
      </span>
    )
  }

}

export default CancelSubscriptionButton;
