import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {DashboardLayoutA} from "../layouts";
import {getContext} from '../../state/context/selectors'
import {PrayerRequests, RecentServices, RecentMessages} from './containers'

class ChurchOverview extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {

    const { context } = this.props;

    return (
      <DashboardLayoutA className="page-content">
        <div className="grid-row">
          <div className="grid-item-2 grid-column grid-row sr3">
            <RecentServices />
          </div>
          <div className="grid-item-2 grid-column grid-row sr3">
            <RecentMessages />
          </div>
        </div>
      </DashboardLayoutA>
    )
  }

}

export default connect(
  (state) => {
    return {
      context: getContext(state)
    }
  },
  (dispatch) => (
    bindActionCreators({

    }, dispatch)
  )
)(ChurchOverview);